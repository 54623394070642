import ContactUs from "./ContactUs"
import Footer from "./Footer"
import Header from "./Header"
import Review from "./Review"
import WhyVyapar from "./WhyVyapar"
import coi from "../images/coi.png"
import tan from "../images/tan_company.png"
import check from "../images/check.png"
import AoA from "../images/AoA.png"
import step1 from "../images/step1.png";
import step2 from "../images/step2.png";
import step3 from "../images/step3.png";
import oneDirector from "../images/oneDirector.png";
import twoDirector from "../images/2director.png";
import uniqueBusinessName from "../images/uniqueBusinessName.png";
import registeredAddress from "../images/officeAddress.png"
import arrow from "../images/arrow.png"
import map from "../images/Section_8_company_statewise_fee_Section_8_company_statewise_fee.png"
import dsc from "../images/digital_signature_dsc-03.svg.tmp"
import MoA from "../images/moa.png"
import MasterData from "../images/Master-data.png"
import Din from "../images/DIN_Allotment.png"
import panCard from "../images/pan-card.png"
import { LuCheckCircle } from "react-icons/lu";
import { FaArrowAltCircleRight, FaWhatsapp } from "react-icons/fa";
import { PiNumberCircleOneFill, PiNumberCircleTwoFill, PiNumberCircleThreeFill, PiNumberCircleFourFill, PiNumberCircleFiveFill } from "react-icons/pi";
import flower from "../images/flower.jpg"
import PU from "../images/PU-separator.svg"
import FastPro from "../images/Fast_Process.png"
import benefitPvtLtd from "../images/benefitPvtLtd1.png"
import documentRequired from "../images/document-required.png"
import { useEffect, useState } from "react"
import '../styles/servicePage.css'
import Section2 from "./servicePage/Section2"
import { useLocation, useNavigate, useNavigation } from "react-router-dom"
import DocumentData from "./servicePage/DocumentData"
import MultiImage from "./servicePage/MultiImage"
import TestiMonials from "./TestiMonials"
import { ToastContainer, toast } from "react-toastify"
import uploadFile from "../upload/uploadFile"
import cheerio from 'cheerio';
import thankyouimg from '../images/freepik-export-20240517133959H7Be.png'
import Payment from "./Payment"
import axios from "axios"
import CartSidebar from "./CartSidebar"




const ServicePage = () => {
    const [activeSuccessModal, setActiveSuccessModal] = useState(0);
    const [activeSection1, setActiveSection1] = useState('Apply for Dsc');
    const [activeSection2, setActiveSection2] = useState('Incorporation Certificate')
    const [modalActive, setModalActive] = useState(false);
    const [modalActiveUpload, setModalActiveUpload] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [userData, setUserData] = useState();
    const [requiredData, setRequiredData] = useState();
    const [allDoument, setAllDocumnet] = useState(0);
    const [file, setFile] = useState()
    const [disble, setDisble] = useState(false)
    const [selectDocument, setSelectDocument] = useState()
    const [documentList, setDocumentList] = useState();
    const [payDetials, setPayDetails] = useState()
    const [userAllDetails, setUserAllDetails] = useState()
    const [modifiedHtmlContent, setModifiedHtmlContent] = useState()
    const [modifiedHtmlHeading, setModifiedHtmlHeading] = useState()
    const [promocode, setPromocode] = useState()
    const [franchiseid, setFranchiseid] = useState()
    const [offerData, setOfferData] = useState()
    const [invoiceOpen, setInvoiceOpen] = useState(false)
    const [imageShow, setImageShow] = useState({ value: false, image: "" })
    const [amount, setAmount] = useState('');
    const [currency, setCurrency] = useState('INR');
    const [orderId, setOrderId] = useState();
    const [cartStatus, setCartStatus] = useState(false);

    const handleSectionClick1 = (section) => {
        setActiveSection1(section);
    };
    const handleSectionClick2 = (section2) => {
        setActiveSection2(section2);
    };

    const navigate = useNavigate()

    const [selectServicedata, setSelectServiceData] = useState()

    const location = useLocation();
    const stateId = location.state;
    console.log(stateId, 'stateid')

    function getVideoId(url) {
        // Regular expression to match YouTube URL patterns
        const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

        // Try to extract video ID from the URL using regular expression
        const match = url.match(regExp);

        // If a match is found, return the video ID
        if (match && match[1]) {
            return match[1];
        } else {
            // If no match is found, return null or handle the error accordingly
            return null;
        }
    }
    const AllData = (data) => {
        // console.log("object", Object.keys(data))
        Object.keys(data).map(item => {
        })
        const htmlContent = data?.details;
        const $ = cheerio.load(htmlContent);
        $('style').each((index, element) => {
            const styles = $(element).text();
            $('h3').attr('style', styles);
        });

        setModifiedHtmlContent($.html())
    }
    const HeadingData = (data) => {
        const htmlContent = data?.heading;
        const $ = cheerio.load(htmlContent);
        $('style').each((index, element) => {
            const styles = $(element).text();
            $('h3').attr('style', styles);
        });

        setModifiedHtmlHeading($.html())
    }

    useEffect(() => {
        setFranchiseid(localStorage.getItem('franchiseid'))
    }, [localStorage.getItem('franchiseid')])

    const getById = async (id) => {
        try {
            let url = `${process.env.REACT_APP_PORT}/admin/service/getbyid`;

            const response = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ dataId: id })
            });
            const data = await response.json();
            if (response.status === 200) {
                setSelectServiceData(data)
                AllData(data)
                HeadingData(data)
                setRequiredData(data?.stepFiveData[0]?.documentsData)
            }


        } catch (e) {
            // toast.error(`! ${e}`)
            console.log(e, 'error')
        }

    }

    useEffect(() => {
        getById(stateId)
        localStorage.setItem('stateId', stateId)
    }, [stateId])

    const openModal = () => {
        setModalActive(true);
    };

    const closeModal = () => {
        setModalActive(false);
    };
    const openUploadModal = () => {
        setModalActiveUpload(true)
    };

    const closeUploadModal = () => {
        setModalActiveUpload(false);
    };

    const getList = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PORT}/admin/documentlist/list`);
            const data = await response.json();
            if (response.status === 200) {
                setDocumentList(data)
            }
            // console.log('Data received:', data);

        } catch (e) {
            console.log(e, 'error')
        }

    }

    const PayNow = (data) => {
        console.log(data, 'paynow')
        if (localStorage.getItem('logintype')) {

            if (localStorage.getItem('logintype') === "franchise") {

                setAmount(data.fr_amount)
                setSidebar(true)
            } else {

                setAmount(data.discount_amount ? data.discount_amount : data.amount)
                setSidebar(true)

            }
        } else {
            openModal()

        }
    }

    const getData = async () => {
        if (localStorage.getItem('logintype') === "franchise") {
            let url = `${process.env.REACT_APP_PORT}/admin/getuserFranchise`
            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ id: localStorage.getItem('franchiseid') })
                });
                const data = await response.json();
                if (response.status === 200) {
                    setUserAllDetails(data?.user)
                    setUserData(data?.user?.documents)

                } else {
                }
            } catch (e) {
                toast.error(e)
            }

        } else {

            let url = `${process.env.REACT_APP_PORT}/admin/getuser`
            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ id: localStorage.getItem('id') })
                });
                const data = await response.json();
                if (response.status === 200) {
                    setUserAllDetails(data?.user)
                    setUserData(data?.user?.documents)

                } else {
                }
            } catch (e) {
                toast.error(e)
            }
        }

    }

    useEffect(() => {
        getData()
        getList()
    }, [])

    const callBack = () => {
        setDisble(false)
    }

    const AddDocument = async () => {
        let url = `${process.env.REACT_APP_PORT}/admin/addDocuments`

        if (file && selectDocument !== "0") {
            setDisble(true)
            // const fileName = file.name + Date.now();
            // await uploadFile(fileName, file, callBack)
            const formData = new FormData();
            formData.append("file", file);
            formData.append("id", localStorage.getItem('id'));
            formData.append("name", selectDocument);

            try {
                const response = await fetch(url, {
                    method: "POST",
                    body: formData,
                });
                const data = await response.json();
                if (response.status === 200) {
                    setUserData(data?.user?.documents)
                    toast.success("Save Data Sucesfully!")
                    closeUploadModal()

                } else {
                    toast.error(data.error)
                }
            } catch (e) {
                toast.error(e)

                console.log(e, 'error')
            }

        }
    }

    const CreateOrder = async () => {
        let userId = localStorage.getItem('id');
        let service = selectServicedata.innerCategoryName;
        console.log(service);

        const orderData = { amount, currency };
        let url = `${process.env.REACT_APP_PORT}/admin/order/createOrder`;

        try {
            const { data } = await axios.post(url, orderData);
            setOrderId(data.id);
            const options = {
                key: 'rzp_test_6igAuqeDdhUC5t', // Enter the Key ID generated from the Dashboard
                amount: data.amount,
                currency: data.currency,
                name: 'Vyapar Bandhu Consultancy LLP',
                description: 'Test Transaction',
                order_id: data.id,
                handler: async (response) => {
                    console.log(response);
                    console.log(response.razorpay_payment_id ? true : false);
                    if (localStorage.getItem('logintype') === "franchise") {
                        let paymentUrl = `${process.env.REACT_APP_PORT}/admin/order/placeSingledOrderFranchise`;
                        const responseData = await fetch(paymentUrl, {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                razerpayData: response,
                                franchiseid,
                                discountamount: offerData ? offerData : payDetials.amount,
                                userData: userAllDetails,
                                package: payDetials,
                                userId: userId,
                                service: service
                            })
                        });
                        const data = await responseData.json();
                        if (responseData.status === 200) {
                            setInvoiceOpen(false);
                            setFranchiseid();
                            setPromocode();
                            setSidebar(false);
                            toast.success('Order Created Successfully');
                            setActiveSuccessModal(1);
                        } else {
                            toast.error('Something went wrong');
                        }

                    } else {

                        let paymentUrl = `${process.env.REACT_APP_PORT}/admin/order/placeSingledOrder`;
                        const responseData = await fetch(paymentUrl, {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                razerpayData: response,
                                franchiseid,
                                discountamount: offerData ? offerData : payDetials.discount_amount,
                                userData: userAllDetails,
                                package: payDetials,
                                userId: userId,
                                service: service
                            })
                        });
                        const data = await responseData.json();
                        if (responseData.status === 200) {
                            setInvoiceOpen(false);
                            setFranchiseid();
                            setPromocode();
                            setSidebar(false);
                            toast.success('Order Created Successfully');
                            setActiveSuccessModal(1);
                        } else {
                            toast.error('Something went wrong');
                        }
                    }

                },
                prefill: {
                    name: userAllDetails.name,
                    email: userAllDetails.email,
                    contact: userAllDetails.number
                },
                theme: {
                    color: '#3399cc'
                },
                modal: {
                    ondismiss: () => {
                        setOfferData();
                        setAmount()
                        toast.error('Order Cancelled');
                    }
                }
            };

            const rzp1 = new window.Razorpay(options);
            rzp1.open();

        } catch (e) {
            toast.error(e.message);
        }
    }



    const CheckPromo = async () => {
        if (promocode && franchiseid || promocode) {
            if (localStorage.getItem("logintype") === "franchise") {
                try {
                    let url = `${process.env.REACT_APP_PORT}/admin/offer/getOfferBycodeFranchise`
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ code: promocode, franchiseid: localStorage.getItem("franchiseid") })
                    });
                    const data = await response.json();
                    console.log(data, 'response.status')
                    if (response.status === 200) {

                        if (!data.length) {
                            toast.error("Promocode not found")
                        }
                        if (data[0].typeDiscount === "amount") {

                            setOfferData(payDetials.fr_amount - data[0].amount)
                            setAmount(payDetials.fr_amount - data[0].amount)
                        } else {
                            const discountAmountcalculate = (payDetials.fr_amount * data[0].amount) / 100;
                            setOfferData(payDetials.fr_amount - discountAmountcalculate)
                            setAmount(payDetials.fr_amount - discountAmountcalculate)
                        }
                        // setActiveSuccessModal(true)
                        toast.success('Promo Apply Successfully')

                    }
                    else {
                        console.log(response.status, 'data')
                        toast.error("Data not found")
                    }
                } catch (e) {
                    toast.error(e)
                }

            } else {

                try {
                    let url = `${process.env.REACT_APP_PORT}/admin/offer/getOfferBycode`
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ code: promocode, franchiseid })
                    });
                    console.log(response.status, 'response.status')
                    const data = await response.json();
                    if (response.status === 200) {

                        if (!data.length) {
                            toast.error("Promocode not found")
                        }
            
                        if (data[0].typeDiscount === "amount") {
                            setOfferData(payDetials.discount_amount - data[0].amount)
                            setAmount(payDetials.discount_amount - data[0].amount)
                        } else {
                            const discountAmountcalculate = (payDetials.discount_amount * data[0].amount) / 100;
                            setOfferData(payDetials.discount_amount - discountAmountcalculate)
                            setAmount(payDetials.discount_amount - discountAmountcalculate)
                        }
                        // setActiveSuccessModal(true)
                        toast.success('Promo Apply Successfully')

                    }
                    else {
                        console.log(response.status, 'data')
                        toast.error("Data not found")
                    }
                } catch (e) {
                    toast.error(e)
                }
            }



        }
        else {
            toast.error("please fill code")
        }

    }

    var allDocumentStatus;


    const AddToCart = async (item, category) => {
        item.category = category;
        console.log(item, 'service')
        if (localStorage.getItem('logintype')) {

            if (localStorage.getItem('logintype') === 'franchise') {

                let url = `${process.env.REACT_APP_PORT}/admin/addCartfranchise`;
                try {
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ franchiseid: localStorage.getItem('franchiseid'), packageData: item })
                    });
                    if (response.status === 200) {
                        setDisble(false)
                        toast.success("Add to Cart Sucesfully!")
                        setCartStatus(true)
                    }
                } catch (e) {
                    console.log(e, 'error')
                }
            } else {

                let url = `${process.env.REACT_APP_PORT}/admin/addCart`;
                try {
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ email: localStorage.getItem('email'), packageData: item })
                    });
                    if (response.status === 200) {
                        setDisble(false)
                        toast.success("Add to Cart Sucesfully!")
                        setCartStatus(true)
                    }
                } catch (e) {
                    console.log(e, 'error')
                }
            }

        } else {
            toast.error("Please Login Your account")
            setModalActive(true)

        }
    }


    useEffect(() => {
        if (activeSuccessModal === 1) {
            const timer = setTimeout(() => {
                setActiveSuccessModal(2);
            }, 5000);

            // Cleanup the timeout if the component unmounts or if the value of activeSuccessModal changes
            return () => clearTimeout(timer);
        }
    }, [activeSuccessModal]);


    return (
        <>
            <Header closeModal={closeModal} modalActive={modalActive} setModalActive={setModalActive} cartStatus={cartStatus} setCartStatus={setCartStatus} />
            {console.log(selectServicedata, 'selectServicedata?.images')}
            {
                selectServicedata &&

                <div>
                    {selectServicedata?.images &&
                        <div className='container-fluid' style={{ background: `url(${process.env.REACT_APP_PORT}/admin/service/file/${selectServicedata?.images})`, backgroundSize: "cover", minHeight: "350px", padding: "0px" , backgroundRepeat:"no-repeat" , backgroundSize:"cover" }}>
                            {console.log(`${process.env.REACT_APP_PORT}/admin/service/file/${selectServicedata?.images}`,'selectServicedata?.images')}
                            <div style={{ background: "rgb(243 243 243 / 40%)", width: "100%", minHeight: "350px" }}>
                                <div className='row py-5'>
                                    <div className='col-sm-12 mt-2 d-flex ' style={{ flexDirection: "column", justifyContent: 'center', alignItems: "center" }}>
                                        <h1 dangerouslySetInnerHTML={{ __html: modifiedHtmlHeading }}></h1>
                                        {
                                            selectServicedata?.details &&
                                            <h3 className='fs-4 mt-3 custom-editor-content' dangerouslySetInnerHTML={{ __html: modifiedHtmlContent }}></h3>
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    }
                    <div className='container mt-5' >
                        {
                            selectServicedata?.stepTwoData?.map((data, index) => {
                                return (
                                    <>
                                        <Section2 data={data} />

                                    </>
                                )

                            })

                        }
                        {
                            selectServicedata?.stepThreeData?.map((data, index) => {
                                return (
                                    <>


                                        <div className='row mt-5'>
                                            {
                                                data?.image ?
                                                    <>
                                                        <h1 className='text-center heading_main  '>{data.heading}</h1>
                                                        <div className='col-sm-5  pt-4 text-center'>
                                                            <img src={`${process.env.REACT_APP_PORT}/admin/service/file/${data?.image}`} alt="condition" height="auto" width='100%' />
                                                        </div>
                                                        {
                                                            data?.questions?.length > 0 &&
                                                            <div className='col-sm-7 '>
                                                                {

                                                                    data.questions.map((question, index) => {
                                                                        return (
                                                                            <>
                                                                                <h3 className="mt-2 f_30" style={{ color: 'black' }}>{index + 1}. <span className="custom-editor-content" dangerouslySetInnerHTML={{ __html: question.question }}></span></h3>
                                                                                {/* <p>{question.description}</p> */}
                                                                                {/* <div dangerouslySetInnerHTML={{ __html: question.description }} style={{fontSize:"20px"}}>

                                                                                </div> */}
                                                                                <pre className="f_24 custom-editor-content" style={{ width: '100%', whiteSpace: 'pre-wrap', textAlign: 'justify', fontFamily: 'inherit', }} dangerouslySetInnerHTML={{ __html: question.description }}>
                                                                                    {/* {question.description} */}
                                                                                </pre>

                                                                            </>
                                                                        )

                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            data?.questions[0]?.question &&
                                                            <>
                                                                <h1 className='text-center heading_main  mt-4'>{data.heading}</h1>
                                                                <div className='col-12 '>
                                                                    {

                                                                        data.questions.map((question, index) => {
                                                                            return (
                                                                                <>
                                                                                    <h3 className="mt-2 f_30" style={{ color: 'black' }}>{index + 1}. <span className="custom-editor-content" dangerouslySetInnerHTML={{ __html: question.question }}></span></h3>

                                                                                    <pre className="f_24 custom-editor-content" style={{ width: '100%', whiteSpace: 'pre-wrap', textAlign: 'justify', fontFamily: 'inherit' }} dangerouslySetInnerHTML={{ __html: question.description }}>
                                                                                    </pre>

                                                                                </>
                                                                            )

                                                                        })
                                                                    }
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </>
                                )
                            })
                        }
                        {
                            selectServicedata?.requirements?.map((data, index) => {
                                return (
                                    <>
                                        <h1 className="text-center heading_main  mt-5">{data?.heading}</h1>
                                        {
                                            data?.details[0]?.name &&

                                            <div class="row mt-3" style={{ display: "flex", flexWrap: "wrap" }}>
                                                <div className=" row col-lg-6">

                                                    {
                                                        data?.details?.map((SubData, index) => {
                                                            const className = index % 2 === 0 ? 'bg_color' : 'bg_orange';
                                                            return (
                                                                <>

                                                                    <div class="col-xl-12 col-md-6 mb-3" style={{ display: "flex" }}>
                                                                        <div class={`card ${className}`} style={{ padding: "0px", flex: "1 1 0", display: "flex", flexDirection: "column", marginBottom: "0px" }}>
                                                                            <div class="card-body" style={{ padding: "1rem", flex: "1", display: "flex", flexDirection: "column", height: 'auto' }}>
                                                                                <div class="d-flex align-items-center" style={{ flex: "1" }}>
                                                                                    <div class="ms-3">
                                                                                        <p class="fw-bold mb-1 text_workflow f_24">{SubData.name}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className="col-lg-6">
                                                    <img src={`${process.env.REACT_APP_PORT}/admin/service/file/${data?.image}`} className="w-100" />

                                                </div>
                                            </div>

                                        }




                                        {/* <div className="row">
                                            <h1 className="text-center heading_main  mt-4">{data?.heading}</h1>
                                            {
                                                data?.details[0]?.name &&
                                                <div className="row mt-2" >
                                                    <div className=" px-5 py-2 mx-auto" style={{ width: 'auto' }}>
                                                        {
                                                            data?.details?.map((SubData, index) => {
                                                                const className = index % 2 === 0 ? 'bg_color' : 'bg_orange';
                                                                return (
                                                                    <>
                                                                        {
                                                                            SubData.name &&
                                                                            <div key={index} className={`card py-3 px-5 ${className} mb-3`}>
                                                                                <label className='text_workflow'><span className={`ps-3 ${className} `}>{SubData.name}</span></label>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div> */}
                                    </>
                                )
                            })
                        }

                        {
                            selectServicedata?.stepFourData?.map((data, index) => {
                                return (
                                    <>
                                        <MultiImage data={data} />


                                    </>
                                )
                            })
                        }

                    </div>

                    {
                        selectServicedata?.stepFiveData?.map((data, index) => {

                            return (
                                <>
                                    {
                                        data.heading &&
                                        <DocumentData data={data} />
                                    }
                                </>
                            )
                        })

                    }
                    {
                        selectServicedata?.process?.map((data, index) => {
                            return (
                                <>

                                    <div className="container mt-5">

                                        <div className='row '>
                                            {
                                                data?.processData?.length > 0 && data?.processData[0]?.stepName &&
                                                <>
                                                    {
                                                        data.heading &&

                                                        <h1 className='text-center heading_main  mt-4'>{data.heading}</h1>
                                                    }
                                                    {
                                                        data?.processData?.length > 1 && data?.processData[0]?.description &&
                                                        <div className='col-12 '>
                                                            {

                                                                data?.processData.map((question, index) => {
                                                                    return (
                                                                        <>
                                                                            {
                                                                                question.description &&
                                                                                <>
                                                                                    {
                                                                                        question.stepName &&
                                                                                        <h3 className="f_30" style={{ color: 'black' }}>{index + 1}. <span>{question.stepName}</span></h3>
                                                                                    }
                                                                                    <pre className="f_24 custom-editor-content" style={{ width: '100%', whiteSpace: 'pre-wrap', textAlign: 'justify', fontFamily: 'inherit' }} dangerouslySetInnerHTML={{ __html: question?.description }}></pre>

                                                                                </>
                                                                            }

                                                                        </>
                                                                    )

                                                                })
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </>
                            )

                        })
                    }

                    {
                        selectServicedata?.incorporation[0]?.details?.length > 0 && selectServicedata?.incorporation?.map((data, index) => {
                            return (
                                <>
                                    <div className="container mt-5">

                                        <div className="row">
                                            {
                                                data?.details?.length > 0 &&
                                                <h1 className="text-center heading_main  ">{data?.heading}</h1>
                                            }
                                            {
                                                data?.details?.[0]?.name &&

                                                <div className="row" >
                                                    <div className=" px-5 py-2 mx-auto" style={{ width: 'auto' }}>
                                                        {
                                                            data?.details?.map((SubData, index) => {
                                                                const className = index % 2 === 0 ? 'text_green' : 'text_orange';
                                                                return (
                                                                    <>
                                                                        <div key={index} className={`card py-3 px-5 ${className} mb-3`}>
                                                                            <label className='text_workflow f_24'><span className={` f_24 ${className} `}>{index + 1}. {SubData.name}</span></label>
                                                                        </div>

                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>

                                </>
                            )
                        })
                    }

                    {
                        selectServicedata?.youtubeLink.length > 0 &&
                        <div className="container ">
                            <div className="text-center row">
                                {selectServicedata?.youtubeLink?.map((link) => {
                                    return (
                                        <>
                                            {
                                                link.heading &&

                                                <div key={link._id} className="mt-5">
                                                    {link.heading && <h1 className='d-flex justify-content-center heading_main mt-4'>{link.heading}</h1>}
                                                    {link.link && (
                                                        <div style={{ padding: "20px" }}>
                                                            <iframe
                                                                title="YouTube video player"
                                                                width="100%"
                                                                height="450"
                                                                src={`https://www.youtube.com/embed/${getVideoId(link.link)}`}
                                                                allowFullScreen
                                                                style={{ borderRadius: "20px" }}
                                                            ></iframe>
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        </>


                                    );
                                })}
                            </div>
                        </div>
                    }
                    {
                        selectServicedata?.faqData?.length > 0 && selectServicedata?.faqData[0].question &&
                        <div className="container mt-5">
                            <h1 className='text-center heading_main   mb-3'>Frequently Asked Questions (FAQs)</h1>
                            <div class="accordion" id="accordionExample">
                                {selectServicedata?.faqData && selectServicedata?.faqData.map((item, index) => {
                                    const collapseId = `collapse-${index}`;
                                    const headingId = `heading-${index}`;
                                    return (
                                        <div class="accordion-item" key={index}>
                                            <h2 class="accordion-header" id={headingId}>
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${collapseId}`} aria-expanded="false" aria-controls={collapseId}>
                                                    <span className="f_30" style={{ fontWeight: "500" }}>{item.question}</span>
                                                </button>
                                            </h2>
                                            <div id={collapseId} class="accordion-collapse collapse" aria-labelledby={headingId} data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <pre className="f_24 custom-editor-content" style={{ width: '100%', whiteSpace: 'pre-wrap', textAlign: 'justify', fontFamily: 'inherit' }} dangerouslySetInnerHTML={{ __html: item.answer }}>
                                                        {/* {data.description} */}
                                                    </pre>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    }
                    {/* {
                         selectServicedata?.stepSixData[0].amount && selectServicedata?.stepSixData[0].package &&
                        <div className="container">
                            <h1 className=' d-flex  justify-content-center heading_main  mt-4'>Explore Our Company Incorporation Packages</h1>
                            <div className="d-flex row mt-3" style={{ justifyContent: 'space-around' }}>

                                {
                                    selectServicedata?.stepSixData?.map((data, index) => {
                                        let dataArray = data.description.split(',');
                                        const trimmedArray = dataArray.map(item => item.trim());
                                        return (
                                            <>
                                                <div className="col-lg-4 col-sm-6 p-4">

                                                    <div class="card " style={{ borderRadius: "15px" }} >
                                                        <div className="card-body text-center bg_orange text-white text_workflow" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>
                                                            <div>{data.package}</div>
                                                            <div>₹ {data.amount}</div>

                                                        </div>
                                                        <div class="card-body">
                                                            <ul>
                                                                {
                                                                    trimmedArray?.map((item) => {
                                                                        return (
                                                                            <>
                                                                                <li class="card-text">{item}</li>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                            <div className="text-center">

                                                                <button className="btn px-5 text-white  " style={{ background: "#198754" }} ><b>Pay Now</b></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    } */}
                    {
                        selectServicedata?.stepSixData?.length > 0 && selectServicedata?.stepSixData[0].package &&
                        <>
                            <div className="container mt-5">
                                <div className="d-flex justify-content-center heading_main text-center">{selectServicedata?.packageHeading ? selectServicedata?.packageHeading : "Explore Our Company Incorporation Packages"} </div>
                                {/* <h1 className=' d-flex  justify-content-center heading_main '>Explore Our Company Incorporation Packages</h1> */}
                                <div className="d-flex row mt-3" style={{ justifyContent: 'space-around' }}>

                                    {
                                        selectServicedata?.stepSixData?.map((data, index) => {


                                            return (
                                                <>
                                                    {
                                                        data?.package && data?.amount ?

                                                            <div className="col-lg-4 col-sm-6 p-3" style={{ display: "flex", alignItems: 'stretch' }}>

                                                                <div class="card " style={{ borderRadius: "15px", border: "1px solid #cbcbcb" }} >
                                                                    <div className="text-start px-4 py-3  text_workflow d-flex f_24" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px", flexDirection: 'column', alignItems: 'start', justifyContent: 'start', color: "black" }}>
                                                                        <div className="f_30">{data.package}</div>
                                                                        {
                                                                            localStorage.getItem('logintype') === "user" ?
                                                                                <>
                                                                                    {
                                                                                        data.discount_amount > 0 ?
                                                                                            <>

                                                                                                <div className="d-flex">
                                                                                                    <div className="mt-2" style={{ backgroundColor: "rgb(254, 180, 68)", padding: "0px 13px", borderRadius: "5px", fontSize: "18px" }}>Save {data.amount - data.discount_amount}₹</div>
                                                                                                    <div className="mt-2 ml-2" style={{ fontSize: "18px", alignItems: "center", textDecoration: "line-through" }}>{data.amount}</div>
                                                                                                </div>
                                                                                                <div className="f_28">₹ {data.discount_amount}</div>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <div className="f_28">₹ {data.amount}</div>
                                                                                            </>

                                                                                    }

                                                                                </>
                                                                                :
                                                                                <>


                                                                                    <div className="d-flex">
                                                                                        <div className="mt-2" style={{ backgroundColor: "rgb(254, 180, 68)", padding: "0px 13px", borderRadius: "5px", fontSize: "18px" }}>Save {data.amount - data.fr_amount}₹</div>
                                                                                        <div className="mt-2 ml-2" style={{ fontSize: "18px", alignItems: "center", textDecoration: "line-through" }}>{data.amount}</div>
                                                                                    </div>
                                                                                    <div className="f_28">₹ {data.fr_amount}</div>

                                                                                </>
                                                                        }

                                                                    </div>
                                                                    <div className="text-start mb-1 px-3 mt-1 d-flex w-100 " style={{ justifyContent: 'space-between', gap: '10px' }}>

                                                                        <button className="btn px-md-4 text-white f_20 w-100 " style={{ background: "#198754" }} onClick={() => { PayNow(data); setPayDetails(data); setCartStatus(false) }}><b>Pay Now</b></button>
                                                                        <button className="btn px-md-4 text-white f_20 w-100" style={{ background: "#198754" }} onClick={() => { AddToCart(data, selectServicedata.innerCategoryName) }}><b><i class="bi bi-cart-fill"></i></b></button>
                                                                    </div>
                                                                    {/* <div className="px-4" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px",  flexDirection: 'column', alignItems: 'start', justifyContent: 'start', color: "black" }}>

                                                                        <div className="f_30 ">{data.package}</div>
                                                                        <div>{data.amount}</div>
                                                                    </div> */}
                                                                    <div class="card-body p-sm-3 p-2">
                                                                        <div className="custom-editor-content" dangerouslySetInnerHTML={{ __html: data.description }}></div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            ""
                                                    }

                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            }




            <WhyVyapar />
            <Review />
            <TestiMonials />
            <ContactUs />
            <Footer />
            <CartSidebar cartStatus={cartStatus} setCartStatus={setCartStatus} />

            {
                sidebar &&
                <>
                    <div className="modal-overlay" ></div>
                    <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">

                        <section className="vh-100 container_body">
                            <div className="container py-5 h-100">
                                <div className="row px-3 d-flex align-items-center justify-content-center h-100">
                                    <div className="col-md-10  col-lg-10 col-xl-9 card flex-row mx-auto px-0  " style={{ width: "90%", maxHeight: '100%' }}>


                                        <div className="card-body p-sm-4 p-2" style={{ overflow: "hidden" }}>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ fontSize: "30px" }} onClick={() => {
                                                setSidebar(false); setInvoiceOpen(false)
                                                setFranchiseid()
                                                setPromocode()
                                            }}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>

                                            {
                                                !invoiceOpen ?
                                                    <>

                                                        <h2 class="title text-center mt-3 modal_heading" >
                                                            Place Order
                                                        </h2>
                                                        <div className="d-flex w_50 flex-column" style={{ marginLeft: 'auto', marginRight: 'auto', maxHeight: "90%" }}>

                                                            {/* <ul class="p-0 flex-column mb-sm-auto mb-0 align-items-start" style={{ maxHeight: "40%", overflow: 'auto' }} id="menu" >

                                                                {
                                                                    requiredData?.map((item) => {
                                                                        allDocumentStatus = requiredData.every(item => {
                                                                            const dataStatus = userData.filter(data => data.name === item.document);
                                                                            return dataStatus.length > 0;
                                                                        });
                                                                        const dataStatus = userData.filter((data) => data.name === item.document)

                                                                        const image = dataStatus.length > 0 ? userData.filter((data) => data.name === item.document) : "";


                                                                        return (
                                                                            <>

                                                                                <li class="nav-item d-flex w-100" style={{ fontSize: "20px", padding: '5px 0px', justifyContent: 'space-between' }}>
                                                                                    {
                                                                                        dataStatus.length > 0 ?
                                                                                            <>
                                                                                                <div className="w-75 d-flex">

                                                                                                    <i class="bi bi-check2-circle" style={{ color: "#00e300" }}></i>
                                                                                                    <span class="ms-1  d-inline" >{item.document}</span>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <button type="button" class="btn btn-primary" style={{ border: "none" }} onClick={() => { setImageShow({ value: true, image: image ? image[0].image : "" }) }}  ><i class="bi bi-eye"></i></button>

                                                                                                </div>

                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <div className="w-75 d-flex">

                                                                                                    <i class="bi bi-exclamation-circle" style={{ color: "rgb(255 0 0)" }}></i>
                                                                                                    <span class="ms-1  text-justify" >{item.document}</span>
                                                                                                </div>
                                                                                                {
                                                                                                    !allDocumentStatus &&

                                                                                                    <div>
                                                                                                        <button type="button" class="btn btn-primary" style={{ backgroundColor: "rgb(254, 180, 68)", border: "none" }} onClick={() => { openUploadModal(); setSelectDocument(item.document) }} disabled={allDocumentStatus ? true : false}><i class="bi bi-cloud-arrow-up"></i></button>

                                                                                                    </div>
                                                                                                }
                                                                                            </>
                                                                                    }


                                                                                </li>


                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                            </ul> */}
                                                            <div class="modal-footer mt-2">

                                                                <div class="form-input w-100 d-flex flex-column align-items-center" style={{ gap: '10px' }}>
                                                                    <span></span>
                                                                    {
                                                                        localStorage.getItem('franchiseid') ?
                                                                            <></>
                                                                            :
                                                                            <input className="mb-0" type="text" placeholder="Franchise id if any.." tabindex="10" required style={{ paddingLeft: "25px" }}
                                                                                value={franchiseid}
                                                                                onChange={(e) => { setFranchiseid(e.target.value) }}
                                                                            />
                                                                    }
                                                                    <input className="mb-0" type="text" placeholder="Promocode.." tabindex="10" required style={{ paddingLeft: "25px" }}
                                                                        value={promocode}
                                                                        onChange={(e) => { setPromocode(e.target.value) }}
                                                                    />
                                                                    {/* <button onClick={() => { CheckPromo() }} type="button" class="btn btn-primary" style={{ border: "none", background: "#198754", width: "max-content", fontSize: "20px", padding: "5px 30px" }} >Apply</button> */}

                                                                </div>


                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-primary"
                                                                    style={{ backgroundColor: "#198754", border: "none", padding: '10px', fontSize: "25px", width: "100%", fontWeight: "600" }}
                                                                    // onClick={() => { CreateOrder() }}
                                                                    onClick={() => { CheckPromo() }}
                                                                >Apply Code</button>
                                                                <button type="button" class="btn btn-primary"
                                                                    style={{ backgroundColor: "#198754", border: "none", padding: '10px', fontSize: "25px", width: "100%", fontWeight: "600" }}
                                                                    // onClick={() => { CreateOrder() }}
                                                                    onClick={() => { setInvoiceOpen(true) }}
                                                                >Next</button>


                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <h2 class="title text-center mt-4 modal_heading" >
                                                            Oreder Details
                                                        </h2>
                                                        <div class="p-sm-2 p-0  modal-footer mt-2 d-flex flex-column justify-content-center">


                                                            {/* <div>
                                                                <div>Service Name : </div>
                                                                <div>{selectServicedata.innerCategoryName}</div>
                                                            </div> */}

                                                            <div class="card" style={{ boxShadow: "none" }}>
                                                                <div class="card-body mx-sm-4 p-0">
                                                                    <div class="container w-75 d-md-block d-none">
                                                                        {
                                                                            console.log(payDetials, 'payDetials')
                                                                        }

                                                                        <div class="d-flex">
                                                                            <div className="w-50"><b>Service Name</b></div>
                                                                            <div>: <span>{selectServicedata.innerCategoryName}</span></div>
                                                                        </div>
                                                                        <hr style={{ border: "1px solid black;" }} />
                                                                        <div class="d-flex">
                                                                            <div className="w-50"><b>Package Name</b></div>
                                                                            <div>: <span>{payDetials.package}</span></div>
                                                                        </div>
                                                                        <hr style={{ border: "1px solid black;" }} />
                                                                        <div class="d-flex">
                                                                            <div className="w-50"><b>Package Amount</b></div>
                                                                            {
                                                                                localStorage.getItem('logintype') === "user" ?
                                                                                    <div>: <span>{payDetials.amount}</span></div>
                                                                                    :

                                                                                    <div>: <span>{payDetials.fr_amount}</span></div>
                                                                            }
                                                                        </div>
                                                                        <hr style={{ border: "1px solid black;" }} />
                                                                        {
                                                                            promocode &&
                                                                            <>
                                                                                <div class="d-flex">
                                                                                    <div className="w-50"><b>Discount Amount</b></div>
                                                                                    <div>: <span>{offerData}</span></div>
                                                                                </div>
                                                                                <hr style={{ border: "1px solid black;" }} />

                                                                            </>
                                                                        }
                                                                        {
                                                                            franchiseid &&
                                                                            <>
                                                                                <div class="d-flex">
                                                                                    <div className="w-50"><b>Franchiseid</b></div>
                                                                                    <div>: <span>{franchiseid === 'undefined' ? "Not Added" : franchiseid}</span></div>
                                                                                </div>
                                                                                <hr style={{ border: "1px solid black;" }} />

                                                                            </>
                                                                        }
                                                                        <div class="d-flex justify-content-center">
                                                                            {
                                                                                localStorage.getItem('logintype') === "user" ?
                                                                                    <>
                                                                                        {
                                                                                            promocode ?
                                                                                                <div><b>You Need to you {offerData}</b></div> :
                                                                                                <div><b>You Need to you {payDetials.discount_amount}</b></div>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            promocode ?
                                                                                                <div><b>You Need to you {offerData}</b></div> :
                                                                                                <div><b>You Need to you {payDetials.fr_amount}</b></div>
                                                                                        }
                                                                                    </>

                                                                            }
                                                                            {/* <div><b>You Need to you {offerData ? offerData : payDetials.fr_amount}</b></div> */}
                                                                            <hr style={{ border: "1px solid black;" }} />
                                                                        </div>


                                                                        <div>
                                                                            <button type="button" class="btn btn-primary"
                                                                                style={{ backgroundColor: "rgb(25, 135, 84)", border: "none", padding: '12px', fontSize: "30px", width: "100%", fontWeight: "600" }}
                                                                                onClick={() => { CreateOrder() }}
                                                                            >Pay Now</button>
                                                                        </div>


                                                                    </div>
                                                                    <div class="container d-md-none d-block">
                                                                        {
                                                                            console.log(payDetials, 'payDetials')
                                                                        }

                                                                        <div class="d-flex">
                                                                            <div className="w-50"><b>Service Name</b></div>
                                                                            <div>: <span>{selectServicedata.innerCategoryName}</span></div>
                                                                        </div>
                                                                        <hr style={{ border: "1px solid black;" }} />
                                                                        <div class="d-flex">
                                                                            <div className="w-50"><b>Package Name</b></div>
                                                                            <div>: <span>{payDetials.package}</span></div>
                                                                        </div>
                                                                        <hr style={{ border: "1px solid black;" }} />
                                                                        <div class="d-flex">
                                                                            <div className="w-50"><b>Package Amount</b></div>
                                                                            {
                                                                                localStorage.getItem('logintype') === "user" ?
                                                                                    <div>: <span>{payDetials.discount_amount}</span></div>
                                                                                    :

                                                                                    <div>: <span>{payDetials.fr_amount}</span></div>
                                                                            }
                                                                        </div>
                                                                        <hr style={{ border: "1px solid black;" }} />
                                                                        {
                                                                            promocode &&
                                                                            <>
                                                                                <div class="d-flex">
                                                                                    <div className="w-50"><b>Discount Amount</b></div>
                                                                                    <div>: <span>{offerData}</span></div>
                                                                                </div>
                                                                                <hr style={{ border: "1px solid black;" }} />

                                                                            </>
                                                                        }
                                                                        {
                                                                            franchiseid &&
                                                                            <>
                                                                                <div class="d-flex">
                                                                                    <div className="w-50"><b>Franchiseid</b></div>
                                                                                    <div>: <span>{franchiseid}</span></div>
                                                                                </div>
                                                                                <hr style={{ border: "1px solid black;" }} />

                                                                            </>
                                                                        }
                                                                        <div class="d-flex justify-content-center">
                                                                            {
                                                                                localStorage.getItem('logintype') === "user" ?
                                                                                    <>
                                                                                        {
                                                                                            promocode ?
                                                                                                <div><b>You Need to you {offerData}</b></div> :
                                                                                                <div><b>You Need to you {payDetials.discount_amount}</b></div>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            promocode ?
                                                                                                <div><b>You Need to you {offerData}</b></div> :
                                                                                                <div><b>You Need to you {payDetials.fr_amount}</b></div>
                                                                                        }
                                                                                    </>

                                                                            }
                                                                            {/* <div><b>You Need to you {offerData ? offerData : payDetials.discount_amount}</b></div> */}
                                                                            <hr style={{ border: "1px solid black;" }} />
                                                                        </div>


                                                                        <div className="d-flex justify-content-center">
                                                                            <button type="button" class="btn btn-primary"
                                                                                style={{ backgroundColor: "#198754", border: "none", padding: '10px 30px', fontSize: "20px", width: "auto", fontWeight: "600" }}
                                                                                onClick={() => {
                                                                                    // history.push("/Payment");

                                                                                    CreateOrder()

                                                                                }}
                                                                            >Pay Now</button>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>

                                                    </>
                                            }




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    {/* <div class="modal fade show" id="exampleModal" tabindex="-1" role="dialog" style={{ display: 'block', background: "#ffffffb8" }}>
                        <div class="modal-dialog" role="document" style={{ height: "90%" }}>
                            <div class="modal-content" style={{ maxHeight: "100%" }}>
                                <div class="modal-header">
                                    <h5 class="modal-title text-green" id="exampleModalLabel">Place Order </h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => { setSidebar(false) }}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body text-center" style={{ height: "90%", overflowY: "auto" }}>
                                    <ul class="p-0 flex-column mb-sm-auto mb-0 align-items-start" id="menu" >

                                        {
                                            requiredData?.map((item) => {
                                                console.log(item, userData, 'userDatauserData')
                                                allDocumentStatus = requiredData.every(item => {
                                                    const dataStatus = userData.filter(data => data.name === item.document);
                                                    return dataStatus.length > 0;
                                                });
                                                const dataStatus = userData.filter((data) => data.name === item.document)
                                                console.log(item, 'document')

                                                const image = dataStatus.length > 0 ? userData.filter((data) => data.name === item.document) : "";

                                                console.log(image, 'image')

                                                return (
                                                    <>

                                                        <li class="nav-item d-flex w-100" style={{ fontSize: "20px", padding: '5px 0px', justifyContent: 'space-between' }}>
                                                            {
                                                                dataStatus.length > 0 ?
                                                                    <>
                                                                        <div className="w-75 d-flex">

                                                                            <i class="bi bi-check2-circle" style={{ color: "#00e300" }}></i>
                                                                            <span class="ms-1  d-inline" >{item.document}</span>
                                                                        </div>
                                                                        <div>
                                                                            <button type="button" class="btn btn-primary" style={{ border: "none" }} onClick={() => { setImageShow({ value: true, image: image ? image[0].image : "" }) }}  ><i class="bi bi-eye"></i></button>

                                                                        </div>

                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="w-75 d-flex">

                                                                            <i class="bi bi-exclamation-circle" style={{ color: "rgb(255 0 0)" }}></i>
                                                                            <span class="ms-1  text-justify" >{item.document}</span>
                                                                        </div>
                                                                        {
                                                                            !allDocumentStatus &&

                                                                            <div>
                                                                                <button type="button" class="btn btn-primary" style={{ backgroundColor: "rgb(254, 180, 68)", border: "none" }} onClick={() => { openUploadModal(); setSelectDocument(item.document) }} disabled={allDocumentStatus ? true : false}><i class="bi bi-cloud-arrow-up"></i></button>

                                                                            </div>
                                                                        }
                                                                    </>
                                                            }


                                                        </li>


                                                    </>
                                                )
                                            })
                                        }

                                    </ul>

                                </div>
                                <div class="modal-footer">

                                    <div class="form-input w-100 d-flex flex-column align-items-center" style={{ gap: '10px' }}>
                                        <span></span>
                                        <input className="mb-0" type="text" placeholder="Franchise id if any.." tabindex="10" required style={{ paddingLeft: "25px" }}
                                            value={franchiseid}
                                            onChange={(e) => { setFranchiseid(e.target.value) }}
                                        />
                                        <input className="mb-0" type="text" placeholder="Promocode.." tabindex="10" required style={{ paddingLeft: "25px" }}
                                            value={promocode}
                                            onChange={(e) => { setPromocode(e.target.value) }}
                                        />
                                        <button onClick={() => { CheckPromo() }} type="button" class="btn btn-primary" style={{ border: "none", background: "#198754", width: "max-content", fontSize: "20px", padding: "5px 30px" }} >Apply</button>

                                    </div>


                                </div>
                            
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary"
                                        style={{ backgroundColor: "rgb(254, 180, 68)", border: "none", padding: '12px', fontSize: "30px", width: "100%", fontWeight: "600" }}
                                        onClick={() => { CreateOrder() }}
                                    >Pay Now</button>


                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* 
                    <div class="offcanvas offcanvas-end show sidebar_width" tabindex="-1" id="offcanvas" data-bs-keyboard="false" data-bs-backdrop="false" style={{ boxShadow: "0 0.5rem 3rem 0 rgba(0, 0, 0, 0.1)", background: "rgb(25, 135, 84)", color: "white" }}>
                        <div class="offcanvas-header" style={{ display: "flex", justifyContent: "space-between", padding: "0px 10px" }}>
                            <h4 class="offcanvas-title d-block" id="offcanvas">Document List</h4>
                            <button type="button" class=" " data-bs-dismiss="offcanvas" aria-label="Close" style={{ color: "white", border: "none", background: "transparent" }} onClick={() => { setSidebar(false) }}> <i class="bi bi-x-lg"></i></button>
                        </div>
                        <div class="offcanvas-body px-0">
                            <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start" id="menu" style={{ height: "70%", overflowY: 'auto' }}>

                                {
                                    requiredData?.map((item) => {
                                        allDocumentStatus = requiredData.every(item => {
                                            const dataStatus = userData.filter(data => data.name === item.document);
                                            return dataStatus.length > 0;
                                        });
                                        const dataStatus = userData.filter((data) => data.name === item.document)
                                        console.log(item, 'document')

                                        return (
                                            <>

                                                <li class="nav-item d-flex w-100" style={{ fontSize: "20px", padding: '5px 20px', justifyContent: 'space-between' }}>
                                                    {
                                                        dataStatus.length > 0 ?
                                                            <>
                                                                <div style={{ display: "flex" }}>

                                                                    <i class="bi bi-check2-circle" style={{ color: "#00e300" }}></i>
                                                                    <span class="ms-1  d-inline" >{item.document}</span>
                                                                </div>

                                                            </>
                                                            :
                                                            <>
                                                                <div>

                                                                    <i class="bi bi-exclamation-circle" style={{ color: "rgb(255 0 0)" }}></i>
                                                                    <span class="ms-1 d-none d-sm-inline" >{item.document}</span>
                                                                </div>
                                                                {
                                                                    !allDocumentStatus &&

                                                                    <div>
                                                                        <button type="button" class="btn btn-primary" style={{ backgroundColor: "rgb(254, 180, 68)", border: "none" }} onClick={() => { openUploadModal() }} disabled={allDocumentStatus ? true : false}><i class="bi bi-cloud-arrow-up"></i></button>

                                                                    </div>
                                                                }
                                                            </>
                                                    }


                                                </li>
                                            </>
                                        )
                                    })
                                }

                            </ul>
                            <div class="p-3">

                                <div class="form-input">
                                    <span></span>
                                    <input type="text" placeholder="Promocode.." tabindex="10" required style={{ paddingLeft: "25px" }}
                                        value={promocode}
                                        onChange={(e) => { setPromocode(e.target.value) }}
                                    />

                                </div>
                            </div>
                            <div style={{ padding: '1rem 1rem', flexDirection: "column", gap: "10px", position: 'absolute', bottom: "30px" }} className="d-flex w-100">
                                <button type="button" class="btn btn-primary"
                                    // disabled={allDocumentStatus ? false : true}
                                    style={{ backgroundColor: "rgb(254, 180, 68)", border: "none", padding: '12px', fontSize: "30px", width: "100%", fontWeight: "600" }}
                                    onClick={() => { CreateOrder() }}
                                >Pay Now</button>


                            </div>
                        </div>
                    </div> */}
                </>
            }
            {
                activeSuccessModal > 0 &&

                <>
                    <div className="modal-overlay" onClick={closeModal}></div>
                    <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">

                        <section className=" container_body">
                            <div className="container py-5 h-100">
                                <div className="row px-3 d-flex align-items-center justify-content-center h-100 login">
                                    <div className="col-md-10 col-lg-10 col-xl-9 card flex-row mx-auto px-0">
                                        <div className="img-left_thankyou col-5 d-none d-md-flex w-100">
                                            <img src={thankyouimg} className="w-100" />
                                        </div>

                                        <div className="card-body d-flex justify-content-center p-0 p-sm-3">
                                            {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ fontSize: "30px" }} onClick={closeModal}>
                                                <span aria-hidden="true">&times;</span>
                                            </button> */}

                                            <div className="d-flex justify-content-center col-md-7 h-100  flex-column">
                                                {
                                                    activeSuccessModal === 1 ?
                                                        <>

                                                            <h2 class="title text-center mt-4 heading_main" >
                                                                Order Placed  Successfully!
                                                            </h2>
                                                            <h4 className="text-center f_30">
                                                                Thank Yoy For Purchasing!
                                                            </h4>
                                                            {/* <div className="mt-3 d-flex justify-content-center" style={{ gap: "20px" }}>
                                                                <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={() => { setActiveSuccessModal(0) }}>Close</button>
                                                                <button type="button" class="btn btn-success" onClick={() => { navigate('/profile') }}>Continue</button>
                                                            </div> */}
                                                        </>

                                                        :
                                                        <>


                                                            <h2 class="title text-center mt-2 heading_main" >
                                                                Document
                                                            </h2>
                                                            <div className="h-100" >
                                                                <ul class="p-0 flex-column mb-sm-auto mb-0 align-items-start" id="menu" style={{ maxHeight: "90%", overflow: 'auto' }}>
                                                                    {
                                                                        requiredData?.map((item) => {
                                                                            allDocumentStatus = requiredData.every(item => {
                                                                                const dataStatus = userData.filter(data => data.name === item.document);
                                                                                return dataStatus.length > 0;
                                                                            });
                                                                            const dataStatus = userData.filter((data) => data.name === item.document)
                                                                            console.log(item, 'document')

                                                                            return (
                                                                                <>

                                                                                    <li class="nav-item d-flex w-100 mb-2" style={{ fontSize: "20px", justifyContent: 'space-between' }}>
                                                                                        {
                                                                                            dataStatus.length > 0 ?
                                                                                                <>
                                                                                                    <div style={{ display: "flex" }}>

                                                                                                        <i class="bi bi-check2-circle" style={{ color: "#00e300" }}></i>
                                                                                                        <span class="ms-1  d-inline" >{item.document}</span>
                                                                                                    </div>

                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <div>

                                                                                                        <i class="bi bi-exclamation-circle" style={{ color: "rgb(255 0 0)" }}></i>
                                                                                                        <span class="ms-1  d-sm-inline" >{item.document}</span>
                                                                                                    </div>
                                                                                                    {
                                                                                                        !allDocumentStatus &&

                                                                                                        <div>
                                                                                                            <button type="button" class="btn btn-primary" style={{ backgroundColor: "rgb(254, 180, 68)", border: "none" }} onClick={() => { openUploadModal() }} disabled={allDocumentStatus ? true : false}><i class="bi bi-cloud-arrow-up"></i></button>

                                                                                                        </div>
                                                                                                    }
                                                                                                </>
                                                                                        }


                                                                                    </li>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                    {/* {
                                                                        requiredData?.map((item) => {
                                                                            console.log(item, userData, 'userDatauserData')
                                                                            allDocumentStatus = requiredData.every(item => {
                                                                                const dataStatus = userData.filter(data => data.name === item.document);
                                                                                return dataStatus.length > 0;
                                                                            });
                                                                            const dataStatus = userData.filter((data) => data.name === item.document)
                                                                            console.log(item, 'document')

                                                                            const image = dataStatus.length > 0 ? userData.filter((data) => data.name === item.document) : "";

                                                                            console.log(image, 'image')

                                                                            return (
                                                                                <>

                                                                                    <li class="nav-item d-flex w-100" style={{ fontSize: "20px", padding: '5px 0px', justifyContent: 'space-between' }}>
                                                                                        {
                                                                                            dataStatus.length > 0 ?
                                                                                                <>
                                                                                                    <div className="w-75 d-flex">

                                                                                                        <i class="bi bi-check2-circle" style={{ color: "#00e300" }}></i>
                                                                                                        <span class="ms-1  d-inline" >{item.document}</span>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <button type="button" class="btn btn-primary" style={{ border: "none" }} onClick={() => { setImageShow({ value: true, image: image ? image[0].image : "" }) }}  ><i class="bi bi-eye"></i></button>

                                                                                                    </div>

                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <div className="w-75 d-flex">

                                                                                                        <i class="bi bi-exclamation-circle" style={{ color: "rgb(255 0 0)" }}></i>
                                                                                                        <span class="ms-1  text-justify" >{item.document}</span>
                                                                                                    </div>
                                                                                                    {
                                                                                                        !allDocumentStatus &&

                                                                                                        <div>
                                                                                                            <button type="button" class="btn btn-primary" style={{ backgroundColor: "rgb(254, 180, 68)", border: "none" }} onClick={() => { openUploadModal(); setSelectDocument(item.document) }} disabled={allDocumentStatus ? true : false}><i class="bi bi-cloud-arrow-up"></i></button>

                                                                                                        </div>
                                                                                                    }
                                                                                                </>
                                                                                        }


                                                                                    </li>


                                                                                </>
                                                                            )
                                                                        })
                                                                    } */}

                                                                </ul>
                                                            </div>
                                                            <div className="mt-3 d-flex justify-content-center" style={{ gap: "10px" }}>
                                                                <button type="button" class="btn btn-danger w-100" data-dismiss="modal" onClick={() => { setActiveSuccessModal(0) }}>Close</button>
                                                                <button type="button" class="btn btn-success w-100" onClick={() => { navigate('/profile') }}>View Profile</button>
                                                            </div>

                                                        </>
                                                }

                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                </>
            }

            {
                modalActiveUpload && (
                    <>
                        <div className="modal-overlay" onClick={closeUploadModal}></div>
                        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">

                            <section className="vh-100 container_body" style={{ background: "#ffffffc4" }}>
                                <div className="container py-5 h-100">
                                    <div className="row px-3 d-flex align-items-center justify-content-center h-100">
                                        <div className="col-md-10 w-auto col-lg-10 col-xl-9 card flex-row mx-auto px-0">
                                            {/* <div className="img-left d-none d-md-flex">
                                            <div>

                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                                                    alt="login form" class="img-fluid" style={{ height: "100%" }} />
                                            </div>
                                        </div> */}

                                            <div className="card-body" style={{ boxShadow: "rgb(148 183 11 / 67%) 2px 2px 20px 14px", borderRadius: "10px" }}>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ fontSize: "30px" }} onClick={closeUploadModal}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>


                                                <h2 class="title text-center mt-4 modal_heading" >
                                                    Add  Document
                                                </h2>
                                                <div class="form-box px-3">

                                                    <div class="form-input">
                                                        <select class="form-select" aria-label="Default select example" style={{ borderRadius: "30px" }} onChange={(e) => { setSelectDocument(e.target.value) }}>
                                                            <option value='0'>Choose Document</option>

                                                            {
                                                                documentList?.map((item) => {
                                                                    return (
                                                                        <>

                                                                            <option value={item.name} selected={selectDocument === item.name ? true : false}>{item.name}</option>

                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div class="mb-3 mt-3">
                                                        <input class="form-control" type="file" id="formFile" style={{ borderRadius: "30px" }} onChange={(e) => { setFile(e.target.files[0]) }} />
                                                    </div>

                                                    <div class="mb-3 d-flex justify-content-center">

                                                        <button type="button" class="btn btn-primary" style={{ backgroundColor: "#198754", border: "none", padding: '10px 20px', fontSize: "18px", width: "auto", fontWeight: "600" }}
                                                            onClick={() => { AddDocument() }} disabled={disble}>
                                                            {disble ?
                                                                <div class="spinner-border text-warning" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                                :

                                                                <span>Submit</span>
                                                            }

                                                        </button>
                                                    </div>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                    </>
                )
            }

            {
                imageShow.value === true && (
                    <>
                        {
                            console.log(imageShow, 'imagshow')
                        }
                        <div className="modal-overlay" onClick={() => { }}></div>
                        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">

                            <section className="vh-100 container_body" style={{ background: "#ffffffc4" }}>
                                <div className="container py-5 h-75">
                                    <div className="row px-3 d-flex align-items-center justify-content-center h-100">
                                        <div className="col-md-10 w-auto col-lg-10 col-xl-9 card flex-row mx-auto px-0 h-100">
                                            {/* <div className="img-left d-none d-md-flex">
                                            <div>

                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                                                    alt="login form" class="img-fluid" style={{ height: "100%" }} />
                                            </div>
                                        </div> */}

                                            <div className="card-body h-100" style={{ boxShadow: "rgb(148 183 11 / 67%) 2px 2px 20px 14px", borderRadius: "10px" }}>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ fontSize: "30px" }} onClick={() => { setImageShow({ value: false, image: "" }) }}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                {
                                                    imageShow?.image &&

                                                    <div className="w-100 h-100" style={{ padding: "10px 10px 25px 10px" }}>
                                                        <img src={imageShow.image} className="h-100 w-100" style={{ borderRadius: "20px" }} />
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                    </>
                )
            }

            <a class="whats-app" href="https://wa.link/6bjptw" target="_blank">
                <FaWhatsapp style={{}} />
            </a>
            <ToastContainer />
        </>
    )

}

export default ServicePage