

import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import Header from './pages/Header'
import WhyVyapar from './pages/WhyVyapar'
import Review from './pages/Review'
import Testimonials from './pages/TestiMonials'
import ContactUs from './pages/ContactUs'
import Footer from './pages/Footer'
import logo1 from '../../src/components/images/logo3-removebg-preview.png'
import { ToastContainer, toast } from "react-toastify"
import CartSidebar from "./pages/CartSidebar"
import { FaWhatsapp } from "react-icons/fa"

const FranchiseData = () => {
    const [faqData, setFaqData] = useState([
        { question: "What is the expected ROI for a Vyapar Bandhu franchise?", answer: "Franchisees typically see a return on investment within 6 months." },
        { question: "What kind of ongoing support will I receive?", answer: "We offer continuous support in marketing, operations, client acquisition, and more to ensure your success." },
        { question: "Do I have to pay any fee to be a Franchisee?", answer: "You will not have to pay fee to be a Franchisee." },
        { question: "Do I need a GST registration if I want to be a Franchisee?", answer: "On an initial stage you need not have a GST registration. " },
        { question: "How to contact Vyapar Bandhu for any query?", answer: "Feel free to call at support number. Once you enrolled, you will be provided with dedicated relationship manager. " },
        { question: "Who can become a franchisee with Vyapar Bandhu?", answer: "Anyone who want to start or setup their business in consultancy services to startups etc. can become our franchisee, join us and grow their business. " },
        { question: "Is an agreement required to become a franchisee?", answer: "Yes, upon approval each franchisee must sign a Franchisee agreement. " },
        { question: "How much are the franchisee commissions?", answer: "You will get a decent commission on all services. Commission value vary on service to service. Signup to know about commissions on the services you wish to serve." },
        { question: "When are franchisee commissions paid?", answer: "Franchisee commissions are processed twice in a month and paid at the fixed dates." },
        { question: "How am I paid commissions?", answer: "You will get the bank transfer on fixed dates. " },
        { question: "What type of marketing support will I receive in the program?", answer: "All services by franchisee get the free promotions from Vyapar Bandhu on regular basis to generate business and Vyapar Bandhu marketing team also works to generate business." },
        { question: "What support do Franchisee get?", answer: "All our Franchisee are given a relationship manager who will work with you, providing training, sales enablement, and handling your service execution journey. Additionally, we have an advisory team comprised of consultants to provide additional insight if required." },
        { question: "What happens if I want to dispute an amount of revenue I’ve been paid?", answer: "For any payment disputes, contact your Relationship Manager with the full details of the issue. We'll review your message and do our best to resolve it." },
        { question: "How do I cancel the franchisee after signing up?", answer: "All the details shall be provided in the franchisee agreement." },
    ])
    const [stateData, setStateData] = useState(false);
    const [districtData, setDistrictData] = useState();
    const [modalActive, setModalActive] = useState(false);
    const [cartStatus, setCartStatus] = useState(false);
    const [activeData, setActiveData] = useState('franchise')
    const [franchiseData, setFranchiseData] = useState({
        email: "",
        mobile: "",
        name: "",
        state: "",
        district: "",
        occupation: "",
        password: "",
        confirmpassword: "",
        id: ""
    });
    const [franchiseLogin, setFranchiseLogin] = useState({
        email: "",
        password: "",
    });
    const [franchiseForgot, setFranchiseForgot] = useState({
        email: "",
        password: "",
        confirmpassword: "",
    });
    const [franchiseloginStatus, setFranchiseloginStatus] = useState(false);


    const [emailError, setEmailError] = useState('');
    const [numberError, setNumberError] = useState('');

    console.log(franchiseData, 'franchiseDatafranchiseData')
    const handleNumberChange = (e) => {
        const onlyNumbers = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        setFranchiseData({ ...franchiseData, mobile: onlyNumbers })

        if (onlyNumbers.length !== 10) {
            setNumberError('Phone number must be exactly 10 digits');
        } else {
            setNumberError('');
        }
    };
    const validateEmail = (email) => {
        // Regex for validating email format
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmailChange = (e) => {
        const email = e.target.value;
        setFranchiseData({ ...franchiseData, email: email })

        if (!validateEmail(email)) {
            setEmailError('Invalid email format');
        } else {
            setEmailError('');
        }
    };
    const { pathname } = useLocation();

    const getLoginData = async (id) => {

        let url = `${process.env.REACT_APP_PORT}/admin/getbyIdfranchise`
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ id })
            });
            const data = await response.json();
            if (response.status === 200) {
                console.log(data)
                setFranchiseData({

                    email: data.email,
                    mobile: data.number,
                    name: data.name,
                    state: "",
                    district: "",
                    occupation: data.occupation,
                    password: "",
                    id: data._id,
                    confirmpassword: ""
                })
                setFranchiseloginStatus(true)
                setActiveData('loginData')
                localStorage.setItem("franchiseid", data.franchise_id);
                setFranchiseLogin({
                    email: "",
                    password: "",
                })
                // toast.success("Login Account Sucesfully!")



            } else {
                // toast.error(data.error)
            }
        } catch (e) {
            toast.error(e)

            // console.log(e, 'error')
        }


    }


    useEffect(() => {
        getLoginData(localStorage.getItem('franchiseid'))

    }, [localStorage.getItem('franchiseid')])

    const LogOut = () => {
        localStorage.removeItem('franchiseid')
        setActiveData("franchise")
        setFranchiseData({

            email: "",
            mobile: "",
            name: "",
            state: "",
            district: "",
            occupation: "",
            password: "",
            id: "",
            confirmpassword: ""



        })
    }

    const navigate = useNavigate()

    const FranchiseLogin = async () => {
        if (franchiseLogin.email && franchiseLogin.password) {

            let url = `${process.env.REACT_APP_PORT}/admin/franchiselogin`
            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ email: franchiseLogin.email, password: franchiseLogin.password })
                });
                const data = await response.json();
                if (response.status === 200) {
                    console.log(data)
                    localStorage.setItem("logintype", "franchise");
                    localStorage.setItem("franchiseid", data.franchise_id);
                    setFranchiseLogin({
                        email: "",
                        password: "",
                    })
                    setFranchiseloginStatus(true)
                    toast.success("Login Account Sucesfully!")
                    setActiveData('loginData')
                    navigate('/franchiseprofile')
                    // setFranchiseData({
                    //     email: data.email,
                    //     mobile: data.number,
                    //     name: data.name,
                    //     state: "",
                    //     district: "",
                    //     occupation: data.occupation,
                    //     password: "",
                    //     id: data._id,
                    //     confirmpassword: ""
                    // })



                } else {
                    toast.error(data.error)
                }
            } catch (e) {
                toast.error(e)

                console.log(e, 'error')
            }
        } else {
            toast.error("please fille all filled!")

        }

    }


    const CreateFranchiseAccount = async () => {
        console.log(franchiseData, 'franchisedata')
        if (franchiseData.email && franchiseData.name && franchiseData.mobile && franchiseData.password && franchiseData.confirmpassword) {
            if (franchiseData.password === franchiseData.confirmpassword) {
                let url = `${process.env.REACT_APP_PORT}/admin/franchisecreateAccount`
                try {
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ email: franchiseData.email, password: franchiseData.password, number: franchiseData.mobile, name: franchiseData.name, state: franchiseData.state, district: franchiseData.district, occupation: franchiseData.occupation, location: franchiseData.location })
                    });
                    const data = await response.json();
                    if (response.status === 200) {
                        toast.success("Create Account Sucesfully!")
                        setTimeout(() => {
                            setFranchiseData({
                                email: data.email,
                                mobile: data.number,
                                name: data.name,
                                state: "",
                                district: "",
                                occupation: data.occupation,
                                password: "",
                                id: data._id,
                                confirmpassword: ""
                            });
                            setFranchiseloginStatus(true);
                            setActiveData('loginData');
                            localStorage.setItem("franchiseid", data.franchise_id);
                            localStorage.setItem("logintype", "franchise");
                            setFranchiseLogin({
                                email: "",
                                password: "",
                            });
                            setActiveData('franchise');
                        }, 2000);

                    } else {
                        const data = await response.json();
                        toast.error(data.error)
                    }
                } catch (e) {
                    toast.error(e)

                    console.log(e, 'error')
                }
            } else {
                toast.error("password and confirmPassword not same!")
            }
        } else {

            toast.error("please fille all filled!")
        }


    }

    const ForgotFranchisePassword = async () => {
        if (franchiseForgot.email && franchiseForgot.password && franchiseForgot.confirmpassword) {
            if (franchiseForgot.password === franchiseForgot.confirmpassword) {
                let url = `${process.env.REACT_APP_PORT}/admin/ForgotfranchisePassword`
                try {
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ email: franchiseForgot.email, password: franchiseForgot.password })
                    });
                    if (response.status === 200) {
                        setFranchiseForgot({
                            email: "",
                            password: "",
                            confirmpassword: "",
                        })
                        toast.success("Password Update Sucesfully!")
                        setActiveData('franchise')

                    } else {
                        const data = await response.json();
                        toast.error(data.error)
                    }
                } catch (e) {
                    toast.error(e)

                    console.log(e, 'error')
                }
            } else {
                toast.error("password and confirmPassword not same!")
            }
        } else {

            toast.error("please fille all filled!")
        }


    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    const getStateData = async () => {
        try {
            const response = await fetch(`https://cdn-api.co-vin.in/api/v2/admin/location/states`);
            console.log(response, 'response')
            const data = await response.json();
            if (response.status === 200) {
                console.log(data, 'allData')
                setStateData(data.states)
            }
            console.log('Data received:', data);

        } catch (e) {
            console.log(e, 'error')
        }
    }
    console.log(stateData, 'stateData')
    
    useEffect(() => {
        getStateData()
        setFranchiseloginStatus(localStorage.getItem('franchiseid') ? true : false)
    }, [])
    const closeModal = () => {
        setModalActive(false);
    };


    const stateChange = async (e) => {
        setFranchiseData({ ...franchiseData, state: e.target.value })
        const getId = stateData?.filter((item) => item.state_name === e.target.value)
        console.log(getId)
        try {
            const response = await fetch(`https://cdn-api.co-vin.in/api/v2/admin/location/districts/${getId[0]?.state_id}`);
            console.log(response, 'response')
            const data = await response.json();
            if (response.status === 200) {
                console.log(data, 'districts')
                setDistrictData(data.districts)
            }
            console.log('Data received:', data);

        } catch (e) {
            console.log(e, 'error')
        }


    }

    return (
        <>
            <Header closeModal={closeModal} modalActive={modalActive} setModalActive={setModalActive} cartStatus={cartStatus} setCartStatus={setCartStatus} />
            <div className="container">
                <div className="my-5 px-sm-5 mx-sm-5">
                    <div className="mb-5">

                        <h1 className="text-center heading_main mb-1">Franchisee Opportunity of Vyapar Bandhu Consultancy LLP </h1>
                        <div className="mt-4 about_div mb-5">
                            <p className='f_20'>
                                Welcome to Vyapar Bandhu Consultancy LLP, your reliable partner in business service solutions. Operating on a PAN India basis, we cater to entrepreneurs who are either established in their businesses or are just beginning their entrepreneurial journey. Our mission is to assist you at every step to start and grow your business seamlessly, ensuring you can operate without hesitation.
                            </p>

                            {
                                localStorage.getItem('logintype') === "franchise" || !localStorage.getItem('logintype') &&



                                <section className="h-100 container_body mt-5 mb-3">
                                    <div className="container p-0 h-100">
                                        <div className="row px-3 d-flex align-items-center justify-content-center  login">
                                            <div className="col-md-10 col-lg-10 col-xl-9 card flex-row mx-auto px-0" style={{ boxShadow: "0px 0rem 2rem 15px rgba(0, 0, 0, 0.1)" }}>
                                                <div className="  d-none d-lg-flex ">
                                                    <div className=' d-flex align-items-center justify-content-center'>

                                                        <img src={logo1} className='w-75' />
                                                    </div>
                                                </div>

                                                <div className="card-body">
                                                    {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ fontSize: "30px" }} onClick={closeModal}>
                                                <span aria-hidden="true">&times;</span>
                                            </button> */}


                                                    {
                                                        activeData === "franchise" &&
                                                        <>

                                                            <h2 class="title text-center mt-4 modal_heading" >
                                                                Franchise account
                                                            </h2>
                                                            <div class="form-box px-3">
                                                                <div class="form-input">
                                                                    <span><i class="bi bi-envelope-fill"></i></span>
                                                                    <input type="email" tabIndex={1} placeholder="Email Address" tabindex="10" required value={franchiseLogin.email} onChange={(e) => { setFranchiseLogin({ ...franchiseLogin, email: e.target.value }) }} />
                                                                </div>
                                                                <div class="form-input">
                                                                    <span><i class="fa fa-key"></i></span>
                                                                    <input type="password" tabIndex={1} name="" placeholder="Password" tabindex="10" required value={franchiseLogin.password} onChange={(e) => { setFranchiseLogin({ ...franchiseLogin, password: e.target.value }) }} />
                                                                </div>

                                                                {/* <div class="mb-3">
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" id="cb1" name="" />
                                                                <label class="custom-control-label" for="cb1">Remember me</label>
                                                            </div>
                                                        </div> */}

                                                                <div class="mb-3">
                                                                    <button type="submit" tabIndex={1} tabindex="10" class="btn btn-block text-uppercase modal_button_bg" onClick={() => { FranchiseLogin() }}>
                                                                        Login
                                                                    </button>
                                                                </div>

                                                                <div class="text-right">
                                                                    <a class="forget-link" style={{ cursor: "pointer" }} onClick={() => { setActiveData('franchiseforgot') }}>
                                                                        Forget Password?
                                                                    </a>
                                                                </div>

                                                                {/* <div class="text-center ">
                                                            <h4 class="title text-center mb-2" style={{ color: 'rgb(25, 135, 84)' }}>
                                                                OR
                                                            </h4>
                                                        </div> */}

                                                                {/* <div class="row mb-3">
                                                            <div class="col-12 text-center">
                                                                <a onClick={() => { setCreateNumber(); setActiveData('OTP') }} class="w-auto btn btn-block btn-social btn-facebook modal_button_bg">
                                                                    login with Mobile Otp
                                                                </a>
                                                            </div>


                                                        </div> */}

                                                                <hr class="my-4" />

                                                                <div class="text-center mb-2">
                                                                    Don't have an account?
                                                                    <a class="register-link" onClick={() => { setActiveData('franchisecreate') }} style={{ cursor: "pointer" }} >
                                                                        Register here
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        activeData === "franchisecreate" &&
                                                        <>

                                                            <h2 class="title text-center mt-4 modal_heading" >
                                                                Create Account
                                                            </h2>
                                                            <div class="form-box px-3">

                                                                <div class="form-input">
                                                                    <span><i class="bi bi-person-circle"></i></span>
                                                                    <input tabIndex={1} style={{ marginBottom: "10px" }} type="email" placeholder="Name" tabindex="10" required value={franchiseData.name} onChange={(e) => { setFranchiseData({ ...franchiseData, name: e.target.value }) }} />
                                                                </div>
                                                                {/* <div class="form-input">
                                                                <span><i class="bi bi-envelope-fill"></i></span>
                                                                <input style={{ marginBottom: "10px" }} type="email" placeholder="Email Address" tabindex="10" required value={franchiseData.email} onChange={(e) => { setFranchiseData({ ...franchiseData, email: e.target.value }) }} />
                                                            </div> */}
                                                                <div class="form-input">
                                                                    <span><i class="bi bi-envelope-fill"></i></span>
                                                                    <input tabIndex={1} type="email" placeholder="Email Address" tabindex="10" required value={franchiseData.email} onChange={(e) => handleEmailChange(e)} />
                                                                    {emailError && <span className="error d-flex text-danger" style={{ position: 'absolute', marginTop: "34px", fontWeight: '500' }}><i class="bi bi-exclamation-circle mr-1"></i> {emailError}</span>}
                                                                </div>
                                                                {/* <div class="form-input">
                                                                <span><i class="fa-solid fa-phone-volume"></i></span>
                                                                <input style={{ marginBottom: "10px" }} type="tel" name="" placeholder="Mobile Number" pattern="[0-9]{3} [0-9]{3} [0-9]{4}" maxlength="12" tabindex="10" required
                                                                    value={franchiseData.mobile} onChange={(e) => {
                                                                        const onlyNumbers = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                                                                        setFranchiseData({ ...franchiseData, mobile: onlyNumbers })
                                                                    }}

                                                                />
                                                            </div> */}
                                                                <div class={`form-input ${emailError && "mt-2"} `}>
                                                                    <span><i class="fa-solid fa-phone-volume"></i></span>
                                                                    <input tabIndex={1} type="tel" name="" placeholder="Mobile Number" pattern="[0-9]{3} [0-9]{3} [0-9]{4}" maxlength="12" tabindex="10" required
                                                                        value={franchiseData.mobile} onChange={(e) => {
                                                                            handleNumberChange(e)
                                                                        }}

                                                                    />
                                                                    {numberError && <span className="error d-flex text-danger" style={{ position: 'absolute', marginTop: "35px", fontWeight: '500' }}><i class="bi bi-exclamation-circle mr-1"></i> {numberError}</span>}
                                                                </div>
                                                                <div class={`form-input mb-3 ${numberError && "mt-2"} `}>
                                                                    <span><i class="bi bi-pin-map-fill"></i></span>
                                                                     <input tabIndex={1} tabindex="10" style={{ marginBottom: "10px" }} type="text" name="" placeholder="State" required value={franchiseData.state} onChange={(e) => { setFranchiseData({ ...franchiseData, state: e.target.value }) }} /> 
                                                                    {/* <select tabIndex={1} tabindex="10" class="form-select p-2" aria-label="Default select example" style={{ borderRadius: "30px" }} onChange={(e) => { stateChange(e) }}>
                                                                        <option value='0'>Please Select State ....</option>
                                                                        {
                                                                            stateData?.length && stateData.map((data) => {
                                                                                return (
                                                                                    <>
                                                                                        <option value={data.state_name}>{data.state_name}</option>

                                                                                    </>
                                                                                )
                                                                            })
                                                                        }



                                                                    </select> */}
                                                                </div>
                                                                <div class={`form-input mb-3  `}>
                                                                    <span><i class="bi bi-pin-map-fill"></i></span>
                                                                    <input tabIndex={1} tabindex="10" style={{ marginBottom: "10px" }} type="text" name="" placeholder="District" required value={franchiseData.district} onChange={(e) => { setFranchiseData({ ...franchiseData, district: e.target.value }) }} />
                                                                    {/* <select tabIndex={1} tabindex="10" class="form-select p-2" aria-label="Default select example" style={{ borderRadius: "30px" }} onChange={(e) => { setFranchiseData({ ...franchiseData, district: e.target.value }) }}>
                                                                        <option value='0'>Please Select District ....</option>
                                                                        {
                                                                            districtData?.length && districtData.map((data) => {
                                                                                return (
                                                                                    <>
                                                                                        <option value={data.district_name}>{data.district_name}</option>

                                                                                    </>
                                                                                )
                                                                            })
                                                                        }



                                                                    </select> */}
                                                                </div>
                                                                {/* <div class="form-input mb-1">
                                                                    <span><i class="bi bi-pin-map-fill"></i></span>
                                                                    <input tabIndex={1} style={{ marginBottom: "10px" }} type="text" name="" placeholder="District" required value={franchiseData.district} onChange={(e) => { setFranchiseData({ ...franchiseData, district: e.target.value }) }} />
                                                                </div> */}
                                                                <div class="form-input mb-2">
                                                                    {/* <span><i class="bi bi-pin-map-fill"></i></span> */}
                                                                    <select tabIndex={1} tabindex="10" class="form-select p-2 " aria-label="Default select example" style={{ borderRadius: "30px" }} onChange={(e) => { setFranchiseData({ ...franchiseData, occupation: e.target.value }) }}>
                                                                        <option value='0'>Choose occupation</option>
                                                                        <option value='CA'>CA</option>
                                                                        <option value='CS'>CS</option>
                                                                        <option value='Advocate'>Advocate</option>
                                                                        <option value='Graduate'>Graduate</option>
                                                                        <option value='PostGraduate'>Post Graduate</option>
                                                                        <option value='Student'>Student</option>
                                                                        <option value='other'>other</option>

                                                                    </select>
                                                                    {/* <input style={{ marginBottom: "10px" }} type="text" name="" placeholder="occupation" required value={franchiseData.district} onChange={(e) => { setFranchiseData({ ...franchiseData, occupation: e.target.value }) }} /> */}
                                                                </div>
                                                                {/* <div class="form-input">
                                                            <span><i class="bi bi-geo-alt-fill"></i></span>
                                                            <input style={{ marginBottom: "10px" }} type="text" name="" placeholder="Location" required value={franchiseData.location} onChange={(e) => { setFranchiseData({ ...franchiseData, location: e.target.value }) }} />
                                                        </div> */}
                                                                <div class="form-input mt-3">
                                                                    <span><i class="fa fa-key"></i></span>
                                                                    <input tabIndex={1} tabindex="10" style={{ marginBottom: "10px" }} type="password" name="" placeholder="Password" required value={franchiseData.password} onChange={(e) => { setFranchiseData({ ...franchiseData, password: e.target.value }) }} />
                                                                </div>
                                                                <div class="form-input mt-2">
                                                                    <span><i class="fa-regular fa-circle-check"></i></span>
                                                                    <input tabIndex={1} tabindex="10" style={{ marginBottom: "10px" }} type="password" name="" placeholder="Confirm Password" required value={franchiseData.confirmpassword} onChange={(e) => { setFranchiseData({ ...franchiseData, confirmpassword: e.target.value }) }} />
                                                                </div>
                                                                <div class="mb-3">
                                                                    <button tabIndex={1} tabindex="10" type="submit" class="btn btn-block text-uppercase modal_button_bg" onClick={() => { CreateFranchiseAccount() }}>
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                                <div class="text-right">
                                                                    <a class="forget-link" style={{ cursor: "pointer" }} onClick={() => { setActiveData('franchise') }}>
                                                                        Back to login
                                                                    </a>
                                                                </div>




                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        activeData === "loginData" &&
                                                        <>

                                                            <h2 class="title text-center mt-3 modal_heading" >
                                                                View Profile
                                                            </h2>
                                                            <div class="form-box px-2 d-flex flex-column" style={{ justifyContent: 'center', alignItems: "center" }}>
                                                                <div style={{ width: "50%" }}>
                                                                    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp" alt="avatar"
                                                                        class="rounded-circle img-fluid" />
                                                                </div>

                                                                {/* <div class="form-input">
                                                                <span><i class="bi bi-person-circle"></i></span>
                                                                <input disabled style={{ marginBottom: "10px" }} type="email" placeholder="Name" tabindex="10" required value={franchiseData.name} onChange={(e) => { setFranchiseData({ ...franchiseData, name: e.target.value }) }} />
                                                            </div>
                                                            <div class="form-input">
                                                                <span><i class="bi bi-envelope-fill"></i></span>
                                                                <input disabled style={{ marginBottom: "10px" }} type="email" placeholder="Email Address" tabindex="10" required value={franchiseData.email} onChange={(e) => { setFranchiseData({ ...franchiseData, email: e.target.value }) }} />
                                                            </div>
                                                            <div class="form-input">
                                                                <span><i class="fa-solid fa-phone-volume"></i></span>
                                                                <input disabled style={{ marginBottom: "10px" }} type="tel" name="" placeholder="Mobile Number" pattern="[0-9]{3} [0-9]{3} [0-9]{4}" maxlength="12" tabindex="10" required
                                                                    value={franchiseData.mobile}

                                                                />
                                                            </div>
                                                            <div class="form-input">
                                                                <span><i class="bi bi-pin-map-fill"></i></span>
                                                                <input disabled style={{ marginBottom: "10px" }} type="text" name="" placeholder="state" required value={franchiseData.state} />
                                                            </div>
                                                            <div class="form-input">
                                                                <span><i class="bi bi-pin-map-fill"></i></span>
                                                                <input disabled style={{ marginBottom: "10px" }} type="text" name="" placeholder="district" required value={franchiseData.district} />
                                                            </div>
                                                            <div class="form-input">
                                                                <span><i class="fa fa-key"></i></span>
                                                                <input disabled style={{ marginBottom: "10px" }} type="text" name="" placeholder="district" required value={franchiseData.id} />
                                                            </div> */}


                                                                <button type="submit" class="btn btn-block text-uppercase modal_button_bg" onClick={() => { navigate('/franchiseprofile') }}>
                                                                    View Profile
                                                                </button>

                                                                {/* <div class="mb-3">
                                                        </div>
                                                        <div class="text-right">
                                                            <a class="forget-link" style={{ cursor: "pointer" }} onClick={() => { setActiveData('franchise') }}>
                                                                Back to login
                                                            </a>
                                                        </div> */}




                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        activeData === "franchiseforgot" &&
                                                        <>

                                                            <h2 class="title text-center mt-4 modal_heading" >
                                                                Forgot Password
                                                            </h2>
                                                            <div class="form-box px-3">
                                                                <div class="form-input">
                                                                    <span><i class="fa-regular fa-envelope"></i></span>
                                                                    <input type="text" placeholder="Email Address" tabindex="10" required
                                                                        value={franchiseForgot.email}
                                                                        onChange={(e) => { setFranchiseForgot({ ...franchiseForgot, email: e.target.value }) }}
                                                                    />
                                                                </div>
                                                                <div class="form-input">
                                                                    <span><i class="fa fa-key"></i></span>
                                                                    <input type="password" name="" placeholder=" New Password" required
                                                                        value={franchiseForgot.password}
                                                                        onChange={(e) => { setFranchiseForgot({ ...franchiseForgot, password: e.target.value }) }} />
                                                                </div>
                                                                <div class="form-input">
                                                                    <span><i class="fa-regular fa-circle-check"></i></span>
                                                                    <input type="password" name="" placeholder="Confirm Password" required
                                                                        value={franchiseForgot.confirmpassword}
                                                                        onChange={(e) => { setFranchiseForgot({ ...franchiseForgot, confirmpassword: e.target.value }) }}
                                                                    />
                                                                </div>



                                                                <div class="mb-3">
                                                                    <button type="submit" class="btn btn-block text-uppercase modal_button_bg" onClick={() => { ForgotFranchisePassword() }}>
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                                <div class="text-right">
                                                                    <a class="forget-link" style={{ cursor: "pointer" }} onClick={() => { setActiveData('franchise') }}>
                                                                        Back to login
                                                                    </a>
                                                                </div>


                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            }














                            <h1 className="text-center heading_main mb-1">Why Franchise with Us?  </h1>
                            <p className='f_20 mt-4'>
                                <ul>
                                    <li><span style={{ fontWeight: "500" }}>High Demand :</span> With the increasing need for professional business services, our platform is highly sought after by entrepreneurs nationwide.</li>
                                    <li><span style={{ fontWeight: "500" }}>Proven Success :</span> Vyapar Bandhu has a track record of helping businesses thrive with our comprehensive range of services.</li>
                                    <li><span style={{ fontWeight: "500" }}>Technological Edge :</span> Our online platform and mobile application provide an unmatched ease of doing business, leveraging the latest technology for efficient service delivery.</li>
                                    <li><span style={{ fontWeight: "500" }}>Comprehensive Services :</span> We offer a wide array of services, including Company Incorporation, GST Registration, Income Tax & TDS Return, Compliance, Environmental Clearances, and much more, ensuring businesses have all they need in one place.</li>
                                </ul>
                            </p>


                            <p className='f_20 mt-5'>
                                <div className="text-center" ><h4 className="text_green text-center">Support and Training  </h4></div>
                                <ul>
                                    <li><span style={{ fontWeight: "500" }}>Comprehensive Training :</span> Learn from our experienced consultants who will provide you with in-depth knowledge of our services and systems.</li>
                                    <li><span style={{ fontWeight: "500" }}>Marketing Support :</span> Benefit from our national and regional marketing campaigns designed to attract clients and enhance brand visibility.</li>
                                    <li><span style={{ fontWeight: "500" }}>Ongoing Assistance :</span> Receive continuous support in client acquisition, service delivery, operations, and troubleshooting.</li>
                                </ul>
                            </p>


                            <p className='f_20 mt-5'>
                                <div className="text-center"><h4 className="text_green">Franchisee Requirements  </h4></div>
                                <ul>
                                    <li><span style={{ fontWeight: "500" }}>Experience :</span> Prior experience in business consultancy, Taxation, HR, Advocacy, Accountant, or related fields is preferred but not mandatory.</li>
                                    <li><span style={{ fontWeight: "500" }}>Passion :</span> A strong commitment to helping businesses succeed and a dedication to maintaining high service standards.</li>
                                    <li><span style={{ fontWeight: "500" }}>Education :</span> at least graduate in any discipline, preference given to B.com, M.com, LLB, LLM, BBA, MBA, BCA, MCA, CS, CA, CMA, Certified Professionals etc.</li>
                                    <li><span style={{ fontWeight: "500" }}>Office :</span> A office space at a decent location with the area at least 200 sq. feet with office furniture.</li>
                                    <li><span style={{ fontWeight: "500" }}>Technical :</span> Should have a Laptop or desktop, Printer, Scanner, High Speed Wifi etc. </li>
                                </ul>
                            </p>
                            <p className='f_20 mt-5 '>
                                <div className="text-center"><h4 className="text_green">Application Process  </h4></div>
                                <ol>
                                    <li><span style={{ fontWeight: "500" }}>Submit Application :</span> Fill out our online application form or contact us directly.</li>
                                    <li><span style={{ fontWeight: "500" }}>Initial Meeting :</span> Meet with our franchise development team to discuss the opportunity.</li>
                                    <li><span style={{ fontWeight: "500" }}>Approval :</span> After review, receive approval and sign the franchise agreement.</li>
                                    <li><span style={{ fontWeight: "500" }}>Training :</span> Complete our comprehensive training program to prepare for your role as a franchisee.</li>
                                    <li><span style={{ fontWeight: "500" }}>Launch :</span> Open your Vyapar Bandhu Consultancy franchise and start offering services.</li>

                                </ol>
                            </p>


                        </div>

                        <h1 className="text-center heading_main mb-1">FAQs </h1>
                        <div className="container mt-5">
                            <div class="accordion" id="accordionExample">
                                {faqData.map((item, index) => {
                                    const collapseId = `collapse-${index}`;
                                    const headingId = `heading-${index}`;
                                    return (
                                        <div class="accordion-item" key={index}>
                                            <h2 class="accordion-header" id={headingId}>
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${collapseId}`} aria-expanded="false" aria-controls={collapseId}>
                                                    <span className="f_24" style={{ fontWeight: "500" }}>{item.question}</span>
                                                </button>
                                            </h2>
                                            <div id={collapseId} class="accordion-collapse collapse" aria-labelledby={headingId} data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <pre className="f_22" style={{ width: '100%', whiteSpace: 'pre-wrap', textAlign: 'justify', fontFamily: 'inherit' }} >
                                                        {/* {data.description} */}  {item.answer}
                                                    </pre>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <h1 className="text-center heading_main mb-1 mt-5">Contact Information</h1>
                        <div className="mt-4 about_div mb-5">
                            {/* <p className='f_20'>
                                Welcome to Vyapar Bandhu Consultancy LLP, your reliable partner in business service solutions. Operating on a PAN India basis, we cater to entrepreneurs who are either established in their businesses or are just beginning their entrepreneurial journey. Our mission is to assist you at every step to start and grow your business seamlessly, ensuring you can operate without hesitation.
                            </p> */}
                            <p className='f_24'>
                                Interested in becoming a Vyapar Bandhu franchisee? Contact our franchise development team at <b>franchise@vyaparbandhu.com</b> or whatsApp at <b>+91-8077425868 </b>or fill out the form.
                            </p>

                        </div>




                    </div>


                </div>
            </div>
            <WhyVyapar />
            <Review />
            <Testimonials />
            <ContactUs />
            <Footer />
            <ToastContainer />
            <a class="whats-app" href="https://wa.link/6bjptw" target="_blank">
                <FaWhatsapp style={{}} />
            </a>
            <CartSidebar cartStatus={cartStatus} setCartStatus={setCartStatus} />

        </>
    )
}

export default FranchiseData;