

const MultiImage = ({ data }) => {
    return (
        <>
            {data?.heading &&

                <h1 className="text-center heading_main  mt-5">{data?.heading}</h1>
            }
            {
                data?.description &&

                <h4 className=' mt-3 text-center mb-3' >
                    <div >
                        <pre className="f_24 custom-editor-content" style={{ width: '100%', fontSize: '20px',whiteSpace: 'pre-wrap', textAlign: 'justify', fontFamily: 'inherit' ,scrollbarWidth:"none"}} dangerouslySetInnerHTML={{ __html: data?.description }}>
                            {/* {data.description} */}
                        </pre>
                    </div>
                    {/* <pre style={{ width: '100%', whiteSpace: 'pre-wrap', textAlign: 'justify', fontFamily: 'inherit' }}>
                        {data?.description}
                    </pre> */}


                </h4>
            }
            {
                data?.images.length !== 0 &&
                <>
                    <div className="row">

                        {
                            data?.images.map((image, index) => {
                                return (
                                    <>
                                        {
                                            data?.images.length === 1 && image.filename &&
                                            <div className="p-2 mb-2  "  >

                                                <img style={{ borderRadius: "20px" ,}} src={`${process.env.REACT_APP_PORT}/admin/service/file/${image?.filename}`} className="mb-1 w-100" alt="No Previous Image" />
                                            </div>
                                            }
                                        {
                                            data?.images.length > 1 && image.filename &&

                                            <div className="p-2 mb-2 col-md-6 "  style={{height:'100%'}} >

                                                <img style={{ borderRadius: "20px",height:"90%" }} src={`${process.env.REACT_APP_PORT}/admin/service/file/${image?.filename}`}  className="mb-1 w-100 h-100" alt="No Previous Image" />
                                            </div>

                                        }

                                    </>
                                )

                            })
                        }
                    </div>
                </>
            }
        </>
    )
}
export default MultiImage