import React, { useEffect, useState } from "react";
import scanner from '../images/google-docs.png'
import Header from "./Header.jsx";
import Footer from "./Footer.jsx";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import CartSidebar from "./CartSidebar.jsx";
import { FaWhatsapp } from "react-icons/fa";


const DocumentPage = () => {

    const [tabName, setTabName] = useState([{ id: "1", name: "Resume Templates" }, { id: "2", name: "Cover Letter Templates" }, { id: "3", name: "Cv Letter Templates" }])
    const [activeTab, setActiveTab] = useState()
    const [documentList, setDocumentList] = useState();
    const [categoryList, setCategoryList] = useState()
    const [search, setSearch] = useState();
    const [searchData, setSearchData] = useState();
    const [activePremium, setActivePremium] = useState(0);
    const [activePremiumData, setActivePremiumData] = useState(0);
    const [modalActive, setModalActive] = useState(false);
    const [cartStatus, setCartStatus] = useState(false);



    const getData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PORT}/admin/templates/list`);
            const result = await response.json();
            if (response.status === 200) {
                console.log(result)
                setDocumentList(result);
            } else {
                console.log(`Error: ${response.status}`);
            }
        } catch (error) {
            console.log(`Error: ${error.message}`);
        } try {
            const response = await fetch(`${process.env.REACT_APP_PORT}/admin/templates/categorylist`);
            const data = await response.json();
            if (response.status === 200) {
                setCategoryList(data)
                setActiveTab()
            }
            console.log('Data received:', data);

        } catch (e) {
            console.log(e, 'error')
        }
    }
    useEffect(() => {

        getData();

    }, [])


    useEffect(() => {
        if (search && activePremium === 0) {
            console.log("call0");

            const updateData = documentList.filter(data =>
                data.name.toString().toLowerCase().includes(search.toLowerCase())
            );
            setSearchData(updateData);
        }
        else if (search && activePremium === 1) {
            console.log("call1");

            const updateData = documentList.filter(data =>
                data.name.toString().toLowerCase().includes(search.toLowerCase())
            );
            const freeData = updateData.filter(data => data.premium === false);
            setSearchData(freeData);
        }
        else if (search && activePremium === 2) {
            console.log("call2");

            const updateData = documentList.filter(data =>
                data.name.toString().toLowerCase().includes(search.toLowerCase())
            );
            const freeData = updateData.filter(data => data.premium === true);
            setSearchData(freeData);
        }
        else if (search && !activePremium) {
            console.log("call2");

            const updateData = documentList.filter(data =>
                data.name.toString().toLowerCase().includes(search.toLowerCase())
            );

            setSearchData(updateData);
        }
        else {
            setSearchData([]);
        }
    }, [search, activePremium]);
    console.log(searchData, 'setSearchData')
    console.log(search, 'setSearchData')

    useEffect(() => {
        if (activePremium === 0) {
            setActivePremiumData(documentList)
        }
        if (activePremium === 1) {
            const updateData = documentList.filter(data => data.premium === false);
            setActivePremiumData(updateData)
        }
        if (activePremium === 2) {
            const updateData = documentList.filter(data => data.premium === true);
            setActivePremiumData(updateData)
        }

    }, [activePremium])




    // const handlePremium = async (item) => {
    //     let url = `${process.env.REACT_APP_PORT}/admin/templates/payPremium`
    //     if (localStorage.getItem('email')) {

    //         try {
    //             const response = await fetch(url, {
    //                 method: "POST",
    //                 headers: { "Content-Type": "application/json" },
    //                 body: JSON.stringify({ id: item._id, email: localStorage.getItem('email') })
    //             });
    //             const data = await response.json();

    //             if (response.status === 200) {
    //                 console.log(data, 'datas_response')
    //                 toast.success("Delete data Sucesfully!")
    //             }
    //         } catch (e) {
    //             console.log(e, 'error')
    //         }
    //     } else {
    //         toast.error('please Login first!!!')
    //     }

    // }

    const handlePremium = async (item) => {
        // window.open(`${process.env.REACT_APP_PORT}/admin/templates/file/${item.fileName}`)
        if (localStorage.getItem('logintype')) {
            console.log(item)
            const orderData = { amount: item.price, currency: "INR" };
            let url = `${process.env.REACT_APP_PORT}/admin/templates/createPremium`
            try {
                const { data } = await axios.post(url, orderData);

                const options = {
                    key: 'rzp_test_6igAuqeDdhUC5t',
                    amount: data.amount,
                    currency: data.currency,
                    name: 'Vyapar Bandhu Consultancy LLP',
                    description: 'Test Transaction',
                    order_id: data.id,
                    handler: async (response) => {
                        console.log(response);
                        console.log(response.razorpay_payment_id ? true : false);

                        if (localStorage.getItem('logintype') === "user") {
                            let paymentUrl = `${process.env.REACT_APP_PORT}/admin/templates/payPremium`
                            const responseData = await fetch(paymentUrl, {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify({ razerpayData: response, item: item, email: localStorage.getItem('email') })
                            });
                            const data = await responseData.json();
                            if (responseData.status === 200) {
                                // window.open(`${process.env.REACT_APP_PORT}/admin/templates/file/${item.fileName}`)
                                //   setInvoiceOpen(false)
                                //   setFranchiseid()
                                //   setPromocode()
                                //   setSidebar(false)
                                toast.success('Payment Successfully')
                                getData()
                                //   setActiveSuccessModal(true)

                            } else {
                                toast.error('somthing get wrong')
                            }


                        } else {

                            let paymentUrl = `${process.env.REACT_APP_PORT}/admin/templates/payPremiumFranchise`
                            const responseData = await fetch(paymentUrl, {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify({ razerpayData: response, id: item._id, franchiseid: localStorage.getItem('franchiseid') })
                            });
                            const data = await responseData.json();
                            if (responseData.status === 200) {
                                // window.open(`${process.env.REACT_APP_PORT}/admin/templates/file/${item.fileName}`)
                                //   setInvoiceOpen(false)
                                //   setFranchiseid()
                                //   setPromocode()
                                //   setSidebar(false)
                                toast.success('Payment Successfully')
                                getData()
                                //   setActiveSuccessModal(true)

                            } else {
                                toast.error('somthing get wrong')
                            }
                        }
                    },
                    prefill: {
                        name: localStorage.getItem('name'),
                        email: localStorage.getItem('email'),
                        contact: localStorage.getItem('number')
                    },
                    theme: {
                        color: '#3399cc'
                    }
                };

                const rzp1 = new window.Razorpay(options);
                rzp1.open();


            } catch (e) {
                toast.error(e)
            }
        } else {
            setModalActive(true)
            // toast.error('please Login first!!!')
        }

    }
    const closeModal = () => {
        setModalActive(false);
    };
    return (
        <>
            <Header closeModal={closeModal} modalActive={modalActive} setModalActive={setModalActive} cartStatus={cartStatus} setCartStatus={setCartStatus} />

            <div className="banner_bg">
                <div className="bg_text h-100 heading_main" style={{background:"rgb(243 243 243 / 43%)"}}>
                    Important Documents Format
                </div>
            </div>
            <div className="navbar-light bg-light h-80">
                <nav className="navbar navbar-expand-lg navbar-light bg-light container d-flex h-80">
                    {/* <a className="navbar-brand f_24 heading_main" >Document</a> */}

                    <div className=" navbar-collapse " id="navbarNav" style={{ justifyContent: 'space-between' }}>
                        <div>
                            <button type="button" class={` f_20 mb-sm-0 mb-2 btn btn-outline-success ms-1 ${activePremium === 0 ? "btn-success text-white" : 'btn-outline-success'}`} onClick={() => { setActiveTab(); setActivePremium(0) }}>All</button>
                            <button type="button" class={`f_20 mb-sm-0 mb-2 btn btn-outline-success ms-1 ${activePremium === 1 ? "btn-success text-white" : 'btn-outline-success'}`} onClick={() => { setActivePremium(1) }}>Free</button>
                            <button type="button" class={`f_20 mb-sm-0 mb-2 btn btn-outline-success ms-1 ${activePremium === 2 ? "btn-success text-white" : 'btn-outline-success'}`} onClick={() => { setActivePremium(2) }}>Premium</button>
                        </div>

                        <div className="form-inline col-md-4 col-12">
                            <input style={{ padding: '10px', fontSize: "18px" }} className="form-control mr-sm-2 w-100" type="text" placeholder="Search" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                            {/* <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button> */}
                        </div>
                    </div>
                </nav>
            </div>

            {/* <div className="container text_second">
                <div className="f_30 f_600">On this page you will find a range of forms and documents available for instant download. All documents are supplied in Word format with instructions. Our company forms can be used by limited companies registered in England, Scotland and Wales. We have designed our forms to be suitable for most limited companies.</div>
            </div> */}

            <ul className="nav nav-pills nav-justified container mt-5" style={{ whiteSpace: "nowrap", gap: "12px" }}>
                {
                    categoryList && categoryList.map((item, index) => {

                        return (
                            <>
                                {
                                    activeTab == item.name ?

                                        <li className="nav-item">
                                            <button type="button" class="btn text-white active_tab f_20" onClick={() => { activePremium === 0 && setActivePremium(); setActiveTab(item.name) }}><b>{item.name}</b></button>
                                            {/* <a className={`nav-link f_20 active_tab text-white`} aria-current="page" onClick={() => { setActiveTab(item.name) }}><b>{item.name}</b></a> */}
                                        </li>
                                        :
                                        <li className="nav-item">
                                            <button type="button" class="btn btn-outline-success f_20" onClick={() => { activePremium === 0 && setActivePremium(); setActiveTab(item.name) }}><b>{item.name}</b></button>
                                        </li>
                                }
                            </>
                        )
                    })
                }
            </ul>

            <div className="container mt-5 mb-5">
                <div className="row">
                    {
                        activePremium === 0 && !search && documentList && documentList?.map((item, index) => {
                            console.log(localStorage.getItem('email'), 'localStorage.getItem')
                            let mathcData = "";
                            if (item?.Orders?.length > 0) {
                                mathcData = item.Orders.find((user) => user.email === localStorage.getItem('email'))
                            } else {
                                mathcData = "";
                            }
                            return (
                                <>
                                    {
                                        activePremium === 0 && !activeTab ?
                                            <div className="col-lg-3 col-sm-6 col-12 d-flex">
                                                <div className="card" style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 15px 10px' }}>
                                                    {item.premium === true ?
                                                        <>
                                                            <span class=" active_tab badge badge-primary position-absolute ml-2 mt-2 fs-6" >Premium</span>
                                                            <span class=" active_tab badge badge-primary position-absolute ml-2 mt-5 fs-6" >{item.price} ₹</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span class=" active_tab badge badge-primary position-absolute ml-2 mt-2 fs-6" >Free</span>
                                                        </>
                                                    }
                                                    <div className="pt-5 d-flex justify-content-center">

                                                        <img className="card-img-top " style={{ width: "35%" }} src={scanner} alt="Card image cap" />
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-center fs-5 mb-2 ">
                                                            <span className="f_18" style={{  fontWeight: "600" }}>{item.name}</span>
                                                        </div>
                                                        <div className="d-flex w-100 justify-content-center" >
                                                            {/* <div className="border_color text_green btn btn-outline-primary d-flex justify-content-center w_48" >
                                                            Preview
                                                        </div> */}
                                                            <div className="border_color text_green btn btn-outline-primary d-flex justify-content-center  button_hover" onClick={() => {
                                                                if (item.premium === false) (
                                                                    window.open(`${process.env.REACT_APP_PORT}/admin/templates/file/${item.fileName}`)
                                                                )
                                                                else {
                                                                    if (!mathcData) {
                                                                        console.log('call match')
                                                                        handlePremium(item)
                                                                    } else {
                                                                        window.open(`${process.env.REACT_APP_PORT}/admin/templates/file/${item.fileName}`)
                                                                    }
                                                                }
                                                            }} >
                                                                <b>Download</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                            :

                                            item.type === activeTab &&
                                            <div className="col-lg-3 col-sm-6 col-12 d-flex">
                                                <div className="card" style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 15px 10px' }}>
                                                    {item.premium === true ?
                                                        <>
                                                            <span class=" active_tab badge badge-primary position-absolute ml-2 mt-2 fs-6" >Premium</span>
                                                            <span class=" active_tab badge badge-primary position-absolute ml-2 mt-5 fs-6" >{item.price} ₹</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span class=" active_tab badge badge-primary position-absolute ml-2 mt-2 fs-6" >Free</span>
                                                        </>
                                                    }
                                                    <div className="pt-5 d-flex justify-content-center">

                                                        <img className="card-img-top " style={{ width: "35%" }} src={scanner} alt="Card image cap" />
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-center fs-5 mb-2 ">
                                                            <span className="f_18" style={{ color: "rgba(0,0,0,.55)", fontWeight: "600" }}>{item.name}</span>
                                                        </div>
                                                        <div className="d-flex w-100 justify-content-center" >
                                                            {/* <div className="border_color text_green btn btn-outline-primary d-flex justify-content-center w_48" >
                                                            Preview
                                                        </div> */}
                                                            <div className="border_color text_green btn btn-outline-primary d-flex justify-content-center  button_hover" onClick={() => {
                                                                if (item.premium === false) (
                                                                    window.open(`${process.env.REACT_APP_PORT}/admin/templates/file/${item.fileName}`)
                                                                )
                                                                else {
                                                                    if (mathcData === "") {

                                                                        handlePremium(item)
                                                                    } else {
                                                                        window.open(`${process.env.REACT_APP_PORT}/admin/templates/file/${item.fileName}`)
                                                                    }
                                                                }
                                                            }} >
                                                                <b>Download</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                    }

                                </>
                            )
                        })
                    }
                    {
                        activePremium !== 0 && !search && activePremiumData && activePremiumData?.map((item, index) => {

                            let mathcData = "";
                            if (item?.Orders?.length > 0) {
                                mathcData = item.Orders.find((user) => user.email === localStorage.getItem('email'))
                            } else {
                                mathcData = "";
                            }
                            return (
                                <>
                                    {
                                        item.type === activeTab &&
                                        <div className="col-lg-3 col-sm-6 col-12 d-flex">
                                            <div className="card" style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 15px 10px' }}>
                                                {item.premium === true ?
                                                    <>
                                                        <span class=" active_tab badge badge-primary position-absolute ml-2 mt-2 fs-6" >Premium</span>
                                                        <span class=" active_tab badge badge-primary position-absolute ml-2 mt-5 fs-6" >{item.price} ₹</span>
                                                    </>
                                                    :
                                                    <>
                                                        <span class=" active_tab badge badge-primary position-absolute ml-2 mt-2 fs-6" >Free</span>
                                                    </>
                                                }
                                                <div className="pt-5 d-flex justify-content-center">

                                                    <img className="card-img-top " style={{ width: "35%" }} src={scanner} alt="Card image cap" />
                                                </div>
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-center fs-5 mb-2 ">
                                                        <span className="f_18" style={{ color: "rgba(0,0,0,.55)", fontWeight: "600" }}>{item.name}</span>
                                                    </div>
                                                    <div className="d-flex w-100 justify-content-center" >
                                                        {/* <div className="border_color text_green btn btn-outline-primary d-flex justify-content-center w_48" >
                                                            Preview
                                                        </div> */}
                                                        <div className="border_color text_green btn btn-outline-primary d-flex justify-content-center  button_hover" onClick={() => {
                                                            if (item.premium === false) (
                                                                window.open(`${process.env.REACT_APP_PORT}/admin/templates/file/${item.fileName}`)
                                                            )
                                                            else {
                                                                if (mathcData === "") {

                                                                    handlePremium(item)
                                                                } else {
                                                                    window.open(`${process.env.REACT_APP_PORT}/admin/templates/file/${item.fileName}`)
                                                                }
                                                            }
                                                        }} >
                                                            <b>Download</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    }

                                </>
                            )
                        })
                    }
                    {console.log(searchData, 'searchData')}
                    {

                        search && searchData && searchData?.map((item, index) => {
                            return (
                                <>
                                    {
                                        activePremium === 0 && !activeTab ?
                                            <div className="col-lg-3 col-sm-6 col-12">
                                                <div className="card" style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 15px 10px' }}>
                                                    {item.premium === true ?
                                                        <>
                                                            <span class=" active_tab badge badge-primary position-absolute ml-2 mt-2 fs-6" >Premium</span>
                                                            <span class=" active_tab badge badge-primary position-absolute ml-2 mt-5 fs-6" >{item.price} ₹</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span class=" active_tab badge badge-primary position-absolute ml-2 mt-2 fs-6" >Free</span>
                                                        </>
                                                    }
                                                    <div className="pt-5 d-flex justify-content-center">

                                                        <img className="card-img-top" style={{ width: "35%" }} src={scanner} alt="Card image cap" />
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-center fs-5 mb-2 ">
                                                            <span className="f_18" style={{ color: "rgba(0,0,0,.55)", fontWeight: "600" }}>{item.name}</span>
                                                        </div>
                                                        <div className="d-flex w-100 justify-content-center" >
                                                            {/* <div className="border_color text_green btn btn-outline-primary d-flex justify-content-center w_48" >
                                                            Preview
                                                        </div> */}
                                                            <div className="border_color text_green btn btn-outline-primary d-flex justify-content-center w_48 button_hover" onClick={() => {
                                                                if (item.premium === false) (

                                                                    window.open(`${process.env.REACT_APP_PORT}/admin/templates/file/${item.fileName}`)
                                                                )
                                                                else {
                                                                    alert('You need to pay for this')
                                                                }
                                                            }} >
                                                                Download
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            :

                                            item.type === activeTab &&
                                            <div className="col-lg-3 col-sm-6 col-12">
                                                <div className="card" style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 15px 10px' }}>
                                                    {item.premium === true ?
                                                        <>
                                                            <span class=" active_tab badge badge-primary position-absolute ml-2 mt-2 fs-6" >Premium</span>
                                                            <span class=" active_tab badge badge-primary position-absolute ml-2 mt-5 fs-6" >{item.price} ₹</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span class=" active_tab badge badge-primary position-absolute ml-2 mt-2 fs-6" >Free</span>
                                                        </>
                                                    }
                                                    <div className="pt-5 d-flex justify-content-center">

                                                        <img className="card-img-top" style={{ width: "35%" }} src={scanner} alt="Card image cap" />
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-center fs-5 mb-2 ">
                                                            <span className="f_18" style={{ color: "rgba(0,0,0,.55)", fontWeight: "600" }}>{item.name}</span>
                                                        </div>
                                                        <div className="d-flex w-100 justify-content-center" >
                                                            {/* <div className="border_color text_green btn btn-outline-primary d-flex justify-content-center w_48" >
                                                            Preview
                                                        </div> */}
                                                            <div className="border_color text_green btn btn-outline-primary d-flex justify-content-center w_48 button_hover" onClick={() => {
                                                                if (item.premium === false) (

                                                                    window.open(`${process.env.REACT_APP_PORT}/admin/templates/file/${item.fileName}`)
                                                                )
                                                                else {
                                                                    alert('You need to pay for this')
                                                                }
                                                            }} >
                                                                Download
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                    }

                                </>
                            )
                        })
                    }



                </div>

            </div>

            <Footer />
            <a class="whats-app" href="https://wa.link/6bjptw" target="_blank">
                <FaWhatsapp style={{}} />
            </a>
            <CartSidebar cartStatus={cartStatus} setCartStatus={setCartStatus} />
            <ToastContainer/>

        </>
    );
};

export default DocumentPage;
