import React from 'react';
import profile from '../images/profile.png';
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import { Carousel } from 'react-bootstrap';

const Testimonials = () => {
    return (
        <>
            <div className='container d-lg-block d-none'>
                <h1 className='text-center heading_main  mt-5'>Testimonials</h1>
                <Carousel>
                    <Carousel.Item >
                        <div className=" p-2 text-center text-lg-start shadow-1-strong rounded">
                            <div className="row text-center d-flex align-items-stretch">
                                <div class="col-md-4 mb-5 mb-md-0 d-flex align-items-stretch">
                                    <div class="card testimonial-card">
                                        <div class="card-up" style={{ backgroundColor: 'rgb(254, 180, 68)' }}></div>
                                        <div class="avatar mx-auto bg-white">
                                            <img src={profile}
                                                class="rounded-circle img-fluid" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="mb-4">MOHD SAHID</h4>
                                            <hr />
                                            <p class="dark-grey-text mt-4">
                                                <i class="fas fa-quote-left pe-2"></i>Bahut hi achhi service M apna MSTC ka registration kraya h scrap dealer ka or GST b kraya h or ab meri return b Vyapar bandhu se hi file ho rhi h. Bahut hi shi rate me or jldi kam krana h to Vyapar Bandhu se achha koi nhi best h ek dm <i class="fas fa-quote-right pe-2"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-5 mb-md-0 d-flex align-items-stretch">
                                    <div class="card testimonial-card">
                                        <div class="card-up" style={{ backgroundColor: 'rgb(254, 180, 68)' }}></div>
                                        <div class="avatar mx-auto bg-white">
                                            <img src={profile}
                                                class="rounded-circle img-fluid" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="mb-4">Tahir Enterprises</h4>
                                            <hr />
                                            <p class="dark-grey-text mt-4">
                                                <i class="fas fa-quote-left pe-2"></i>Prompt reply of my query and doubt on GST and then I got my GST registration from Vyapar Bandhu only with in 3 days, it was amazing for me. Earlier I give my details and documents to some one in my town Kandhla 9 months ago but he was not able to get my GST registration.<i class="fas fa-quote-right pe-2"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-5 mb-md-0 d-flex align-items-stretch">
                                    <div class="card testimonial-card">
                                        <div class="card-up" style={{ backgroundColor: 'rgb(254, 180, 68)' }}></div>
                                        <div class="avatar mx-auto bg-white">
                                            <img src={profile}
                                                class="rounded-circle img-fluid" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="mb-4">HAMAD Enterprise</h4>
                                            <hr />
                                            <p class="dark-grey-text mt-4">
                                                <i class="fas fa-quote-left pe-2"></i>Bahut hi achhi service M apna MSTC ka registration kraya h scrap dealer ka or GST b kraya h or ab meri return b Vyapar bandhu se hi file ho rhi h. <i class="fas fa-quote-right pe-2"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item >
                        <div className=" p-2 text-center text-lg-start shadow-1-strong rounded">
                            <div className="row text-center d-flex align-items-stretch">
                                <div class="col-md-4 mb-5 mb-md-0 d-flex align-items-stretch">
                                    <div class="card testimonial-card">
                                        <div class="card-up" style={{ backgroundColor: 'rgb(254, 180, 68)' }}></div>
                                        <div class="avatar mx-auto bg-white">
                                            <img src={profile}
                                                class="rounded-circle img-fluid" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="mb-4">MOHD SAHID</h4>
                                            <hr />
                                            <p class="dark-grey-text mt-4">
                                                <i class="fas fa-quote-left pe-2"></i>Bahut hi achhi service M apna MSTC ka registration kraya h scrap dealer ka or GST b kraya h or ab meri return b Vyapar bandhu se hi file ho rhi h. Bahut hi shi rate me or jldi kam krana h to Vyapar Bandhu se achha koi nhi best h ek dm <i class="fas fa-quote-right pe-2"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-5 mb-md-0 d-flex align-items-stretch">
                                    <div class="card testimonial-card">
                                        <div class="card-up" style={{ backgroundColor: 'rgb(254, 180, 68)' }}></div>
                                        <div class="avatar mx-auto bg-white">
                                            <img src={profile}
                                                class="rounded-circle img-fluid" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="mb-4">Tahir Enterprises</h4>
                                            <hr />
                                            <p class="dark-grey-text mt-4">
                                                <i class="fas fa-quote-left pe-2"></i>Prompt reply of my query and doubt on GST and then I got my GST registration from Vyapar Bandhu only with in 3 days, it was amazing for me. Earlier I give my details and documents to some one in my town Kandhla 9 months ago but he was not able to get my GST registration.<i class="fas fa-quote-right pe-2"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-5 mb-md-0 d-flex align-items-stretch">
                                    <div class="card testimonial-card">
                                        <div class="card-up" style={{ backgroundColor: 'rgb(254, 180, 68)' }}></div>
                                        <div class="avatar mx-auto bg-white">
                                            <img src={profile}
                                                class="rounded-circle img-fluid" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="mb-4">HAMAD Enterprise</h4>
                                            <hr />
                                            <p class="dark-grey-text mt-4">
                                                <i class="fas fa-quote-left pe-2"></i>Bahut hi achhi service M apna MSTC ka registration kraya h scrap dealer ka or GST b kraya h or ab meri return b Vyapar bandhu se hi file ho rhi h. <i class="fas fa-quote-right pe-2"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </Carousel.Item>



                    {/* Add more Carousel.Items for additional testimonials */}

                </Carousel>
            </div>
            <div className='container d-lg-none d-md-block d-none'>
                <h1 className='text-center heading_main  mt-5'>Testimonials</h1>
                <Carousel>
                    <Carousel.Item >
                        <div className=" p-2 text-center text-lg-start shadow-1-strong rounded">
                            <div className="row text-center d-flex align-items-stretch">
                                <div class="col-6 mb-5 mb-md-0 d-flex align-items-stretch">
                                    <div class="card testimonial-card">
                                        <div class="card-up" style={{ backgroundColor: 'rgb(254, 180, 68)' }}></div>
                                        <div class="avatar mx-auto bg-white">
                                            <img src={profile}
                                                class="rounded-circle img-fluid" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="mb-4">MOHD SAHID</h4>
                                            <hr />
                                            <p class="dark-grey-text mt-4">
                                                <i class="fas fa-quote-left pe-2"></i>Bahut hi achhi service M apna MSTC ka registration kraya h scrap dealer ka or GST b kraya h or ab meri return b Vyapar bandhu se hi file ho rhi h. Bahut hi shi rate me or jldi kam krana h to Vyapar Bandhu se achha koi nhi best h ek dm <i class="fas fa-quote-right pe-2"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 mb-5 mb-md-0 d-flex align-items-stretch">
                                    <div class="card testimonial-card">
                                        <div class="card-up" style={{ backgroundColor: 'rgb(254, 180, 68)' }}></div>
                                        <div class="avatar mx-auto bg-white">
                                            <img src={profile}
                                                class="rounded-circle img-fluid" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="mb-4">Tahir Enterprises</h4>
                                            <hr />
                                            <p class="dark-grey-text mt-4">
                                                <i class="fas fa-quote-left pe-2"></i>Prompt reply of my query and doubt on GST and then I got my GST registration from Vyapar Bandhu only with in 3 days, it was amazing for me. Earlier I give my details and documents to some one in my town Kandhla 9 months ago but he was not able to get my GST registration.<i class="fas fa-quote-right pe-2"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className=" p-2 text-center text-lg-start shadow-1-strong rounded">
                            <div className="row text-center d-flex align-items-stretch">
                                <div class="col-6 mb-5 mb-md-0 d-flex align-items-stretch">
                                    <div class="card testimonial-card">
                                        <div class="card-up" style={{ backgroundColor: 'rgb(254, 180, 68)' }}></div>
                                        <div class="avatar mx-auto bg-white">
                                            <img src={profile}
                                                class="rounded-circle img-fluid" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="mb-4">MOHD SAHID</h4>
                                            <hr />
                                            <p class="dark-grey-text mt-4">
                                                <i class="fas fa-quote-left pe-2"></i>Bahut hi achhi service M apna MSTC ka registration kraya h scrap dealer ka or GST b kraya h or ab meri return b Vyapar bandhu se hi file ho rhi h. Bahut hi shi rate me or jldi kam krana h to Vyapar Bandhu se achha koi nhi best h ek dm <i class="fas fa-quote-right pe-2"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 mb-5 mb-md-0 d-flex align-items-stretch">
                                    <div class="card testimonial-card">
                                        <div class="card-up" style={{ backgroundColor: 'rgb(254, 180, 68)' }}></div>
                                        <div class="avatar mx-auto bg-white">
                                            <img src={profile}
                                                class="rounded-circle img-fluid" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="mb-4">Tahir Enterprises</h4>
                                            <hr />
                                            <p class="dark-grey-text mt-4">
                                                <i class="fas fa-quote-left pe-2"></i>Prompt reply of my query and doubt on GST and then I got my GST registration from Vyapar Bandhu only with in 3 days, it was amazing for me. Earlier I give my details and documents to some one in my town Kandhla 9 months ago but he was not able to get my GST registration.<i class="fas fa-quote-right pe-2"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </Carousel.Item>


                    {/* Add more Carousel.Items for additional testimonials */}

                </Carousel>
            </div>

            {/* //small screen */}
            <div className='container d-lg-none d-md-none  d-block'>
                <h1 className='text-center heading_main  mt-5'>Testimonials</h1>
                <Carousel>
                    <Carousel.Item >
                        <div className=" p-2 text-center text-lg-start shadow-1-strong rounded">
                            <div className="row text-center d-flex align-items-stretch">
                                <div class="col-12 mb-5 mb-md-0 d-flex align-items-stretch">
                                    <div class="card testimonial-card">
                                        <div class="card-up" style={{ backgroundColor: 'rgb(254, 180, 68)' }}></div>
                                        <div class="avatar mx-auto bg-white">
                                            <img src={profile}
                                                class="rounded-circle img-fluid" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="mb-4">MOHD SAHID</h4>
                                            <hr />
                                            <p class="dark-grey-text mt-4">
                                                <i class="fas fa-quote-left pe-2"></i>Bahut hi achhi service M apna MSTC ka registration kraya h scrap dealer ka or GST b kraya h or ab meri return b Vyapar bandhu se hi file ho rhi h. Bahut hi shi rate me or jldi kam krana h to Vyapar Bandhu se achha koi nhi best h ek dm <i class="fas fa-quote-right pe-2"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className=" p-2 text-center text-lg-start shadow-1-strong rounded">
                            <div className="row text-center d-flex align-items-stretch">

                                <div class="col-12 mb-5 mb-md-0 d-flex align-items-stretch">
                                    <div class="card testimonial-card">
                                        <div class="card-up" style={{ backgroundColor: 'rgb(254, 180, 68)' }}></div>
                                        <div class="avatar mx-auto bg-white">
                                            <img src={profile}
                                                class="rounded-circle img-fluid" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="mb-4">Tahir Enterprises</h4>
                                            <hr />
                                            <p class="dark-grey-text mt-4">
                                                <i class="fas fa-quote-left pe-2"></i>Prompt reply of my query and doubt on GST and then I got my GST registration from Vyapar Bandhu only with in 3 days, it was amazing for me. Earlier I give my details and documents to some one in my town Kandhla 9 months ago but he was not able to get my GST registration.<i class="fas fa-quote-right pe-2"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item >
                        <div className=" p-2 text-center text-lg-start shadow-1-strong rounded">
                            <div className="row text-center d-flex align-items-stretch">
                                <div class="col-12 mb-5 mb-md-0 d-flex align-items-stretch">
                                    <div class="card testimonial-card">
                                        <div class="card-up" style={{ backgroundColor: 'rgb(254, 180, 68)' }}></div>
                                        <div class="avatar mx-auto bg-white">
                                            <img src={profile}
                                                class="rounded-circle img-fluid" />
                                        </div>
                                        <div class="card-body">
                                            <h4 class="mb-4">MOHD SAHID</h4>
                                            <hr />
                                            <p class="dark-grey-text mt-4">
                                                <i class="fas fa-quote-left pe-2"></i>Bahut hi achhi service M apna MSTC ka registration kraya h scrap dealer ka or GST b kraya h or ab meri return b Vyapar bandhu se hi file ho rhi h. Bahut hi shi rate me or jldi kam krana h to Vyapar Bandhu se achha koi nhi best h ek dm <i class="fas fa-quote-right pe-2"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </Carousel.Item>


                    {/* Add more Carousel.Items for additional testimonials */}

                </Carousel>
            </div>
        </>
    );
};

export default Testimonials;
