import { useEffect, useState } from "react";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import Header from "./Header";
import OurWorkflow from "./OurWorkflow";
import Review from "./Review";
import Services from "./Services";
import Slider from "./Slider";
import TestiMonials from "./TestiMonials";
import WhyVyapar from "./WhyVyapar";
import '../styles/login.css'
import { ToastContainer, toast } from 'react-toastify';
import CartSidebar from "./CartSidebar";
import { FaWhatsapp } from "react-icons/fa";
import InvoicePDF from "./InvoicePDF";

import { PDFDownloadLink } from '@react-pdf/renderer';
const Home = () => {
    const [modalActive, setModalActive] = useState(false);
    const [cartStatus, setCartStatus] = useState(false);
    const [allData, setAllData] = useState();

    const openModal = () => {
        setModalActive(true);
    };

    const closeModal = () => {
        setModalActive(false);
    };



    const getList = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PORT}/admin/home/getData`);
            const data = await response.json();
            if (response.status === 200) {

                setAllData(data && data[0])
            }
            console.log('Data received:', data);


        } catch (e) {
            console.log(e, 'error')
        }

    }
    useEffect(() => {

        getList();

    }, [])
    const invoiceData = {
        billTo: 'Section-B QSR Private Limited',
        contactNo: '9953149874',
        gstin: '07ABNCS2468K1ZQ',
        state: '07-Delhi',
        invoiceNo: 'VB2324136',
        date: '17-06-2024',
        placeOfSupply: '07-Delhi',
        items: [
            { name: 'Incorporation of Private Limited Company', hsn: '', quantity: 1, price: 15000, total: 15000 },
            { name: 'Fssai Registration', hsn: '998312', quantity: 1, price: 3000, total: 3000 },
            { name: 'Drafting of Documents/ Agreement', hsn: '', quantity: 1, price: 10000, total: 10000 }
        ],
        total: 33000,
    };
    return (
        <>
            <div className="App">

                <Header closeModal={closeModal} modalActive={modalActive} setModalActive={setModalActive} cartStatus={cartStatus} setCartStatus={setCartStatus} />
                {/* <div>
                    <h1>Invoice Generator</h1>
                    
                    <PDFDownloadLink width="100%" 
                        document={<InvoicePDF invoiceData={invoiceData} />}
                        fileName="invoice.pdf"
                    >
                        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download Invoice')}
                    </PDFDownloadLink>
                </div> */}
                {
                    allData?.sliderImage &&

                    <Slider sliderImage={allData?.sliderImage} />
                }
                {
                    allData?.services &&

                    <Services services={allData?.services} />
                }
                {
                    allData?.workFlow &&

                    <OurWorkflow workFlow={allData?.workFlow} />
                }
                {allData?.about &&


                    <AboutUs about={allData?.about} />
                }
                {allData?.whyvyparbandhu &&


                    <WhyVyapar whyvyparbandhu={allData?.whyvyparbandhu} />
                }
                <Review />
                <TestiMonials />
                <ContactUs />
                <Footer />
                <ToastContainer />
                <a class="whats-app" href="https://wa.link/6bjptw" target="_blank">
                    <FaWhatsapp style={{}} />
                </a>
                <CartSidebar cartStatus={cartStatus} setCartStatus={setCartStatus} />

            </div>
        </>
    );
};

export default Home;
