import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import logo1 from '../images/logo3-removebg-preview.png';
import logo2 from '../images/pdflogo.jpg';
import scanner from '../images/scanner.png';
import sign from '../images/sign.jpg';
import { toWords } from 'number-to-words';

Font.register({
    family: 'Helvetica',
    fonts: [
        {
            src: 'path-to-your-fonts/Helvetica.ttf', // Regular
        },
        {
            src: 'path-to-your-fonts/Helvetica-Bold.ttf',
            fontWeight: 'bold',
        },
    ],
});

const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontSize: 12,
        fontFamily: 'Helvetica',
        position: 'relative',
    },
    headerContainer: {
        marginBottom: 25
    },
    header: {
        fontSize: 18,
        textAlign: 'center',
        marginBottom: 10,
    },
    mb2: {
        marginBottom: 2,
    },
    font: {
        fontSize: 10,
        textAlign: 'center',
        marginBottom: 5,
    },
    section: {
        marginBottom: 20,
    },
    textend: {
        textAlign: 'right',
    },
    detailsRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
        // alignItems: 'center',
    },
    tableHeader: {
        backgroundColor: '#00b7ff',
        color: '#fff',
    },
    tableRow: {
        flexDirection: 'row',
        marginBottom: "5px",
    },
    tableColHeader: {
        width: '15%',
        textAlign: 'center',
        padding: 5,
        fontSize: 10,
        fontWeight: 'bold',
    },
    tableCol: {
        width: '15%',
        textAlign: 'center',
        padding: 5,
        fontSize: 10,
    },
    tableColWide: {
        width: '40%',
        textAlign: 'left',
        padding: 5,
        fontSize: 10,
    },
    bold: {
        fontWeight: 'bold',
    },
    textCenter: {
        textAlign: 'center',
    },
    logo: {
        marginTop: 20,
        width: 50,
        height: 50,
    },
    divider: {
        borderBottomColor: '#000',
        borderBottomWidth: 1,
        marginBottom: 10,
        borderColor: '#00b7ff',
    },
    blueColor: {
        color: '#00b7ff',
    },
    footer: {
        position: 'absolute',
        bottom: 10,
        left: 30,
        right: 30,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 10,
    },
    footerLogo: {
        width: 100,
        height: 35,
    },
    scanner: {
        width: 65,
        height: 75,
    },
    content: {
        bottom: 30,
    },
    boldFont: {
        fontWeight: "bold",
    },
});

const numberToWords = ({ number }) => {
    return toWords(number).toUpperCase();
};

const InvoicePDF = ({ invoiceData,address }) => {
    console.log(invoiceData,address,'invoiceData')
    return (
        <>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.headerContainer} fixed>
                        <View style={styles.detailsRow}>
                            <View>
                                <Text style={[styles.header, styles.bold]}>Vyapar Bandhu Consultancy LLP</Text>
                                <Text style={[styles.font]}>A49/1, Garima Garden, Sahibabad, Ghaziabad, Uttar Pradesh, 201005</Text>
                                <Text style={[styles.font]}>Phone no.: +91-8272828300</Text>
                                <Text style={[styles.font]}>Email: vyaparbandhu@gmail.com</Text>
                                <Text style={[styles.font]}>GSTIN: 09AAUFV5273E1ZK</Text>
                                <Text style={[styles.font]}>State: 09-Uttar Pradesh</Text>
                            </View>
                            <Image style={styles.logo} src={logo1} />
                        </View>
                        <View style={styles.divider} />
                    </View>
                    <View style={styles.content}>
                        <Text style={[styles.bold, styles.blueColor, { textAlign: "center", marginTop: "5px", fontSize: 18, fontWeight: "bold" }]}>Tax Invoice</Text>
                        <View style={styles.detailsRow}>
                            <View style={styles.section}>
                                <Text style={[styles.bold, styles.mb2]}>Bill To </Text>
                                <Text style={[styles.bold, styles.mb2]}>{invoiceData?.userData?.name}</Text>
                                <Text>Contact No.: {invoiceData?.userData?.number}</Text>
                                <Text>GSTIN Number: </Text>
                                <Text>Address: {address}</Text>
                            </View>
                            <View style={[styles.section, styles.textend,{display:'flex' , justifyContent:'flex-end' , alignItems:"flex-end"}]}>
                                <Text style={[styles.bold, styles.mb2]}>Invoice Details</Text>
                                <Text style={styles.textend}>Invoice No.: VB2324136 </Text>
                                <Text style={styles.textend}>Date: {invoiceData?.createdAt}</Text>
                                <Text style={styles.textend}>Place of Supply: 07-Delhi</Text>
                            </View>
                        </View>
                        <View style={styles.section}>
                            <View style={[styles.tableRow, styles.tableHeader]}>
                                <Text style={styles.tableColHeader}>#</Text>
                                <Text style={[styles.tableColHeader, styles.tableColWide]}>Item name</Text>
                                <Text style={[styles.tableColHeader, styles.tableColWide]}>Package name</Text>
                                <Text style={styles.tableColHeader}>HSN/ SAC</Text>
                                <Text style={styles.tableColHeader}>Quantity</Text>
                                <Text style={styles.tableColHeader}>Price/ unit</Text>
                                <Text style={styles.tableColHeader}>Total Amount</Text>
                            </View>
                            {/* {invoiceData?.items?.map((item, index) => ( */}
                            <View style={styles.tableRow}>
                                <Text style={styles.tableCol}>1</Text>
                                <Text style={[styles.tableCol, styles.tableColWide]}>{invoiceData?.service}</Text>
                                <Text style={[styles.tableCol, styles.tableColWide]}>{invoiceData?.package?.package}</Text>
                                <Text style={styles.tableCol}>{invoiceData?.hsn}</Text>
                                <Text style={styles.tableCol}>1</Text>
                                <Text style={styles.tableCol}>{invoiceData?.discountamount}</Text>
                                <Text style={styles.tableCol}>{invoiceData?.discountamount}</Text>
                            </View>
                            {/* ))} */}
                            <View style={[styles.tableRow, { borderBottomWidth: 1, borderBottomStyle: "solid", borderTopWidth: 1, borderTopStyle: "solid" }]}>
                                <Text style={[styles.tableCol, { width: '85%', textAlign: 'start', fontWeight: 'bold', marginLeft: "30px" }]}>Total:</Text>
                                <Text style={[styles.tableCol, { width: '15%', fontWeight: 'bold', marginLeft: "20px" }]}>{invoiceData?.discountamount}</Text>
                            </View>
                        </View>
                        <View wrap={false} style={[{ alignItems: "flex-start", marginBottom: 20 }, styles.detailsRow]}>
                            <View>
                                <Text style={[styles.font, styles.boldFont, { fontWeight: "extrabold" }]}>Invoice Amount In Words</Text>
                                {
                                    invoiceData?.discountamount ?
                                        <Text style={[styles.font]}>{`${toWords(invoiceData?.discountamount).toUpperCase()} ONLY`}</Text>
                                        : 
                                        <Text style={[styles.font]}>None</Text>
                                }
                                <Text style={[styles.font, { marginTop: 5 }]}>Terms And Conditions</Text>
                                <Text style={[styles.font, { width: "40%" }]}>Payment to be made with in 15 days from the date of invoice
                                    or service provided whichever is earlier.
                                </Text>
                            </View>
                            <View style={[{ width: "45%" }]}>
                                <View style={[{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }]}>
                                    <Text style={[styles.font]}>Sub Total</Text>
                                    <Text style={[styles.font]}>{invoiceData?.discountamount}</Text>
                                </View>
                                <View style={[{ display: "flex", flexDirection: "row", fontSize: 10, paddingLeft: 2, paddingRight: 2, paddingBottom: 0, paddingTop: 3, justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#00b7ff', color: "white" }]}>
                                    <Text style={[styles.font]}>Total</Text>
                                    <Text style={[styles.font]}>{invoiceData?.discountamount}</Text>
                                </View>
                                <View style={[{ display: "flex", flexDirection: "row", marginTop: 2, justifyContent: 'space-between' }]}>
                                    <Text style={[styles.font]}>Received</Text>
                                    <Text style={[styles.font]}>0.00</Text>
                                </View>
                                <View style={[{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }]}>
                                    <Text style={[styles.font]}>Balance</Text>
                                    <Text style={[styles.font]}>{invoiceData?.discountamount}</Text>
                                </View>
                                <View style={[{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }]}>
                                    <Text style={[styles.font]}>Payment Mode </Text>
                                    <Text style={[styles.font]}>Credit</Text>
                                </View>
                                <View style={[{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }]}>
                                    <Text style={[styles.font]}>Previous Balance</Text>
                                    <Text style={[styles.font]}>0.00</Text>
                                </View>
                                <View style={[{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }]}>
                                    <Text style={[styles.font]}>Current Balance</Text>
                                    <Text style={[styles.font]}>{invoiceData?.discountamount}</Text>
                                </View>
                            </View>
                        </View>
                        <View wrap={false} style={[styles.detailsRow, { marginTop: 30 }]}>
                            <View style={[styles.section, { display: "flex", flexDirection: "row", gap: 3 }]}>
                                {/* <View>
                                    <Image style={styles.scanner} src={scanner} />

                                </View> */}
                                <View>
                                    <Text style={[styles.bold, styles.mb2, { fontSize: 12, marginBottom: 4 }]}>Pay To :</Text>
                                    <Text style={[styles.bold, styles.mb2, { fontSize: 10 }]}>Bank Name: KOTAK MAHINDRA BANK </Text>
                                    <Text style={[{ fontSize: 10 }]}>LIMITED,C,77 RDC, RAJ NAGAR DI</Text>
                                    <Text style={[{ fontSize: 10, marginTop: 4 }]}>Bank Account No.: 5746058438</Text>
                                    <Text style={[{ fontSize: 10, marginTop: 4 }]}>Bank IFSC code: KKBK0000677</Text>
                                    <Text style={[{ fontSize: 10, marginTop: 4 }]}>Account Holder's Name: Vyapar Bandhu</Text>
                                    <Text style={[{ fontSize: 10 }]}> Consultancy LLP</Text>
                                </View>
                            </View>
                            <View style={[styles.section, { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 7 }]}>
                                <Text style={[styles.bold, styles.mb2, { fontSize: 10 }]}>For: Vyapar Bandhu Consultancy LLP</Text>
                                <Image style={styles.footerLogo} src={sign} />
                                <Text style={[{ fontSize: 10, fontWeight: 500 }]}>Authorized Signatory</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.footer} fixed>
                        <Text>Vyapar Bandhu Consultancy LLP</Text>
                        {/* <Image style={styles.footerLogo} src={logo2} /> */}
                    </View>
                </Page>
            </Document>
        </>
    )
}

export default InvoicePDF;
