

const DocumentData = ({ data }) => {
    return (
        <>
            <div className="container mt-5">

                <h1 className='text-center heading_main  '>{data.heading}</h1>
                {
                    data?.description &&

                    <h4 className=' text-center mb-4' >
                        <pre className="f_24 custom-editor-content" style={{ width: '100%', whiteSpace: 'pre-wrap', textAlign: 'justify', fontFamily: 'inherit' }} dangerouslySetInnerHTML={{ __html: data?.description }}>
                        </pre>
                        {/* {data?.description} */}
                        {/* <pre style={{width:"100%" , whiteSpace:"pre-wrap", textAlign:"justify" , fontFamily:"inherit" , scrollbarWidth:"none"}}> {data?.description}</pre> */}
                    </h4>
                }
                {
                    data?.documentsData &&

                    <div className="row" >
                        <div class="row " style={{ display: "flex", flexWrap: "wrap" }}>
                            {
                                data?.documentsData?.map((SubData, index) => {
                                    const className = index % 2 === 0 ? 'text_green' : 'text_orange';
                                    return (
                                        <div class="col-xl-4 col-md-6 mb-3" style={{ display: "flex" }}>
                                            <div class={`card ${className}`} style={{ flex: "1 1 0", display: "flex", flexDirection: "column", marginBottom: "0px" }}>
                                                <div class="card-body" style={{ padding: "0.5rem", flex: "1", display: "flex", flexDirection: "column" }}>
                                                    <div class="d-flex align-items-center" style={{ flex: "1" }}>
                                                        <div class="ms-3">
                                                            <label className='text_workflow'><span className=''><img src={`${process.env.REACT_APP_PORT}/admin/service/file/${SubData && SubData.icon}`} width="60px" style={{ borderRadius: "5px" }} alt="" /></span><span className={`f_24 ${className} `} >{SubData.document}</span></label>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/* <div className="col-lg-6 col-12 px-5 py-2 mx-auto">
                            {
                                data?.documentsData?.map((SubData, index) => {
                                    const className = index % 2 === 0 ? 'text_green' : 'text_orange';
                                    return (
                                        <>
                                            <div key={index} className={`card py-3 px-5 ${className} mb-3`}>
                                                <label className='text_workflow'><span className=''><img src={`${process.env.REACT_APP_PORT}/admin/service/file/${SubData && SubData.icon}`} width="60px" style={{ borderRadius: "5px" }} alt="" /></span><span className={`ps-3 ${className} `}>{SubData.document}</span></label>
                                            </div>

                                        </>
                                    )
                                })
                            }



                        </div> */}


                    </div>
                }
            </div>

        </>
    )
}

export default DocumentData