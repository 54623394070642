import React from 'react'
// import Group from "../images/Group.png";
import workFlowImg from "../images/workflow.gif"
import { MdOutlineSecurity, MdBusinessCenter } from "react-icons/md";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { GiMoebiusTriangle } from "react-icons/gi";
import { TbReceiptTax } from "react-icons/tb";
const OurWorkflow = ({ workFlow }) => {
  return (
    <div className='py-sm-5 py-3 px-sm-5'>
      <div className=" px-5">
        <h1 className="text-center  heading_main">{workFlow?.heading}</h1>
        <div className="row " >
          {/* <div className="col-lg-6 col-12 px-sm-5 mx-auto">
            <div className='px-sm-4  px-2 ' >
              {
                workFlow?.data.map((item, index) => {
                  return (
                    <>
                      <div className={`card py-sm-3 px-sm-5  py-1 px-3 ${index === 0 || index === 2 || index === 4 ? "bg_color" : 'workflow_color'}`} >
                        <label className='text_workflow'><span className=''>
                          {index === 0 ? <MdOutlineSecurity /> : index === 1 ? <MdBusinessCenter /> : index === 2 ? <TbReceiptTax /> :
                            index === 3 ? <FaArrowRightArrowLeft /> : <MdOutlineSecurity />}


                        </span><span className='ps-3'>{item.name}</span></label>
                      </div>

                    </>
                  )
                })
              }
           
            </div>
          </div> */}
          <div className="col-12  d-flex align-items-center justify-content-center">
          <div className='w_60 '>

            <img  src={workFlow?.image ? `${process.env.REACT_APP_PORT}/admin/home/file/${workFlow?.image}` : workFlowImg}  alt="workflow" className='container-fluid p-0 '  />
          </div>
          </div>

        </div>

      </div>
    </div>
  )
}

export default OurWorkflow
