import { useEffect, useState } from 'react'
import '../styles/blog.css'
import blog from '../images/blog.png'
import popular from '../images/blog-popular.png'
import Header from './Header'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import CartSidebar from './CartSidebar'
import { FaWhatsapp } from 'react-icons/fa'


const Blog = () => {
    const [blogdata, setBlogData] = useState()
    const [category, setCategory] = useState();
    const [search, setSearch] = useState();
    const [searchData, setSearchData] = useState();
    const [categoryActive, setCategoryActive] = useState('All');
    const [popularBlog, setPopularBlog] = useState()
    const [modalActiveheader, setModalActiveheader] = useState(false);
    const [cartStatus, setCartStatus] = useState(false);

    const getList = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PORT}/admin/blog/list`);
            const data = await response.json();
            if (response.status === 200) {
                setBlogData(data)
                setPopularBlog(data)
            }
            console.log('Data received:', data);

        } catch (e) {
            console.log(e, 'error')
        }

    }
    const getCategoryList = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PORT}/admin/blog/categorylist`);
            const data = await response.json();
            if (response.status === 200) {
                setCategory(data)
            }
            console.log('Data received:', data);

        } catch (e) {
            console.log(e, 'error')
        }

    }
    useEffect(() => {

        getList();
        getCategoryList();

    }, [])


    function getVideoId(url) {
        // Regular expression to match YouTube URL patterns
        const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

        // Try to extract video ID from the URL using regular expression
        const match = url.match(regExp);

        // If a match is found, return the video ID
        if (match && match[1]) {
            return match[1];
        } else {
            // If no match is found, return null or handle the error accordingly
            return null;
        }
    }

    function truncateText(text, maxLength = 350) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    }
    function truncateLatestText(text, maxLength = 100) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    }


    const getSelectedCategory = async (name) => {

        setSearch();
        setSearchData();
        setCategoryActive(name)


        let url = `${process.env.REACT_APP_PORT}/admin/blog/filterByCategory`
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ name: name })
            });
            const data = await response.json();

            if (response.status === 200) {
                setBlogData(data)
                console.log(data)
            }
        } catch (e) {
            console.log(e, 'error')
        }
    }
    useEffect(() => {
        if (search) {
            console.log('Search term:', search);

            const updateData = blogdata.filter(data =>
                data.heading.toString().toLowerCase().includes(search.toLowerCase())
            );
            console.log('Filtered data:', updateData);
            setSearchData(updateData);
        } else {
            setSearchData([]);
        }
    }, [search]);


    const closeModalheader = () => {
        setModalActiveheader(false);
    };



    const handleShareClick = () => {
        const shareData = {
            title: 'Check out this blog post!',
            text: 'I found this interesting blog post. Have a look!',
            url: window.location.href
        };

        if (navigator.share) {
            navigator.share(shareData).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        } else {
            // Fallback for browsers that do not support the Share API
            alert('Sharing is not supported in this browser. Here is the URL: ' + shareData.url);
        }
    };


    return (
        <>
            <Header closeModal={closeModalheader} modalActive={modalActiveheader} setModalActive={setModalActiveheader} cartStatus={cartStatus} setCartStatus={setCartStatus} />
            <div id="main-content" class="blog-page mt-5 mb-5">
                <div class="container">
                    <div class="row clearfix">
                        <div className='col-12'>
                            <div class="card">
                                <div class="header" style={{ padding: "10px 20px" }}>
                                    <h2>Categories</h2>
                                </div>
                                <div class="body widget" style={{ padding: "0px 20px" }}>
                                    <ul class="list-unstyled categories-clouds  list_data m-b-0">
                                        <li className="nav-item">
                                            {
                                                categoryActive === 'All' ?

                                                    <button type="button" class="btn text-white active_tab f_20" onClick={() => { getSelectedCategory("All"); }}><b>{"All"}</b></button>
                                                    :
                                                    <button type="button" class="btn btn-outline-success f_20" onClick={() => { getSelectedCategory("All"); }}><b>{"All"}</b></button>

                                            }
                                            {/* <a className={`nav-link f_20 active_tab text-white`} aria-current="page" onClick={() => {  }}><b>{item.name}</b></a> */}
                                        </li>
                                        {/* <li onClick={() => { getSelectedCategory("All"); }}><Link className={`blog_category_li ${categoryActive === 'All' && 'blog_category_li_active'}`}>All</Link></li> */}

                                        {
                                            category?.map((item) => {
                                                return (
                                                    <>
                                                        {
                                                            categoryActive === item.categoryName ?

                                                                <li className="nav-item">
                                                                    <button type="button" class="btn text-white active_tab f_20" onClick={() => { getSelectedCategory(item.categoryName); }}><b>{item.categoryName}</b></button>
                                                                    {/* <a className={`nav-link f_20 active_tab text-white`} aria-current="page" onClick={() => {  }}><b>{item.name}</b></a> */}
                                                                </li>
                                                                :
                                                                <li className="nav-item">
                                                                    <button type="button" class="btn btn-outline-success f_20" onClick={() => { getSelectedCategory(item.categoryName); }}><b>{item.categoryName}</b></button>
                                                                </li>
                                                        }
                                                        {/* <li onClick={() => { getSelectedCategory(item.categoryName); }}><Link className={`blog_category_li ${categoryActive === item.categoryName && 'blog_category_li_active'}`}>{item.categoryName}</Link></li> */}
                                                    </>
                                                )
                                            })
                                        }
                                        {/* <li><a href="" style={{ color: "blue" }}>food</a></li>
                                        <li><a href="" style={{ color: "blue" }}>shopping</a></li>
                                        <li><a href="" style={{ color: "blue" }}>eCommerce</a></li>
                                        <li><a href="" style={{ color: "blue" }}>eCommerce</a></li>
                                        <li><a href="" style={{ color: "blue" }}>other</a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 right-box">
                            <div class="card">
                                <div class="body search">
                                    <div class="input-group m-b-0">
                                        <div class="input-group-prepend" onClick={() => {

                                        }}>
                                            <span class="input-group-text"><i class="fa fa-search"></i></span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Search..." value={search} onChange={(e) => { setSearch(e.target.value) }} />
                                    </div>
                                </div>
                            </div>
                            {/* <div class="card">
                                <div class="header">
                                    <h2>Categories</h2>
                                </div>
                                <div class="body widget">
                                    <ul class="list-unstyled categories-clouds  list_data m-b-0">
                                        {
                                            category?.map((item) => {
                                                return (
                                                    <>

                                                        <li><a href="" style={{ color: "blue" }}>{item.categoryName}</a></li>
                                                    </>
                                                )
                                            })
                                        }
                                     
                                    </ul>
                                </div>
                            </div> */}
                            <div class="card">
                                <div class="header text-center">
                                    <h2 className='heading_main ' style={{ fontSize: "40px" }}>Latest Posts</h2>
                                </div>
                                <div class="body widget popular-post">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            {
                                                popularBlog?.slice(-5).reverse().map((item) => {
                                                    return (
                                                        <>

                                                            <div class="single_post">
                                                                <h4 class="m-b-0 f_20">{item.heading}</h4>
                                                                <span>{item.date}</span>
                                                                <Link to="/blogdetails" state={item._id}>
                                                                    <div class="img-post">


                                                                        <img src={`${process.env.REACT_APP_PORT}/admin/blog/file/${item?.image}`} alt="Awesome Image" />
                                                                    </div>
                                                                    <p>
                                                                        <pre className='custom-editor-content' dangerouslySetInnerHTML={{ __html: truncateLatestText(item.details) }} style={{ width: '100%', whiteSpace: 'pre-wrap', textAlign: 'justify', fontFamily: 'inherit', fontSize: "18px", fontWeight: "400" }}>

                                                                            {/* {item.details} */}
                                                                        </pre>
                                                                    </p>
                                                                </Link>
                                                            </div>
                                                            <hr />
                                                        </>
                                                    )
                                                })
                                            }
                                            {/* <div class="single_post">
                                                <p class="m-b-0">new rules, more cars, more races</p>
                                                <span>jun 8, 2018</span>
                                                <div class="img-post">
                                                    <img src="https://www.bootdey.com/image/280x280/FFB6C1/000000" alt="Awesome Image" />
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="card">
                                <div class="header">
                                    <h2>Instagram Post</h2>
                                </div>
                                <div class="body widget">
                                    <ul class="list-unstyled instagram-plugin m-b-0">
                                        <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
                                        <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
                                        <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
                                        <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
                                        <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
                                        <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
                                        <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
                                        <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
                                        <li><a href="javascript:void(0);"><img src="https://www.bootdey.com/image/80x80/FFB6C1/000000" alt="image description" /></a></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* <div class="card">
                                <div class="header">
                                    <h2>Email Newsletter <small>Get our products/news earlier than others, let’s get in touch.</small></h2>
                                </div>
                                <div class="body widget newsletter">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Enter Email" />
                                        <div class="input-group-append">
                                            <span class="input-group-text"><i class="icon-paper-plane"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div class="col-lg-8 col-md-12 left-box">
                            {
                                !search && blogdata?.length ? blogdata?.slice().reverse().map((item) => {
                                    return (
                                        <>
                                            <div class="card single_post">
                                                <div class="body">
                                                    <div class="img-post">
                                                        <img class="d-block img-fluid w-100" src={`${process.env.REACT_APP_PORT}/admin/blog/file/${item?.image}`} alt="First slide" />
                                                    </div>
                                                    <h3 className='heading_main ' style={{ color: "#000047" }} dangerouslySetInnerHTML={{ __html: item.heading }}></h3>
                                                    {/* {
                                                        item.link && 

                                                    <div style={{ padding: "20px" }}>   
                                                        <iframe
                                                            title="YouTube video player"
                                                            width="100%"
                                                            height="400"
                                                            src={`https://www.youtube.com/embed/${getVideoId(item.link)}`}
                                                            allowFullScreen
                                                            style={{ borderRadius: "20px" }}
                                                        ></iframe>
                                                    </div>
                                                    } */}
                                                    <p>
                                                        <pre className='custom-editor-content' dangerouslySetInnerHTML={{ __html: truncateText(item.details) }} style={{ width: '100%', whiteSpace: 'pre-wrap', textAlign: 'justify', fontFamily: 'inherit', fontSize: "18px", fontWeight: "400" }}>

                                                            {/* {item.details} */}
                                                        </pre>
                                                    </p>
                                                </div>
                                                <div>

                                                </div>
                                                <div class="footer d-flex" style={{ justifyContent: "space-between", padding: "0px 30px 10px 30px" }}>
                                                    <div class="actions">
                                                        <b>Author Name : {item.author ? item.author : "unknown"} </b>
                                                    </div>
                                                    <div class="actions">
                                                        <b>Posted Date : {item.createdAt}</b>
                                                    </div>

                                                </div>
                                                <div class="footer d-flex" style={{ justifyContent: "space-between" }}>
                                                    <div class="actions">
                                                        <Link to="/blogdetails" state={item} class="btn btn-outline-primary" >Continue Reading</Link>
                                                    </div>
                                                    <div class="actions">
                                                        <Link class="btn btn-outline-primary" onClick={handleShareClick}><i class="bi bi-share"></i> Share </Link>
                                                    </div>

                                                </div>
                                            </div>

                                        </>
                                    )
                                })
                                    :
                                    <>
                                        {
                                            !searchData &&
                                            <div class="card single_post">
                                                <div class="body text-center">

                                                    <h3 className='text-center'>No Blogs</h3>

                                                </div>

                                            </div>
                                        }

                                    </>

                            }
                            {
                                search && searchData?.length ? searchData?.map((item) => {
                                    return (
                                        <>
                                            <div class="card single_post">
                                                <div class="body">
                                                    <div class="img-post">
                                                        <img class="d-block img-fluid w-100" src={`${process.env.REACT_APP_PORT}/admin/blog/file/${item?.image}`} alt="First slide" />
                                                    </div>
                                                    <h3 className='heading_main' style={{ color: "#000047" }} dangerouslySetInnerHTML={{ __html: item.heading }}></h3>
                                                    {/* {
                                                        item.link && 

                                                    <div style={{ padding: "20px" }}>   
                                                        <iframe
                                                            title="YouTube video player"
                                                            width="100%"
                                                            height="400"
                                                            src={`https://www.youtube.com/embed/${getVideoId(item.link)}`}
                                                            allowFullScreen
                                                            style={{ borderRadius: "20px" }}
                                                        ></iframe>
                                                    </div>
                                                    } */}
                                                    <p>
                                                        <pre className='custom-editor-content' dangerouslySetInnerHTML={{ __html: truncateText(item.details) }} style={{ width: '100%', whiteSpace: 'pre-wrap', textAlign: 'justify', fontFamily: 'inherit', fontSize: "18px", fontWeight: "400" }}>

                                                            {/* {item.details} */}
                                                        </pre>
                                                    </p>
                                                </div>
                                                <div class="footer d-flex" style={{ justifyContent: "space-between", padding: "0px 30px 10px 30px" }}>
                                                    <div class="actions">
                                                        <b>Author Name : {item.author ? item.author : "unknown"} </b>
                                                    </div>
                                                    <div class="actions">
                                                        <b>Posted Date : {item.createdAt}</b>
                                                    </div>

                                                </div>
                                                <div class="footer d-flex" style={{ justifyContent: "space-between" }}>
                                                    <div class="actions">
                                                        <Link to="/blogdetails" state={item._id} class="btn btn-outline-success" >Continue Reading</Link>
                                                    </div>
                                                    <div class="actions">
                                                        <Link class="btn btn-outline-success" ><i class="bi bi-share"></i> Share </Link>
                                                    </div>

                                                </div>
                                            </div>

                                        </>
                                    )
                                })
                                    :
                                    <>
                                        {
                                            search &&
                                            <div class="card single_post">
                                                <div class="body text-center">

                                                    <h3 className='text-center'>No Blogs</h3>

                                                </div>

                                            </div>
                                        }

                                    </>


                            }



                            {/* <ul class="pagination pagination-primary">
                                <li class="page-item"><a class="page-link" href="javascript:void(0);">Previous</a></li>
                                <li class="page-item active"><a class="page-link" href="javascript:void(0);">1</a></li>
                                <li class="page-item"><a class="page-link" href="javascript:void(0);">2</a></li>
                                <li class="page-item"><a class="page-link" href="javascript:void(0);">3</a></li>
                                <li class="page-item"><a class="page-link" href="javascript:void(0);">Next</a></li>
                            </ul> */}
                        </div>

                    </div>

                </div>
            </div>
            <a class="whats-app" href="https://wa.link/6bjptw" target="_blank">
                <FaWhatsapp style={{}} />
            </a>
            <Footer />
            <CartSidebar cartStatus={cartStatus} setCartStatus={setCartStatus} />
        </>
    )
}

export default Blog