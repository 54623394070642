import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import NoData from "../images/9214833.jpg"


const CartSidebar = ({ cartStatus, setCartStatus }) => {

    const [cartData, setCartData] = useState()
    const [activeSuccessModal, setActiveSuccessModal] = useState(false);
    const [modalActiveUpload, setModalActiveUpload] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [userData, setUserData] = useState();
    const [requiredData, setRequiredData] = useState();
    const [allDoument, setAllDocumnet] = useState(0);
    const [file, setFile] = useState()
    const [disble, setDisble] = useState(false)
    const [selectDocument, setSelectDocument] = useState()
    const [documentList, setDocumentList] = useState();
    const [payDetials, setPayDetails] = useState()
    const [userAllDetails, setUserAllDetails] = useState()
    const [promocode, setPromocode] = useState()
    const [franchiseid, setFranchiseid] = useState()
    const [offerData, setOfferData] = useState()
    const [invoiceOpen, setInvoiceOpen] = useState(false)
    const [imageShow, setImageShow] = useState({ value: false, image: "" })
    const [amount, setAmount] = useState('');
    const [currency, setCurrency] = useState('INR');
    const [orderId, setOrderId] = useState();
    const [totalAmount, setTotalAmount] = useState(0)


    const getList = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PORT}/admin/documentlist/list`);
            const data = await response.json();
            if (response.status === 200) {
                setDocumentList(data)
            }
            // console.log('Data received:', data);

        } catch (e) {
            console.log(e, 'error')
        }

    }

    const getById = async (id) => {
        try {
            let url = `${process.env.REACT_APP_PORT}/admin/service/getbyid`;

            const response = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ dataId: id })
            });
            const data = await response.json();
            if (response.status === 200) {
                // setSelectServiceData(data)
                // AllData(data)
                // HeadingData(data)
                setRequiredData(data?.stepFiveData[0]?.documentsData)
            }


        } catch (e) {
            // toast.error(`! ${e}`)
            console.log(e, 'error')
        }

    }

    useEffect(() => {
        getById(localStorage.getItem('stateId'))
    }, [localStorage.getItem('stateId')])

    const getData = async () => {
        if (localStorage.getItem('logintype') === "franchise") {

            let url = `${process.env.REACT_APP_PORT}/admin/getCartDatafranchise`;
            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ franchiseid: localStorage.getItem('franchiseid') })
                });
                const data = await response.json();
                if (response.status === 200) {
                    setCartData(data.cartData)
                    const totalDiscountAmount = data.cartData.reduce((total, item) => {
                        return total + (item.discount_amount > 0 ? item.discount_amount : item.amount);
                    }, 0);
                    console.log(totalDiscountAmount, 'totalDiscountAmount')
                    setTotalAmount(totalDiscountAmount ? totalDiscountAmount : 0)
                }
                console.log('Data received:', data);

            } catch (e) {
                console.log(e, 'error')
            }
        } else {

            let url = `${process.env.REACT_APP_PORT}/admin/getCartData`;
            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ email: localStorage.getItem('email') })
                });
                const data = await response.json();
                if (response.status === 200) {
                    setCartData(data.cartData)
                    const totalDiscountAmount = data.cartData.reduce((total, item) => {
                        return total + (item.discount_amount > 0 ? item.discount_amount : item.amount);
                    }, 0);
                    console.log(totalDiscountAmount, 'totalDiscountAmount')
                    setTotalAmount(totalDiscountAmount ? totalDiscountAmount : 0)
                }
                console.log('Data received:', data);

            } catch (e) {
                console.log(e, 'error')
            }
        }
    }

    const getUserData = async (id) => {

        if (localStorage.getItem('logintype') === "franchise") {

            let url = `${process.env.REACT_APP_PORT}/admin/getuserFranchise`
            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ id: localStorage.getItem('franchiseid') })
                });
                const data = await response.json();
                if (response.status === 200) {
                    setUserAllDetails(data?.user)
                    setUserData(data?.user?.documents)

                } else {
                }
            } catch (e) {
                toast.error(e)
            }
        } else {

            let url = `${process.env.REACT_APP_PORT}/admin/getuser`
            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ id: id })
                });
                const data = await response.json();
                if (response.status === 200) {
                    setUserAllDetails(data?.user)
                    setUserData(data?.user?.documents)

                } else {
                }
            } catch (e) {
                toast.error(e)
            }
        }
    }
    useEffect(() => {
        if (localStorage.getItem('logintype')) {

            getList()
            getUserData();
            getData();

        } else {

            // toast.error("please Login your account!!")
        }

    }, [cartStatus])

    const PayNow = (data) => {
        console.log(data, 'paynow')
        setAmount(data.discount_amount)
        if (localStorage.getItem('email')) {
            setSidebar(true)
        }
    }

    const openUploadModal = () => {
        setModalActiveUpload(true)
    };
    const closeUploadModal = () => {
        setModalActiveUpload(false);
    };

    const AddDocument = async () => {
        let url = `${process.env.REACT_APP_PORT}/admin/addDocuments`

        if (file && selectDocument !== "0") {
            setDisble(true)
            // const fileName = file.name + Date.now();
            // await uploadFile(fileName, file, callBack)
            const formData = new FormData();
            formData.append("file", file);
            formData.append("id", localStorage.getItem('id'));
            formData.append("name", selectDocument);

            try {
                const response = await fetch(url, {
                    method: "POST",
                    body: formData,
                });
                const data = await response.json();
                if (response.status === 200) {
                    setUserData(data?.user?.documents)
                    toast.success("Save Data Sucesfully!")
                    closeUploadModal()

                } else {
                    toast.error(data.error)
                }
            } catch (e) {
                toast.error(e)

                console.log(e, 'error')
            }

        }
    }
    const CheckPromo = async () => {
        if (promocode && franchiseid || promocode) {
            let url = `${process.env.REACT_APP_PORT}/admin/offer/getOfferBycode`
            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ code: promocode, franchiseid })
                });
                console.log(response.status, 'response.status')
                const data = await response.json();
                if (response.status === 200) {
                    toast.success('Promo Apply Successfully')

                    if (!data.length) {
                        toast.error("Promocode not found")
                    }
                    console.log(totalAmount - data[0].amount, 'totalData')
                    setOfferData(totalAmount - data[0].amount)
                    setAmount(totalAmount - data[0].amount)

                    // setActiveSuccessModal(true)

                }
                else {
                    console.log(response.status, 'data')
                    toast.error("Data not found")
                }
            } catch (e) {
                toast.error(e)
            }



        }
        else {
            toast.error("please fill code")
        }

    }
    console.log(franchiseid, 'franchise')


    const CreateOrder = async () => {

        let userId = localStorage.getItem('id')


        const orderData = { amount, currency };
        let url = `${process.env.REACT_APP_PORT}/admin/order/createOrder`
        try {
            const { data } = await axios.post(url, orderData);
            setOrderId(data.id);
            const options = {
                key: 'rzp_test_6igAuqeDdhUC5t', // Enter the Key ID generated from the Dashboard
                amount: data.amount,
                currency: data.currency,
                name: 'Vyapar Bandhu Consultancy LLP',
                description: 'Test Transaction',
                order_id: data.id, // This is the order ID returned by the backend
                handler: async (response) => {
                    console.log(response);
                    console.log(response.razorpay_payment_id ? true : false);
                    if (localStorage.getItem('logintype') === "franchise") {
                        let paymentUrl = `${process.env.REACT_APP_PORT}/admin/order/placedOrderFranchise`
                        const responseData = await fetch(paymentUrl, {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({ razerpayData: response, franchiseid, discountamount: offerData ? offerData : totalAmount, userData: userAllDetails, package: cartData, userId: userId })
                        });
                        const data = await responseData.json();
                        if (responseData.status === 200) {
                            DeleteItem(payDetials)
                            setInvoiceOpen(false)
                            setFranchiseid()
                            setPromocode()
                            setSidebar(false)
                            toast.success('Order Created Successfully')
                            setActiveSuccessModal(true)


                        } else {
                            toast.error('somthing get wrong')
                        }

                    } else {

                        let paymentUrl = `${process.env.REACT_APP_PORT}/admin/order/placedOrder`
                        const responseData = await fetch(paymentUrl, {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({ razerpayData: response, franchiseid, discountamount: offerData ? offerData : totalAmount, userData: userAllDetails, package: cartData, userId: userId })
                        });
                        const data = await responseData.json();
                        if (responseData.status === 200) {
                            DeleteItem(payDetials)
                            setInvoiceOpen(false)
                            setFranchiseid()
                            setPromocode()
                            setSidebar(false)
                            toast.success('Order Created Successfully')
                            setActiveSuccessModal(true)


                        } else {
                            toast.error('somthing get wrong')
                        }
                    }

                },
                prefill: {
                    name: userAllDetails.name,
                    email: userAllDetails.email,
                    contact: userAllDetails.number
                },
                theme: {
                    color: '#3399cc'
                }
            };

            const rzp1 = new window.Razorpay(options);
            rzp1.open();

        } catch (e) {
            toast.error(e)
        }
    }
    var allDocumentStatus;

    const DeleteItem = async (item) => {
        console.log(item, 'deleteitem')
        if (localStorage.getItem('logintype') === "franchise") {

            try {
                let url = `${process.env.REACT_APP_PORT}/admin/deleteCartItemfranchise`
                const response = await fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ franchiseid: localStorage.getItem('franchiseid'), docId: item._id })
                });
                const data = await response.json();
                if (response.status === 200) {
                    setDisble(false)
                    setCartData(data?.user?.cartData)
                    const totalDiscountAmount = data?.user?.cartData.reduce((total, item) => {
                        return total + (item.discount_amount > 0 ? item.discount_amount : item.amount);
                    }, 0);
                    console.log(totalDiscountAmount, 'totalDiscountAmount')
                    setTotalAmount(totalDiscountAmount ? totalDiscountAmount : 0)
                    toast.success("Delete Data Sucesfully!")
                } else {
                    toast.error(data.error)
                }
            } catch (e) {
                toast.error(e)

                console.log(e, 'error')
            }
        } else {
            try {
                let url = `${process.env.REACT_APP_PORT}/admin/deleteCartItem`
                const response = await fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ email: localStorage.getItem('email'), docId: item._id })
                });
                const data = await response.json();
                if (response.status === 200) {
                    setDisble(false)
                    setCartData(data?.user?.cartData)
                    const totalDiscountAmount = data?.user?.cartData.reduce((total, item) => {
                        return total + (item.discount_amount > 0 ? item.discount_amount : item.amount);
                    }, 0);
                    console.log(totalDiscountAmount, 'totalDiscountAmount')
                    setTotalAmount(totalDiscountAmount ? totalDiscountAmount : 0)
                    toast.success("Delete Data Sucesfully!")


                } else {
                    toast.error(data.error)
                }
            } catch (e) {
                toast.error(e)

                console.log(e, 'error')
            }

        }
    }

    useEffect(() => {
        setFranchiseid(localStorage.getItem('franchiseid'))
    }, [localStorage.getItem('franchiseid')])

    return (
        <>
            {
                localStorage.getItem('logintype') && cartStatus &&
                <>


                    <div style={{ visibility: "visible" }} class="offcanvas offcanvas-end show " role="dialog" aria-modal="true" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" >
                        <div class="offcanvas-header">
                            <h3 className="text-center heading_main mb-1" style={{ fontSize: "45px" }}>Cart </h3>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setCartStatus(false) }}></button>
                        </div>
                        <div class="offcanvas-body">
                            <div className="container">
                                {/* <div className="d-flex justify-content-center heading_main text-center"> Explore Our Company Incorporation Packages</div> */}
                                {/* <h1 className=' d-flex  justify-content-center heading_main '>Explore Our Company Incorporation Packages</h1> */}
                                <div className="d-flex row " style={{ justifyContent: 'space-around', height: "72vh", overflowY: "auto" }}>
                                    <div>

                                        {
                                            cartData?.map((data, index) => {


                                                return (
                                                    <>
                                                        {
                                                            data?.package && data?.amount ?

                                                                <div className="col-12 p-0" style={{ display: "flex", alignItems: 'stretch' }}>

                                                                    <div class="card " style={{ borderRadius: "15px", boxShadow: "none", border: "1px solid #cbcbcb" }} >
                                                                        <div className="text-start px-4 py-2  text_workflow d-flex f_24" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px",  flexDirection: 'column', alignItems: 'start', justifyContent: 'start', color: "black" }}>
                                                                            <div className="d-flex w-100" style={{ justifyContent: "space-between" }}>

                                                                                <div className="f_28">{data.package}</div>
                                                                                <div className="d-flex w-auto align-items-center">

                                                                                <button onClick={() => { DeleteItem(data) }} type="button" class="btn btn-danger mt-2"><i class="bi bi-trash"></i></button>
                                                                                </div>

                                                                            </div>
                                                                            {
                                                                                data.discount_amount > 0 ?
                                                                                    <>

                                                                                        <div className="d-flex">
                                                                                            <div className="mt-2" style={{ backgroundColor: "rgb(254, 180, 68)", padding: "0px 13px", borderRadius: "5px", fontSize: "14px" }}>Save {data.amount - data.discount_amount}₹</div>
                                                                                            <div className="mt-2 ml-2" style={{ fontSize: "14px", alignItems: "center", textDecoration: "line-through" }}>{data.amount}</div>
                                                                                        </div>
                                                                                        <div className="f_28">₹ {data.discount_amount}</div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <div className="f_28">₹ {data.amount}</div>
                                                                                    </>

                                                                            }

                                                                        </div>
                                                                        <div className="text-start mb-1 px-3 mt-1  w-100 " style={{ justifyContent: 'space-between' }}>

                                                                            {/* <button className="btn px-md-4 text-white f_20 w-100 " style={{ background: "#198754" }} onClick={() => { PayNow(data); setPayDetails(data) }}><b>Pay Now</b></button> */}
                                                                            {/* <button className="btn px-md-4 text-white f_20 " style={{ background: "#198754" }} onClick={() => { AddToCart(data) }}><b><i class="bi bi-cart-fill"></i></b></button> */}
                                                                        </div>
                                                                        <div class="card-body p-sm-3 p-2">
                                                                            <div className="custom-editor-content" dangerouslySetInnerHTML={{ __html: data.description }}></div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                ""
                                                        }

                                                    </>
                                                )
                                            })
                                        }
                                        {
                                            cartData?.length === 0 &&
                                            <>
                                                <div className="d-flex ">
                                                    <img src={NoData} className="w-100" style={{ borderRadius: "30px" }} />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="mt-auto d-flex flex-column" style={{ justifyContent: '', alignItems: "center" }}>
                                    <div className="d-flex w-100 mt-2" style={{ justifyContent: 'space-between' }}>
                                        <div className="f_18"><b>Total Amount</b> </div>
                                        <div className=" f_18 "><b>{totalAmount}₹</b> </div>

                                    </div>
                                    <button disabled={ cartData?.length === 0 ? true: false} className="btn btn-primary w-100 mt-1" onClick={() => { setAmount(totalAmount); setSidebar(true) }}>Next</button>
                                </div>
                            </div>
                        </div>
                    </div>





                </>

            }


            {
                sidebar &&
                <>
                    <div className="modal-overlay" ></div>
                    <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">

                        <section className="vh-100 container_body">
                            <div className="container py-5 h-100">
                                <div className="row px-3 d-flex align-items-center justify-content-center h-100">
                                    <div className="col-md-10  col-lg-10 col-xl-9 card flex-row mx-auto px-0  " style={{ width: "90%", maxHeight: '100%' }}>


                                        <div className="card-body p-sm-4 p-2" style={{ overflow: "hidden" }}>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ fontSize: "30px" }} onClick={() => {
                                                setSidebar(false); setInvoiceOpen(false)
                                                setFranchiseid()
                                                setPromocode()
                                            }}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>

                                            {
                                                !invoiceOpen ?
                                                    <>

                                                        <h2 class="title text-center mt-3 modal_heading" >
                                                            Place Order
                                                        </h2>
                                                        <div className="d-flex w_50 flex-column" style={{ marginLeft: 'auto', marginRight: 'auto', maxHeight: "90%" }}>

                                                            {/* <ul class="p-0 flex-column mb-sm-auto mb-0 align-items-start" style={{ maxHeight: "40%", overflow: 'auto' }} id="menu" >

                                                                {
                                                                    requiredData?.map((item) => {
                                                                        allDocumentStatus = requiredData.every(item => {
                                                                            const dataStatus = userData.filter(data => data.name === item.document);
                                                                            return dataStatus.length > 0;
                                                                        });
                                                                        const dataStatus = userData.filter((data) => data.name === item.document)

                                                                        const image = dataStatus.length > 0 ? userData.filter((data) => data.name === item.document) : "";


                                                                        return (
                                                                            <>

                                                                                <li class="nav-item d-flex w-100" style={{ fontSize: "20px", padding: '5px 0px', justifyContent: 'space-between' }}>
                                                                                    {
                                                                                        dataStatus.length > 0 ?
                                                                                            <>
                                                                                                <div className="w-75 d-flex">

                                                                                                    <i class="bi bi-check2-circle" style={{ color: "#00e300" }}></i>
                                                                                                    <span class="ms-1  d-inline" >{item.document}</span>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <button type="button" class="btn btn-primary" style={{ border: "none" }} onClick={() => { setImageShow({ value: true, image: image ? image[0].image : "" }) }}  ><i class="bi bi-eye"></i></button>

                                                                                                </div>

                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <div className="w-75 d-flex">

                                                                                                    <i class="bi bi-exclamation-circle" style={{ color: "rgb(255 0 0)" }}></i>
                                                                                                    <span class="ms-1  text-justify" >{item.document}</span>
                                                                                                </div>
                                                                                                {
                                                                                                    !allDocumentStatus &&

                                                                                                    <div>
                                                                                                        <button type="button" class="btn btn-primary" style={{ backgroundColor: "rgb(254, 180, 68)", border: "none" }} onClick={() => { openUploadModal(); setSelectDocument(item.document) }} disabled={allDocumentStatus ? true : false}><i class="bi bi-cloud-arrow-up"></i></button>

                                                                                                    </div>
                                                                                                }
                                                                                            </>
                                                                                    }


                                                                                </li>


                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                            </ul> */}
                                                            <div class="modal-footer">

                                                                <div class="form-input w-100 d-flex flex-column align-items-center" style={{ gap: '10px' }}>
                                                                    <span></span>
                                                                    {
                                                                        localStorage.getItem('franchiseid') ?
                                                                            <></>
                                                                            :


                                                                            <input className="mb-0" type="text" placeholder="Franchise id if any.." tabindex="10" required style={{ paddingLeft: "25px" }}
                                                                                value={franchiseid}
                                                                                onChange={(e) => { setFranchiseid(e.target.value) }}
                                                                            />
                                                                    }
                                                                    <input className="mb-0" type="text" placeholder="Promocode.." tabindex="10" required style={{ paddingLeft: "25px" }}
                                                                        value={promocode}
                                                                        onChange={(e) => { setPromocode(e.target.value) }}
                                                                    />
                                                                    {/* <button onClick={() => { CheckPromo() }} type="button" class="btn btn-primary" style={{ border: "none", background: "#198754", width: "max-content", fontSize: "20px", padding: "5px 30px" }} >Apply</button> */}

                                                                </div>


                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-primary"
                                                                    style={{ backgroundColor: "#198754", border: "none", padding: '10px', fontSize: "25px", width: "100%", fontWeight: "600" }}
                                                                    // onClick={() => { CreateOrder() }}
                                                                    onClick={() => { CheckPromo() }}
                                                                >Apply Code</button>
                                                                <button type="button" class="btn btn-primary"
                                                                    style={{ backgroundColor: "#198754", border: "none", padding: '10px', fontSize: "25px", width: "100%", fontWeight: "600" }}
                                                                    // onClick={() => { CreateOrder() }}
                                                                    onClick={() => { setInvoiceOpen(true) }}
                                                                >Next</button>


                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <h2 class="title text-center mt-4 modal_heading" >
                                                            Oreder Details
                                                        </h2>
                                                        <div class="p-sm-2 p-0  modal-footer mt-2 d-flex flex-column justify-content-center">




                                                            <div class="card" style={{ boxShadow: "none" }}>
                                                                <div class="card-body mx-sm-4 p-0">
                                                                    <div class="container w-75 d-md-block d-none">
                                                                        {
                                                                            console.log(payDetials, 'payDetials')
                                                                        }

                                                                        {/* <div class="d-flex">
                                                                            <div className="w-50"><b>Service Name</b></div>
                                                                            <div>: <span>{payDetials.category}</span></div>
                                                                        </div>
                                                                        <hr style={{ border: "1px solid black;" }} />
                                                                        <div class="d-flex">
                                                                            <div className="w-50"><b>Package Name</b></div>
                                                                            <div>: <span>{payDetials.package}</span></div>
                                                                        </div> */}
                                                                        <hr style={{ border: "1px solid black;" }} />
                                                                        <div class="d-flex">
                                                                            <div className="w-50"><b>Package Amount</b></div>
                                                                            <div>: <span>{totalAmount}</span></div>
                                                                        </div>
                                                                        <hr style={{ border: "1px solid black;" }} />
                                                                        {console.log(offerData, 'offerData')}
                                                                        {
                                                                            promocode &&
                                                                            <>
                                                                                <div class="d-flex">
                                                                                    <div className="w-50"><b>Discount Amount</b></div>
                                                                                    <div>: <span>{offerData}</span></div>
                                                                                </div>
                                                                                <hr style={{ border: "1px solid black;" }} />

                                                                            </>
                                                                        }
                                                                        {
                                                                            franchiseid &&
                                                                            <>
                                                                                <div class="d-flex">
                                                                                    <div className="w-50"><b>Franchiseid</b></div>
                                                                                    <div>: <span>{franchiseid}</span></div>
                                                                                </div>
                                                                                <hr style={{ border: "1px solid black;" }} />

                                                                            </>
                                                                        }
                                                                        <div class="d-flex justify-content-center">
                                                                            <div><b>You Need to Pay {offerData ? offerData : totalAmount}</b></div>
                                                                            <hr style={{ border: "1px solid black;" }} />
                                                                        </div>


                                                                        <div>
                                                                            <button type="button" class="btn btn-primary"
                                                                                style={{ backgroundColor: "rgb(25, 135, 84)", border: "none", padding: '12px', fontSize: "30px", width: "100%", fontWeight: "600" }}
                                                                                onClick={() => { CreateOrder() }}
                                                                            >Pay Now</button>
                                                                        </div>


                                                                    </div>
                                                                    {/* <div class="container d-md-none d-block">
                                                                        {
                                                                            console.log(payDetials, 'payDetials')
                                                                        }

                                                                        <div class="d-flex">
                                                                            <div className="w-50"><b>Service Name</b></div>
                                                                            <div>: <span>{payDetials.category}</span></div>
                                                                        </div>
                                                                        <hr style={{ border: "1px solid black;" }} />
                                                                        <div class="d-flex">
                                                                            <div className="w-50"><b>Package Name</b></div>
                                                                            <div>: <span>{payDetials.package}</span></div>
                                                                        </div>
                                                                        <hr style={{ border: "1px solid black;" }} />
                                                                        <div class="d-flex">
                                                                            <div className="w-50"><b>Package Amount</b></div>
                                                                            <div>: <span>{payDetials.amount}</span></div>
                                                                        </div>
                                                                        <hr style={{ border: "1px solid black;" }} />
                                                                        {
                                                                            promocode &&
                                                                            <>
                                                                                <div class="d-flex">
                                                                                    <div className="w-50"><b>Discount Amount</b></div>
                                                                                    <div>: <span>{offerData}</span></div>
                                                                                </div>
                                                                                <hr style={{ border: "1px solid black;" }} />

                                                                            </>
                                                                        }
                                                                        {
                                                                            franchiseid &&
                                                                            <>
                                                                                <div class="d-flex">
                                                                                    <div className="w-50"><b>Franchiseid</b></div>
                                                                                    <div>: <span>{franchiseid}</span></div>
                                                                                </div>
                                                                                <hr style={{ border: "1px solid black;" }} />

                                                                            </>
                                                                        }
                                                                        <div class="d-flex justify-content-center">
                                                                            <div><b>You Need to you {offerData ? offerData : payDetials.amount}</b></div>
                                                                            <hr style={{ border: "1px solid black;" }} />
                                                                        </div>


                                                                        <div className="d-flex justify-content-center">
                                                                            <button type="button" class="btn btn-primary"
                                                                                style={{ backgroundColor: "#198754", border: "none", padding: '10px 30px', fontSize: "20px", width: "auto", fontWeight: "600" }}
                                                                                onClick={() => {

                                                                                    CreateOrder()

                                                                                }}
                                                                            >Pay Now</button>
                                                                        </div>


                                                                    </div> */}
                                                                </div>
                                                            </div>


                                                        </div>

                                                    </>
                                            }




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                </>
            }
            {modalActiveUpload && (
                <>
                    <div className="modal-overlay" onClick={closeUploadModal}></div>
                    <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">

                        <section className="vh-100 container_body" style={{ background: "#ffffffc4" }}>
                            <div className="container py-5 h-100">
                                <div className="row px-3 d-flex align-items-center justify-content-center h-100">
                                    <div className="col-md-10 w-auto col-lg-10 col-xl-9 card flex-row mx-auto px-0">
                                        {/* <div className="img-left d-none d-md-flex">
                                            <div>

                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                                                    alt="login form" class="img-fluid" style={{ height: "100%" }} />
                                            </div>
                                        </div> */}

                                        <div className="card-body" style={{ boxShadow: "rgb(148 183 11 / 67%) 2px 2px 20px 14px", borderRadius: "10px" }}>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ fontSize: "30px" }} onClick={closeUploadModal}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>


                                            <h2 class="title text-center mt-4 modal_heading" >
                                                Add  Document
                                            </h2>
                                            <div class="form-box px-3">

                                                <div class="form-input">
                                                    <select class="form-select" aria-label="Default select example" style={{ borderRadius: "30px" }} onChange={(e) => { setSelectDocument(e.target.value) }}>
                                                        <option value='0'>Choose Document</option>

                                                        {
                                                            documentList?.map((item) => {
                                                                return (
                                                                    <>

                                                                        <option value={item.name} selected={selectDocument === item.name ? true : false}>{item.name}</option>

                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div class="mb-3 mt-3">
                                                    <input class="form-control" type="file" id="formFile" style={{ borderRadius: "30px" }} onChange={(e) => { setFile(e.target.files[0]) }} />
                                                </div>

                                                <div class="mb-3 d-flex justify-content-center">

                                                    <button type="button" class="btn btn-primary" style={{ backgroundColor: "#198754", border: "none", padding: '10px 20px', fontSize: "18px", width: "auto", fontWeight: "600" }}
                                                        onClick={() => { AddDocument() }} disabled={disble}>
                                                        {disble ?
                                                            <div class="spinner-border text-warning" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                            :

                                                            <span>Submit</span>
                                                        }

                                                    </button>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                </>
            )}
        </>
    )
}

export default CartSidebar