import React, { useEffect, useState } from 'react'
import { FaWhatsapp } from "react-icons/fa";
import { IoCallOutline } from "react-icons/io5";

import logo1 from '../images/logo3-removebg-preview.png'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import OTPInput from 'react-otp-input';
import CartSidebar from './CartSidebar';
const Header = ({ closeModal, modalActive, setModalActive, cartStatus, setCartStatus }) => {

    const [modalActiveCall, setModalActiveCall] = useState(false);

    const [generateOtp, setGenerateOtp] = useState()
    const [verifyOtp, setVerifyOtp] = useState()
    const [otpButton, setOtpButton] = useState(0)
    const [otpBox, setOtpBox] = useState(false)
    const [name, setName] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [emailCall, setEmailCall] = useState();
    const [details, setDetails] = useState();
    const [notification, setNotification] = useState();
    const [franchiseloginStatus, setFranchiseloginStatus] = useState(false);
    const [franchiseData, setFranchiseData] = useState({
        email: "",
        mobile: "",
        name: "",
        state: "",
        district: "",
        occupation: "",
        password: "",
        confirmpassword: ""
    });
    const [franchiseLogin, setFranchiseLogin] = useState({
        email: "",
        password: "",
    });
    const [franchiseForgot, setFranchiseForgot] = useState({
        email: "",
        password: "",
        confirmpassword: "",
    });

    const openModal = () => {
        setModalActiveCall(true);
    };

    const closeModalCall = () => {
        setModalActiveCall(false);
    };

    const SubmitData = async () => {
        if (name && phoneNumber && emailCall) {
            let url = `${process.env.REACT_APP_PORT}/admin/creatExpertCall`
            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ email: emailCall, name: name, number: phoneNumber, detail: details })
                });
                if (response.status === 200) {
                    toast.success("Details submitted successfully, Our expert will call you shortly")
                    closeModal()
                    setEmailCall()
                    setName()
                    setPhoneNumber()
                    setDetails()
                    setModalActiveCall(false)
                }
            }
            catch (e) {
                toast.error(e)


                console.log(e, 'error')
            }



        } else {
            toast.error('Fill Required details')
        }
    }



    const [active, setActive] = useState("Start Business");
    const [serviceList, setServiceList] = useState()
    const [startBusiness, setStartBusiness] = useState()
    const [license, setLicense] = useState()
    const [ipr, setIpr] = useState()
    const [taxation, setTaxation] = useState()
    const [iso, setIso] = useState()
    const [compliance, setCompliance] = useState()
    const [other, setOther] = useState()
    // const [modalActive, setModalActive] = useState(false);

    const [activeData, setActiveData] = useState('login')
    const [email, setEmail] = useState()
    const [password, setPasswod] = useState()
    const [createName, setCreateName] = useState()
    const [createAddress, setCreateAddress] = useState()
    const [createEmail, setCreateEmail] = useState()
    const [createFranchiseId, setCreateFranchiseId] = useState()
    const [createPassword, setCreatePassword] = useState()
    const [createNumber, setCreateNumber] = useState()
    const [createConPassword, setCreateConPassword] = useState()
    const [login, setLogin] = useState()
    const [loginStatus, setLoginStatus] = useState(false)
    const [alldata, setAllData] = useState()
    const [emailError, setEmailError] = useState('');
    const [numberError, setNumberError] = useState('');

    const handleNumberChange = (e) => {
        const onlyNumbers = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        setCreateNumber(onlyNumbers);

        if (onlyNumbers.length !== 10) {
            setNumberError('Phone number must be exactly 10 digits');
        } else {
            setNumberError('');
        }
    };
    // const openModal = () => {
    //     setModalActive(true);
    // };
    useEffect(() => {
        setLogin(localStorage.getItem('email'))
    }, [loginStatus])
    // const closeModal = () => {
    //     setModalActive(false);
    // };

    const validateEmail = (email) => {
        // Regex for validating email format
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmailChange = (e) => {
        const email = e.target.value;
        setCreateEmail(email);

        if (!validateEmail(email)) {
            setEmailError('Invalid email format');
        } else {
            setEmailError('');
        }
    };

    const CreateAccount = async () => {
        if (verifyOtp === generateOtp) {
            toast.success("OTP verifyed!!!")
            setVerifyOtp();
            setGenerateOtp();
            if (createName && createEmail && createPassword && createConPassword && createAddress) {
                if (createConPassword === createPassword) {
                    let url = `${process.env.REACT_APP_PORT}/admin/createAccount`
                    try {
                        const response = await fetch(url, {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({ address: createAddress, franchisId: createFranchiseId, email: createEmail, name: createName, password: createPassword, number: createNumber })
                        });
                        const data = await response.json();
                        if (response.status === 200) {
                            localStorage.setItem("email", data.email);
                            localStorage.setItem("id", data._id);
                            localStorage.setItem('number', data.number);
                            localStorage.setItem('name', data.name);
                            localStorage.setItem("cart", cartStatus)
                            localStorage.setItem("franchiseid", data.franchisId)
                            localStorage.setItem("logintype", "user")
                            setCreateConPassword()
                            setEmail()
                            setPasswod()
                            setCreateEmail()
                            setCreateName()
                            setCreatePassword()
                            setCreateNumber()
                            setCreateAddress()
                            toast.success("Create Account Sucesfully!")
                            setOtpBox(0)
                            closeModal()

                        } else {
                            const data = await response.json();
                            toast.error(data.error)
                        }
                    } catch (e) {
                        toast.error(e)

                        console.log(e, 'error')
                    }
                } else {
                    toast.error("password and confirmPassword not same!")
                }
            } else {

                toast.error("please fille all filled!")
            }
        } else {
            toast.error("OTP Wrong!!!")
        }


    }
    const CreateFranchiseAccount = async () => {
        console.log(franchiseData, 'franchisedata')
        if (franchiseData.email && franchiseData.name && franchiseData.mobile && franchiseData.password && franchiseData.confirmpassword) {
            if (franchiseData.password === franchiseData.confirmpassword) {
                let url = `${process.env.REACT_APP_PORT}/admin/franchisecreateAccount`
                try {
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ email: franchiseData.email, password: franchiseData.password, number: franchiseData.mobile, name: franchiseData.name, state: franchiseData.state, district: franchiseData.district, occupation: franchiseData.occupation, location: franchiseData.location })
                    });
                    if (response.status === 200) {
                        setFranchiseData({
                            email: "",
                            mobile: "",
                            name: "",
                            state: "",
                            district: "",
                            occupation: "",
                            password: "",
                            confirmpassword: ""
                        })
                        toast.success("Create Account Sucesfully!")
                        setActiveData('franchise')

                    } else {
                        const data = await response.json();
                        toast.error(data.error)
                    }
                } catch (e) {
                    toast.error(e)

                    console.log(e, 'error')
                }
            } else {
                toast.error("password and confirmPassword not same!")
            }
        } else {

            toast.error("please fille all filled!")
        }


    }
    // const navigate = useNavigate();

    const FranchiseLogin = async () => {
        if (franchiseLogin.email && franchiseLogin.password) {

            let url = `${process.env.REACT_APP_PORT}/admin/franchiselogin`
            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ email: franchiseLogin.email, password: franchiseLogin.password })
                });
                const data = await response.json();
                if (response.status === 200) {
                    setFranchiseloginStatus(true)
                    localStorage.setItem("franchiseid", data._id);
                    setFranchiseLogin({
                        email: "",
                        password: "",
                    })
                    toast.success("Login Account Sucesfully!")
                    setModalActive(false)



                } else {
                    toast.error(data.error)
                }
            } catch (e) {
                toast.error(e)

                console.log(e, 'error')
            }
        } else {
            toast.error("please fille all filled!")

        }

    }
    const Login = async () => {
        if (email && password) {

            let url = `${process.env.REACT_APP_PORT}/admin/login`
            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ email: email, password: password })
                });
                const data = await response.json();
                if (response.status === 200) {
                    localStorage.setItem("email", data[0].email);
                    localStorage.setItem("id", data[0]._id);
                    localStorage.setItem('number', data[0].number);
                    localStorage.setItem('name', data[0].name);
                    localStorage.setItem("cart", cartStatus)
                    localStorage.setItem("franchiseid", data[0].franchisId)
                    localStorage.setItem("logintype", "user")
                    setLoginStatus(true)
                    // setLogin(data.email)
                    setCreateConPassword()
                    setEmail()
                    setPasswod()
                    setCreateEmail()
                    setCreateName()
                    setCreatePassword()
                    toast.success("Login Account Sucesfully!")
                    setModalActive(false)



                } else {
                    toast.error(data.error)
                }
            } catch (e) {
                toast.error(e)

                console.log(e, 'error')
            }
        } else {
            toast.error("please fille all filled!")

        }

    }

    const ForgotPassword = async () => {
        if (email && createPassword && createConPassword) {
            if (createConPassword === createPassword) {
                let url = `${process.env.REACT_APP_PORT}/admin/ForgotPassword`
                try {
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ email: email, password: createPassword })
                    });
                    if (response.status === 200) {
                        setEmail()
                        setCreateConPassword()
                        setPasswod()
                        setCreateEmail()
                        setCreateName()
                        setCreatePassword()
                        toast.success("Password Update Sucesfully!")
                        setActiveData('login')

                    } else {
                        const data = await response.json();
                        toast.error(data.error)
                    }
                } catch (e) {
                    toast.error(e)

                    console.log(e, 'error')
                }
            } else {
                toast.error("password and confirmPassword not same!")
            }
        } else {

            toast.error("please fille all filled!")
        }


    }
    const ForgotFranchisePassword = async () => {
        if (franchiseForgot.email && franchiseForgot.password && franchiseForgot.confirmpassword) {
            if (franchiseForgot.password === franchiseForgot.confirmpassword) {
                let url = `${process.env.REACT_APP_PORT}/admin/ForgotfranchisePassword`
                try {
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ email: franchiseForgot.email, password: franchiseForgot.password })
                    });
                    if (response.status === 200) {
                        setFranchiseForgot({
                            email: "",
                            password: "",
                            confirmpassword: "",
                        })
                        toast.success("Password Update Sucesfully!")
                        setActiveData('franchise')

                    } else {
                        const data = await response.json();
                        toast.error(data.error)
                    }
                } catch (e) {
                    toast.error(e)

                    console.log(e, 'error')
                }
            } else {
                toast.error("password and confirmPassword not same!")
            }
        } else {

            toast.error("please fille all filled!")
        }


    }



    const getList = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PORT}/admin/service/list`);
            const data = await response.json();
            if (response.status === 200) {
                setServiceList(data)
            }

            const response2 = await fetch(`${process.env.REACT_APP_PORT}/admin/category/alldata`);
            const data3 = await response2.json();
            if (response2.status === 200) {
                setAllData(data3)
            }
            const responseData = await fetch(`${process.env.REACT_APP_PORT}/admin/category/alldata`);
            const data2 = await responseData.json();
            if (responseData.status === 200) {
                data2?.map((data, index) => {
                    if (data.name === "Start Business") {
                        setStartBusiness(data.subcategories)
                    }
                    if (data.name === "License/Registration") {
                        setLicense(data.subcategories)
                    }
                    if (data.name === "IPR") {
                        setIpr(data.subcategories)
                    }
                    if (data.name === "Taxation") {
                        setTaxation(data.subcategories)
                    }
                    if (data.name === "Compliance") {
                        setCompliance(data.subcategories)
                    }
                    if (data.name === "ISO/ISI") {
                        setIso(data.subcategories)
                    }
                    if (data.name === "Other Services") {
                        setOther(data.subcategories)
                    }

                })

            }
        } catch (e) {
            console.log(e, 'error')
        }

    }
    const getNotification = async () => {
        try {
            let url = `${process.env.REACT_APP_PORT}/admin/order/notifications`
            const Notificationresponse = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ dataid: localStorage.getItem('id') })
            });
            const dataNotification = await Notificationresponse.json();
            if (Notificationresponse.status === 200) {
                const filteredNotifications = dataNotification.filter(data => data.userId === localStorage.getItem('id'));
                // Save filtered notifications to state
                setNotification(filteredNotifications);
            }

        } catch (e) {

            console.log(e, 'error')
        }
    }



    useEffect(() => {
        getList()
        getNotification()
        setFranchiseloginStatus(localStorage.getItem('franchiseid') ? true : false)
    }, [])
    useEffect(() => {
        getNotification()
    }, [loginStatus])


    const Deleteonotifications = async (dataid) => {

        let url = `${process.env.REACT_APP_PORT}/admin/order/deletenotifications`
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ dataid: dataid })
            });
            if (response.status === 200) {
                toast.success("Delete Data Sucesfully!")
                getNotification();
                // getById(selectServicedata._id)
            }
        } catch (e) {
            console.log(e, 'error')
        }
    }

    useEffect(() => {
        if (localStorage.getItem('franchiseid')) {
            setFranchiseloginStatus(true)
        }
    }, [localStorage.getItem('franchiseid')])


    const handleOtpCreate = async () => {
        if (createNumber && createEmail && !emailError && !numberError) {

            console.log('send otp')
            const newOtp = Math.floor(100000 + Math.random() * 900000).toString();
            setGenerateOtp(newOtp)
            const apiKey = '3666D24A2D1B3D'; // Your API key
            const contacts = createNumber; // Phone number entered by the user
            const senderId = 'TXTSMS';
            const templateId = '1234567890'; // Your template ID
            const otp = newOtp;
            const msg = encodeURIComponent(`Your Login Otp is ${otp}`);
            // const apiUrl = `https://msg.pwasms.com/app/smsapi/index.php?key=${apiKey}&campaign=0&routeid=9&type=text&contacts=${contacts}&senderid=${senderId}&msg=${msg}&template_id=${templateId}`;
            const apiUrl = `https://msg.pwasms.com/app/smsapi/index.php?key=3666D24A2D1B3D&campaign=0&routeid=9&type=text&contacts=${contacts}&%20senderid=SPTSMS&msg=${msg}&template_id=1707166619134631839`;

            try {

                setOtpBox(true)
                const response = await fetch(`https://msg.pwasms.com/app/smsapi/index.php?key=3666D24A2D1B3D&campaign=0&routeid=9&type=text&contacts=${contacts}&%20senderid=SPTSMS&msg=Your%20otp%20is%20${otp}%20SELECTIAL&template_id=1707166619134631839`);
                //   const response = await axios.get(apiUrl);

            } catch (error) {
                console.log('Error sending OTP: ' + error.message);
            }
            if (createEmail) {
                let url = `${process.env.REACT_APP_PORT}/admin/emailOtp`
                try {
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ email: createEmail, otp: otp, })
                    });
                    if (response.status === 200) {
                        toast.success("OTP sent on mobile and email, please submit to verify!!")
                        setOtpButton(1)
                    } else {
                        toast.error("Failed to send otp")
                    }
                } catch (e) {
                    toast.error(e)

                    console.log(e, 'error')
                }


            }
            setOtpButton(1)

        } else {
            toast.error("enter mobile Number and Email !!")
        }

    }
    const handleOtp = async () => {
        if (createNumber && !numberError) {
            toast.success("Otp sent successfully!!")
            console.log('send otp')
            const newOtp = Math.floor(100000 + Math.random() * 900000).toString();
            setGenerateOtp(newOtp)
            const apiKey = '3666D24A2D1B3D'; // Your API key
            const contacts = createNumber; // Phone number entered by the user
            const senderId = 'TXTSMS';
            const templateId = '1234567890'; // Your template ID
            const otp = newOtp;
            const msg = encodeURIComponent(`Your Login Otp is ${otp}`);
            // const apiUrl = `https://msg.pwasms.com/app/smsapi/index.php?key=${apiKey}&campaign=0&routeid=9&type=text&contacts=${contacts}&senderid=${senderId}&msg=${msg}&template_id=${templateId}`;
            const apiUrl = `https://msg.pwasms.com/app/smsapi/index.php?key=3666D24A2D1B3D&campaign=0&routeid=9&type=text&contacts=${contacts}&%20senderid=SPTSMS&msg=${msg}&template_id=1707166619134631839`;

            try {

                setOtpBox(true)
                const response = await fetch(`https://msg.pwasms.com/app/smsapi/index.php?key=3666D24A2D1B3D&campaign=0&routeid=9&type=text&contacts=${contacts}&%20senderid=SPTSMS&msg=Your%20otp%20is%20${otp}%20SELECTIAL&template_id=1707166619134631839`);
                //   const response = await axios.get(apiUrl);

            } catch (error) {
                console.log('Error sending OTP: ' + error.message);
            }

        } else {
            toast.error("enter mobile Number and Email !!")
        }

    }

    const handleverifyOtp = async () => {
        if (verifyOtp === generateOtp) {
            // toast.success("OTP verifyed!!!")

            let url = `${process.env.REACT_APP_PORT}/admin/otplogin`
            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ number: createNumber })
                });
                const data = await response.json();
                if (response.status === 200) {
                    // localStorage.setItem("email", data.email);
                    // localStorage.setItem("id", data._id);
                    // data.name && localStorage.setItem('number', data.number);
                    localStorage.setItem('name', data.name);
                    localStorage.setItem("email", data.email);
                    localStorage.setItem("id", data._id);
                    localStorage.setItem('number', data.number);
                    // localStorage.setItem('name', data.name);
                    localStorage.setItem("cart", cartStatus)
                    localStorage.setItem("franchiseid", data.franchisId)
                    localStorage.setItem("logintype", "user")
                    setLoginStatus(true)
                    // setLogin(data.email)
                    setCreateConPassword()
                    setEmail()
                    setPasswod()
                    setCreateEmail()
                    setCreateName()
                    setCreatePassword()
                    setCreateNumber()
                    toast.success("Login Account Sucesfully!")
                    setModalActive(false)



                } else {
                    toast.error(data.error)
                }
            } catch (e) {
                toast.error(e)

                console.log(e, 'error')
            }





        } else {
            toast.error("OTP Wrong!!!")
        }

    }

    return (
        <>

            <div>
                <div style={{ background: "#198754", marginTop: "-20px" }}>
                    <div className='container-fluid p-0'>
                        <nav className="navbar navbar-expand-xl navbar-light  " style={{ padding: "9px" }}>
                            <div className="container-fluid textSize ">
                                <button className="navbar-toggler bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent1">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex" style={{ alignItems: "center", marginLeft: "auto" }}>
                                        <li className="nav-item  pe-lg-3 d-lg-block d-none" style={{ width: "230px" }}>
                                            <Link className='nav-link    header_active' to='/documents' ><b>Document Formats</b></Link>
                                        </li>
                                        <li className="nav-item  pe-lg-3 d-lg-none d-block" >
                                            <Link className='nav-link    header_active' to='/documents' ><b>Document Formats</b></Link>
                                        </li>
                                        <span className='d-xl-block d-none' style={{ color: "white" }}>|</span>
                                        <li className="nav-item ps-4 pe-3 ">
                                            {/* {
                                                franchiseloginStatus ? */}
                                            <Link to="/franchiseData" state={'Franchise'} className='nav-link    header_active' ><b>Franchise</b></Link>
                                            {/* :
                                                    <Link onClick={() => { setActiveData('franchise'); setModalActive(true) }} className='nav-link    header_active' ><b>Franchise</b></Link>
                                            } */}
                                        </li>
                                        <span className='d-xl-block d-none' style={{ color: "white" }}>|</span>
                                        <li className="nav-item ps-4 pe-3 " style={{ width: "200px" }}>
                                            <Link className='nav-link    header_active' to='/blog'  ><b>Knowledge Hub</b></Link>
                                        </li>
                                        <span className='d-xl-block d-none' style={{ color: "white" }}>|</span>
                                        <li className="nav-item ps-4 pe-3 " style={{ width: "266px" }}>
                                            <a className='nav-link    header_active' href='https://wa.link/6bjptw' target='blank'><b> <span style={{ display: "flex", alignItems: "center" }} className='cursor_p mr_20'><IoCallOutline style={{ marginRight: "3px" }} /> <FaWhatsapp style={{ marginRight: "3px" }} /><span style={{ display: 'flex', flexDirection: "column" }}><span>+91-8272828300</span></span></span>
                                            </b></a>
                                        </li>
                                        <span className='d-xl-block d-none' style={{ color: "white" }}>|</span>
                                        <li className="nav-item ps-4 pe-3 " style={{ width: "143px" }} >
                                            <a className='nav-link    header_active' onClick={() => { openModal() }} style={{ cursor: "pointer" }}><b>Call Back</b></a>
                                        </li>
                                        {
                                            login &&
                                            <>
                                                <span className='d-xl-block d-none' style={{ color: "white" }}>|</span>
                                                <li className="nav-item ps-4 pe-3" >
                                                    <Link data-bs-toggle="dropdown" className='nav-link    header_active' style={{ cursor: "pointer" }}><b>     <i className="bi bi-bell"></i> <span className="badge bg-primary badge-number" style={{ position: 'absolute', borderRadius: "13px", fontSize: "8px", marginLeft: "-7px", borderRadius: "15px" }}>{notification?.length}</span></b></Link>
                                                    <ul className="header_drop_list  dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications " >
                                                        <li className="dropdown-header">
                                                            You have {notification?.length} new notifications
                                                            {
                                                                notification?.length > 0 &&
                                                                <Link to="/Profile" state={'notification'}><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></Link>
                                                            }
                                                        </li>

                                                        {
                                                            notification?.map((data, index) => {
                                                                return (
                                                                    <>
                                                                        <li>
                                                                            <hr className="dropdown-divider" />
                                                                        </li>

                                                                        <li className="notification-item">
                                                                            <i className="bi bi-x-circle text-danger" style={{ cursor: "pointer" }} onClick={() => { Deleteonotifications(data._id) }}></i>
                                                                            <div>
                                                                                <h6>{data.title}</h6>

                                                                            </div>
                                                                        </li>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </li>

                                            </>
                                        }

                                        <span className='d-xl-block d-none' style={{ color: "white" }}>|</span>
                                        <li className="nav-item ps-4 pe-3 d-flex">
                                            {
                                                localStorage.getItem('logintype') ?
                                                    <>

                                                        {
                                                            localStorage.getItem('logintype') === "user" ?
                                                                <Link to='/Profile' className='header_active' style={{ color: "white", textDecoration: "none" }}>
                                                                    <b>Profile</b>

                                                                </Link>
                                                                :
                                                                <Link to='/franchiseprofile' className='header_active' style={{ color: "white", textDecoration: "none" }}>
                                                                    <b>Profile</b>

                                                                </Link>


                                                        }

                                                    </>

                                                    :
                                                    <>


                                                        <Link onClick={() => { setActiveData('login'); setModalActive(true) }} className='text-black' > <span className='cursor_p mr_20'><button type="button" class="btn btn-warning btn-sm" style={{ width: '82px' }}>Log-in</button></span></Link>


                                                        <Link onClick={() => { setActiveData('create'); setModalActive(true) }} className='text-black' > <span className='cursor_p mr_20'><button type="button" class="btn btn-warning btn-sm" style={{ width: '82px' }}>Sign-Up</button></span></Link>

                                                    </>

                                            }
                                        </li>
                                        {
                                            localStorage.getItem('logintype') &&
                                            <>

                                                <span className='d-xl-block d-none' style={{ color: "white" }}>|</span>
                                                <li className="nav-item ps-4 pe-3 d-flex">
                                                    <a className='nav-link    header_active' onClick={() => { setCartStatus(!cartStatus); localStorage.setItem("cart", !cartStatus) }} style={{ cursor: "pointer" }}><b><i class="bi bi-cart-fill"></i></b></a>

                                                </li>
                                            </>
                                        }




                                        {/* <li className="nav-item dropdown">

                                            <a className="nav-link nav-icon" href="/" data-bs-toggle="dropdown">
                                                <i className="bi bi-bell"></i>
                                                <span className="badge bg-primary badge-number">4</span>
                                            </a>

                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                                                <li className="dropdown-header">
                                                    You have 4 new notifications
                                                    <a href="/"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                                                </li>
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>

                                                <li className="notification-item">
                                                    <i className="bi bi-exclamation-circle text-warning"></i>
                                                    <div>
                                                        <h4>Lorem Ipsum</h4>
                                                        <p>Quae Dolores larum veritas distend</p>
                                                        <p>30 min. ago</p>
                                                    </div>
                                                </li>

                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>

                                                <li className="notification-item">
                                                    <i className="bi bi-x-circle text-danger"></i>
                                                    <div>
                                                        <h4>Attune serum nescient</h4>
                                                        <p>Quae dolores arum veritable distent</p>
                                                        <p>1 hr. ago</p>
                                                    </div>
                                                </li>

                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>

                                                <li className="notification-item">
                                                    <i className="bi bi-check-circle text-success"></i>
                                                    <div>
                                                        <h4>Sit serum fugs</h4>
                                                        <p>Quae dolores larum veritable distent</p>
                                                        <p>2 hrs. ago</p>
                                                    </div>
                                                </li>

                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>

                                                <li className="notification-item">
                                                    <i className="bi bi-info-circle text-primary"></i>
                                                    <div>
                                                        <h4>Dicta reprehended</h4>
                                                        <p>Quae dolores larum veritable distend</p>
                                                        <p>4 hrs. ago</p>
                                                    </div>
                                                </li>

                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li className="dropdown-footer">
                                                    <a href="/">Show all notifications</a>
                                                </li>

                                            </ul>

                                        </li> */}




                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                    {/* <div className="container text-white">
                        <div className='textSize py-2 d-md-flex  justify-content-center' style={{alignItems:"center"}}>

                            <span className='cursor_p mr_20 '> <Link to="/documents" style={{ color: "white", textDecoration: "none" }}>Document Formats</Link> </span>


                            <span className='mr_20'>|</span>

                            <span className='cursor_p mr_20'> Franchise </span>
                            <span className='mr_20'>|</span>

                            <span className='cursor_p mr_20'> Knowledge Hub</span>

                            <span className='mr_20'>|</span>

                            <span style={{ display: "flex", alignItems: "center" }} className='cursor_p mr_20'><IoCallOutline style={{marginRight:"px"}}/> <FaWhatsapp /><span style={{display:'flex' , flexDirection:"column"}}><span>+91-8077425868</span><span>+91-9716970019</span></span></span>

                            <span className='mr_20'>|</span>

                            <span className='cursor_p mr_20'>Call Back</span>
                            <span className='mr_20'>|</span>
                            {
                                login ?

                                    <Link to='/Profile' style={{ color: "white", textDecoration: "none" }}>
                                        <span className='cursor_p mr_20'>Profile</span>

                                    </Link>
                                    :
                                    <>


                                        <Link onClick={() => { setActiveData('login'); setModalActive(true) }} className='text-black' > <span className='cursor_p mr_20'><button type="button" class="btn btn-warning btn-sm" style={{ width: '82px' }}>Log-in</button></span></Link>


                                        <Link onClick={() => { setActiveData('create'); setModalActive(true) }} className='text-black' > <span className='cursor_p mr_20'><button type="button" class="btn btn-warning btn-sm" style={{ width: '82px' }}>Sign-Up</button></span></Link>

                                    </>

                            }


                        </div>
                    </div> */}
                </div>
                <div className='bg-light'>
                    <div className='container'>
                        <nav className="navbar navbar-expand-lg navbar-light bg-light ">
                            <div className="container-fluid textSize">
                                <Link className="navbar-brand" to="/" style={{ width: "70px" }}><img src={logo1} className='img-fluid' /></Link>
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item ps-4 pe-3 dropdown">
                                            <a className={`nav-link  ${active === "Start Business" && "header_active"} `} onClick={() => { setActive("Start Business") }}><b>Start Business</b></a>

                                            {startBusiness?.length !== 0
                                                &&
                                                <div class="dropdown-content" style={{ padding: "10px", display: active === "Start Business" && "flex" }} >

                                                    {
                                                        alldata?.map((maindata, index) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        maindata.name === "Start Business" &&
                                                                        maindata.subcategories?.map((inner) => {
                                                                            var status;
                                                                            return (
                                                                                <>
                                                                                    <div className="dropdownList" style={{ flex: '1 0 25%' }}>
                                                                                        <div>

                                                                                            {inner.name}
                                                                                        </div>
                                                                                        <div>
                                                                                            {
                                                                                                inner.innerCategories?.map((innername) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            {
                                                                                                                serviceList.map((servicename) => {
                                                                                                                    {/* { console.log(servicename, 'servicename') } */ }
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                servicename.subCategoryName === inner.name && servicename.innerCategoryName === innername &&
                                                                                                                                <Link
                                                                                                                                    to="/servicePage" state={servicename._id}

                                                                                                                                >{innername}</Link>
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>


                                                                                    </div>

                                                                                </>
                                                                            )

                                                                        })

                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }

                                                    {/* {
                                                        startBusiness?.map((mainData, index) => {

                                                            return (
                                                                <>
                                                                    {
                                                                        mainData.innerCategories.length !== 0 ?
                                                                            <>
                                                                                {
                                                                                    serviceList?.map((data, index) => {
                                                                                        console.log(data, mainData.innerCategories)

                                                                                        return (
                                                                                            <>
                                                                                                {
                                                                                                    mainData.name === data.subCategoryName &&

                                                                                                    <div className="dropdownList">{mainData.name}</div>
                                                                                                }
                                                                                                {
                                                                                                    data.subCategoryName === mainData.name &&

                                                                                                    <Link to="/servicePage" state={data._id}

                                                                                                    >{data.innerCategoryName}</Link>
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                            : <>
                                                                                NO Data
                                                                            </>
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    } */}

                                                </div>
                                            }
                                        </li>
                                        <li className="nav-item ps-4 pe-3 dropdown">
                                            <a className={`nav-link  ${active === "Licence | Registration" && "header_active"} `} onClick={() => { setActive("Licence | Registration") }}><b>Licence | Registration</b></a>
                                            {license?.length !== 0
                                                &&

                                                <div class="dropdown-content" style={{ padding: "10px", display: active === "Licence | Registration" && "flex" }} >
                                                    {
                                                        alldata?.map((maindata, index) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        maindata.name === "License/Registration" &&
                                                                        maindata.subcategories?.map((inner) => {
                                                                            return (
                                                                                <>
                                                                                    <div className="dropdownList" style={{ flex: '1 0 25%' }}>
                                                                                        <div>

                                                                                            {inner.name}
                                                                                        </div>
                                                                                        <div>
                                                                                            {
                                                                                                inner.innerCategories?.map((innername) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            {
                                                                                                                serviceList.map((servicename) => {
                                                                                                                    {/* { console.log(servicename, 'servicename') } */ }
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                servicename.subCategoryName === inner.name && servicename.innerCategoryName === innername &&
                                                                                                                                <Link
                                                                                                                                    to="/servicePage" state={servicename._id}

                                                                                                                                >{innername}</Link>
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>


                                                                                    </div>

                                                                                </>
                                                                            )

                                                                        })

                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    {/* {
                                                        license?.map((mainData, index) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        mainData.innerCategories.length !== 0 ?
                                                                            <>
                                                                                {
                                                                                    serviceList?.map((data, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                {
                                                                                                    mainData.name === data.subCategoryName ?

                                                                                                        <div className="dropdownList">{mainData.name}</div>
                                                                                                        :
                                                                                                        ""
                                                                                                }
                                                                                                {
                                                                                                    data.subCategoryName === mainData.name &&

                                                                                                    <Link to="/servicePage" state={data._id}

                                                                                                    >{data.innerCategoryName}</Link>
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                            : <>
                                                                                NO Data
                                                                            </>
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    } */}

                                                </div>
                                            }
                                        </li>
                                        <li className="nav-item ps-4 pe-3 dropdown">
                                            <a className={`nav-link  ${active === "IPR" && "header_active"} `} onClick={() => { setActive("IPR") }}><b>IPR</b></a>


                                            {ipr?.length !== 0
                                                &&
                                                <div class="dropdown-content" style={{ padding: "10px", display: active === "IPR" && "flex" }} >
                                                    {
                                                        alldata?.map((maindata, index) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        maindata.name === "IPR" &&
                                                                        maindata.subcategories?.map((inner) => {
                                                                            return (
                                                                                <>
                                                                                    <div className="dropdownList" style={{ flex: '1 0 25%' }}>
                                                                                        <div>

                                                                                            {inner.name}
                                                                                        </div>
                                                                                        <div>
                                                                                            {
                                                                                                inner.innerCategories?.map((innername) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            {
                                                                                                                serviceList.map((servicename) => {
                                                                                                                    {/* { console.log(servicename, 'servicename') } */ }
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                servicename.subCategoryName === inner.name && servicename.innerCategoryName === innername &&
                                                                                                                                <Link
                                                                                                                                    to="/servicePage" state={servicename._id}

                                                                                                                                >{innername}</Link>
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>


                                                                                    </div>

                                                                                </>
                                                                            )

                                                                        })

                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    {/* {
                                                        ipr?.map((mainData, index) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        mainData.innerCategories.length !== 0 ?
                                                                            <>
                                                                                {
                                                                                    serviceList?.map((data, index) => {
                                                                                        return (
                                                                                            <>           {
                                                                                                mainData.name === data.subCategoryName ?

                                                                                                    <div className="dropdownList">{mainData.name}</div>
                                                                                                    :
                                                                                                    <div className='text-center'>No data</div>
                                                                                            }
                                                                                                {
                                                                                                    data.subCategoryName === mainData.name &&

                                                                                                    <Link to="/servicePage" state={data._id}

                                                                                                    >{data.innerCategoryName}</Link>
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                            : <>
                                                                                NO Data
                                                                            </>
                                                                    }

                                                                </>
                                                            )
                                                        })
                                                    } */}

                                                </div>
                                            }

                                        </li>
                                        <li className="nav-item ps-4 pe-3 dropdown">
                                            <a className={`nav-link  ${active === "Taxation" && "header_active"} `} onClick={() => { setActive("Taxation") }}><b>Taxation</b></a>

                                            {taxation?.length !== 0
                                                &&
                                                <div class="dropdown-content" style={{ padding: "10px", display: active === "Taxation" && "flex" }} >
                                                    {
                                                        alldata?.map((maindata, index) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        maindata.name === "Taxation" &&
                                                                        maindata.subcategories?.map((inner) => {
                                                                            return (
                                                                                <>
                                                                                    <div className="dropdownList" style={{ flex: '1 0 25%' }}>
                                                                                        <div>

                                                                                            {inner.name}
                                                                                        </div>
                                                                                        <div>
                                                                                            {
                                                                                                inner.innerCategories?.map((innername) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            {
                                                                                                                serviceList.map((servicename) => {
                                                                                                                    {/* { console.log(servicename, 'servicename') } */ }
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                servicename.subCategoryName === inner.name && servicename.innerCategoryName === innername &&
                                                                                                                                <Link
                                                                                                                                    to="/servicePage" state={servicename._id}

                                                                                                                                >{innername}</Link>
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>


                                                                                    </div>

                                                                                </>
                                                                            )

                                                                        })

                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    {/* {
                                                        taxation?.map((mainData, index) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        mainData.innerCategories.length !== 0 ?
                                                                            <>
                                                                                {
                                                                                    serviceList?.map((data, index) => {
                                                                                        return (
                                                                                            <>           {
                                                                                                mainData.name === data.subCategoryName ?

                                                                                                    <div className="dropdownList">{mainData.name}</div>
                                                                                                    :
                                                                                                    <div className='text-center'>No data</div>
                                                                                            }
                                                                                                {
                                                                                                    data.subCategoryName === mainData.name &&

                                                                                                    <Link to="/servicePage" state={data._id}

                                                                                                    >{data.innerCategoryName}</Link>
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                            : <>
                                                                                NO Data
                                                                            </>
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    } */}

                                                </div>
                                            }
                                        </li>
                                        <li className="nav-item ps-4 pe-3 dropdown">
                                            <a className={`nav-link  ${active === "ISO | ISI" && "header_active"} `} onClick={() => { setActive("ISO | ISI") }}><b>ISO | ISI</b></a>

                                            {iso?.length !== 0
                                                &&
                                                <div class="dropdown-content" style={{ padding: "10px", display: active === "ISO | ISI" && "flex" }} >
                                                    {
                                                        alldata?.map((maindata, index) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        maindata.name === "ISO/ISI" &&
                                                                        maindata.subcategories?.map((inner) => {
                                                                            return (
                                                                                <>
                                                                                    <div className="dropdownList" style={{ flex: '1 0 25%' }}>
                                                                                        <div>

                                                                                            {inner.name}
                                                                                        </div>
                                                                                        <div>
                                                                                            {
                                                                                                inner.innerCategories?.map((innername) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            {
                                                                                                                serviceList.map((servicename) => {
                                                                                                                    {/* { console.log(servicename, 'servicename') } */ }
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                servicename.subCategoryName === inner.name && servicename.innerCategoryName === innername &&
                                                                                                                                <Link
                                                                                                                                    to="/servicePage" state={servicename._id}

                                                                                                                                >{innername}</Link>
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>


                                                                                    </div>

                                                                                </>
                                                                            )

                                                                        })

                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    {/* {
                                                        iso?.map((mainData, index) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        mainData.innerCategories.length !== 0 ?
                                                                            <>
                                                                                {
                                                                                    serviceList?.map((data, index) => {
                                                                                        return (
                                                                                            <>           {
                                                                                                mainData.name === data.subCategoryName ?

                                                                                                    <div className="dropdownList">{mainData.name}</div>
                                                                                                    :
                                                                                                    <div className='text-center'>No data</div>
                                                                                            }
                                                                                                {
                                                                                                    data.subCategoryName === mainData.name &&

                                                                                                    <Link to="/servicePage" state={data._id}

                                                                                                    >{data.innerCategoryName}</Link>
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                            : <>
                                                                                NO Data
                                                                            </>
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    } */}

                                                </div>
                                            }
                                        </li>
                                        <li className="nav-item ps-4 pe-3 dropdown">
                                            <a className={`nav-link  ${active === "Compliance" && "header_active"} `} onClick={() => { setActive("Compliance") }}><b>Compliance</b></a>

                                            {compliance?.length !== 0
                                                &&
                                                <div class="dropdown-content" style={{ padding: "10px", display: active === "Compliance" && "flex" }} >
                                                    {
                                                        alldata?.map((maindata, index) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        maindata.name === "Compliance" &&
                                                                        maindata.subcategories?.map((inner) => {
                                                                            return (
                                                                                <>
                                                                                    <div className="dropdownList" style={{ flex: '1 0 25%' }}>
                                                                                        <div>

                                                                                            {inner.name}
                                                                                        </div>
                                                                                        <div>
                                                                                            {
                                                                                                inner.innerCategories?.map((innername) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            {
                                                                                                                serviceList.map((servicename) => {
                                                                                                                    {/* { console.log(servicename, 'servicename') } */ }
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                servicename.subCategoryName === inner.name && servicename.innerCategoryName === innername &&
                                                                                                                                <Link
                                                                                                                                    to="/servicePage" state={servicename._id}

                                                                                                                                >{innername}</Link>
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>


                                                                                    </div>

                                                                                </>
                                                                            )

                                                                        })

                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    {/* {
                                                        compliance?.map((mainData, index) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        mainData.innerCategories.length !== 0 ?
                                                                            <>
                                                                                {
                                                                                    serviceList?.map((data, index) => {
                                                                                        return (
                                                                                            <>           {
                                                                                                mainData.name === data.subCategoryName ?

                                                                                                    <div className="dropdownList">{mainData.name}</div>
                                                                                                    :
                                                                                                    <div className='text-center'>No data</div>
                                                                                            }
                                                                                                {
                                                                                                    data.subCategoryName === mainData.name &&

                                                                                                    <Link to="/servicePage" state={data._id}

                                                                                                    >{data.innerCategoryName}</Link>
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                            : <>
                                                                                NO Data
                                                                            </>
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    } */}

                                                </div>
                                            }
                                        </li>
                                        <li className="nav-item ps-4 pe-3 dropdown">
                                            <a className={`nav-link  ${active === "Other Services" && "header_active"} `} onClick={() => { setActive("Other Services") }}><b>Other Services</b></a>
                                            {other?.length !== 0
                                                &&
                                                <div class="dropdown-content" style={{ padding: "10px", display: active === "Other Services" && "flex" }} >
                                                    {
                                                        alldata?.map((maindata, index) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        maindata.name === "Other Services" &&
                                                                        maindata.subcategories?.map((inner) => {
                                                                            return (
                                                                                <>
                                                                                    <div className="dropdownList" style={{ flex: '1 0 25%' }}>
                                                                                        <div>

                                                                                            {inner.name}
                                                                                        </div>
                                                                                        <div>
                                                                                            {
                                                                                                inner.innerCategories?.map((innername) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            {
                                                                                                                serviceList.map((servicename) => {
                                                                                                                    {/* { console.log(servicename, 'servicename') } */ }
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                servicename.subCategoryName === inner.name && servicename.innerCategoryName === innername &&
                                                                                                                                <Link
                                                                                                                                    to="/servicePage" state={servicename._id}

                                                                                                                                >{innername}</Link>
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>


                                                                                    </div>

                                                                                </>
                                                                            )

                                                                        })

                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    {/* {
                                                        other?.map((mainData, index) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        mainData.innerCategories.length !== 0 ?
                                                                            <>
                                                                                {
                                                                                    serviceList?.map((data, index) => {
                                                                                        return (
                                                                                            <>           {
                                                                                                mainData.name === data.subCategoryName ?

                                                                                                    <div className="dropdownList">{mainData.name}</div>
                                                                                                    :
                                                                                                    <div className='text-center'>No data</div>
                                                                                            }
                                                                                                {
                                                                                                    data.subCategoryName === mainData.name &&

                                                                                                    <Link to="/servicePage" state={data._id}

                                                                                                    >{data.innerCategoryName}</Link>
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                            : <>
                                                                                NO Data
                                                                            </>
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    } */}

                                                </div>
                                            }

                                        </li>





                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>


            {modalActiveCall && (
                <>
                    <div className="modal-overlay" onClick={closeModalCall}></div>
                    <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">

                        <section className="vh-100 container_body">
                            <div className="container py-5 h-100">
                                <div className="row px-3 d-flex align-items-center justify-content-center h-100">
                                    <div className="col-md-10 col-lg-10 col-xl-9 card flex-row mx-auto px-0 " style={{ width: "90% !important" }}>

                                        <div className="card-body">
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ fontSize: "30px" }} onClick={closeModalCall}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>


                                            <h2 class="title text-center mt-4 modal_heading" >
                                                Book Expert Call
                                            </h2>
                                            <div class="form-box w-100 d-flex flex-column justify-content-center align-items-center px-3">
                                                <div className='w_50 '>

                                                    <div class="form-input">
                                                        <span><i class="fa-solid fa-user"></i></span>
                                                        <input tabIndex={1} type="text" placeholder="Name" tabindex="10" value={name} onChange={(e) => { setName(e.target.value) }} />
                                                    </div>
                                                    <div class="form-input">
                                                        <span><i class="fa-solid fa-phone-volume"></i></span>
                                                        <input tabIndex={1} type="tel" name="" placeholder="Mobile Number" pattern="[0-9]{3} [0-9]{3} [0-9]{4}" maxlength="12" required value={phoneNumber} onChange={(e) => {
                                                            const onlyNumbers = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                                                            setPhoneNumber(onlyNumbers);
                                                        }} />
                                                    </div>
                                                    <div class="form-input">
                                                        <span><i class="bi bi-envelope-fill"></i></span>
                                                        <input tabIndex={1} type="email" placeholder="Email Address" tabindex="10" value={emailCall} onChange={(e) => { setEmailCall(e.target.value) }} />
                                                    </div>
                                                    <div class="form-input">
                                                        {/* <span><i class="bi bi-envelope-fill"></i></span> */}

                                                        <textarea tabIndex={1} value={details} onChange={(e) => { setDetails(e.target.value) }} placeholder="Details" tabindex="10" style={{ borderRadius: "20px", padding: "10px", width: "100%", fontSize: "18px", minHeight: "90px" }} className='textarea-focus' />
                                                    </div>

                                                    <div class="row mb-3 mt-3">
                                                        <div class="col-12 text-center">
                                                            <a onClick={SubmitData} class="w-auto btn btn-block btn-social btn-facebook modal_button_bg" style={{ padding: "5px 45px" }}>
                                                                Book
                                                            </a>
                                                        </div>


                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                </>
            )}


            {modalActive && (
                <>
                    <div className="modal-overlay" onClick={closeModal}></div>
                    <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">

                        <section className="vh-100 container_body">
                            <div className="container py-5 h-100">
                                <div className="row px-3 d-flex align-items-center justify-content-center h-100 login">
                                    <div className="col-md-10 col-lg-10 col-xl-9 card flex-row mx-auto px-0">
                                        <div className="  d-none d-lg-flex ">
                                            <div className=' d-flex align-items-center justify-content-center'>

                                                <img src={logo1} className='w-75' />
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ fontSize: "30px" }} onClick={closeModal}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            {
                                                activeData === "login" &&
                                                <>

                                                    <h2 class="title text-center mt-4 modal_heading" >
                                                        Login  Account
                                                    </h2>
                                                    <div class="form-box px-3">
                                                        <div class="form-input">
                                                            <span><i class="bi bi-envelope-fill"></i></span>
                                                            <input tabIndex={1} tabindex="10" type="email" placeholder="Email Address"  required value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                                        </div>
                                                        <div class="form-input">
                                                            <span><i class="fa fa-key"></i></span>
                                                            <input tabIndex={1} tabindex="10" type="password" name="" placeholder="Password" required value={password} onChange={(e) => { setPasswod(e.target.value) }} />
                                                        </div>

                                                        <div class="mb-3">
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" id="cb1" name="" />
                                                                <label class="custom-control-label" for="cb1">Remember me</label>
                                                            </div>
                                                        </div>

                                                        <div class="mb-3">
                                                            <button tabIndex={1} tabindex="10" type="submit" class="btn btn-block text-uppercase modal_button_bg" onClick={() => { Login() }}>
                                                                Login
                                                            </button>
                                                        </div>

                                                        <div class="text-right">
                                                            <a class="forget-link" tabIndex={1} tabindex="10" onClick={() => {
                                                                setEmail()
                                                                setPasswod()
                                                                setCreatePassword()
                                                                setCreateConPassword()
                                                                setActiveData('forgot')
                                                            }} style={{ cursor: "pointer" }}>
                                                                Forget Password?
                                                            </a>
                                                        </div>

                                                        <div class="text-center ">
                                                            <h4 class="title text-center mb-2" style={{ color: 'rgb(25, 135, 84)' }}>
                                                                OR
                                                            </h4>
                                                        </div>

                                                        <div class="row mb-3">
                                                            <div class="col-12 text-center">
                                                                <a tabIndex={1} tabindex="10" onClick={() => { setCreateNumber(); setActiveData('OTP') }} class="w-100 btn btn-block btn-social btn-facebook modal_button_bg" style={{padding:'12px'}}>
                                                                    Login with mobile OTP
                                                                </a>
                                                            </div>


                                                        </div>

                                                        <hr class="my-4" />

                                                        <div class="text-center mb-2">
                                                            Don't have an account?
                                                            <a class="register-link" tabIndex={1} tabindex="10" onClick={() => { setActiveData('create') }} style={{ cursor: "pointer" }} >
                                                                Register here
                                                            </a>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                activeData === "create" &&
                                                <>

                                                    <h2 class="title text-center mt-4 modal_heading" >
                                                        Create Account
                                                    </h2>
                                                    <div class="form-box px-3">
                                                        <div class="form-input">
                                                            <span><i class="fa-solid fa-user"></i></span>
                                                            <input tabIndex={1} type="text" placeholder="Name" tabindex="10" required value={createName} onChange={(e) => { setCreateName(e.target.value) }} />
                                                        </div>
                                                        <div class="form-input">
                                                            <span><i class="bi bi-envelope-fill"></i></span>
                                                            <input tabIndex={1} type="email" placeholder="Email Address" tabindex="10" required value={createEmail} onChange={(e) => handleEmailChange(e)} />
                                                            {emailError && <span className="error d-flex text-danger" style={{ position: 'absolute', marginTop: "32px", fontWeight: '500' }}><i class="bi bi-exclamation-circle mr-1"></i> {emailError}</span>}
                                                        </div>
                                                        <div class="form-input">
                                                            <span><i class="bi bi-person-circle"></i></span>
                                                            <input tabIndex={1} type="text" placeholder="Franchise id if have referance..." tabindex="10" required value={createFranchiseId} onChange={(e) => { setCreateFranchiseId(e.target.value) }} />
                                                        </div>
                                                        <div class="form-input">
                                                            <span><i class="bi bi-pin-map-fill"></i></span>
                                                            <input tabIndex={1} type="text" placeholder="Address" tabindex="10" required value={createAddress} onChange={(e) => { setCreateAddress(e.target.value) }} />
                                                        </div>
                                                        <div class="form-input mt-2">
                                                            <span><i class="fa-solid fa-phone-volume"></i></span>
                                                            <input tabIndex={1} type="tel"  placeholder="Mobile Number" pattern="[0-9]{3} [0-9]{3} [0-9]{4}" maxlength="12" tabindex="10" required
                                                                value={createNumber} onChange={(e) => {
                                                                    handleNumberChange(e)
                                                                }}

                                                            />
                                                            {numberError && <span className="error d-flex text-danger" style={{ position: 'absolute', marginTop: "34px", fontWeight: '500' }}><i class="bi bi-exclamation-circle mr-1"></i> {numberError}</span>}
                                                        </div>
                                                        <div class="form-input mt-2 ">
                                                            <span><i class="fa fa-key"></i></span>
                                                            <input tabIndex={1} type="password" tabindex="10"  placeholder="Password" required value={createPassword} onChange={(e) => { setCreatePassword(e.target.value) }} />
                                                        </div>
                                                        <div class="form-input">
                                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                                            <input tabIndex={1} type="password" tabindex="10"  placeholder="Confirm Password" required value={createConPassword} onChange={(e) => { setCreateConPassword(e.target.value) }} />
                                                        </div>
                                                        {
                                                            otpButton === 1 &&
                                                            <>
                                                                <div class="form-input">
                                                                    <span><i class="fa fa-key"></i></span>
                                                                    <input tabIndex={1} tabindex="10" type="tel" name="" placeholder="Verify OTP" pattern="[0-9]{3} [0-9]{3} [0-9]{4}" maxlength="6" required value={verifyOtp} onChange={(e) => {
                                                                        const onlyNumbers = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                                                                        setVerifyOtp(onlyNumbers);
                                                                    }} />
                                                                </div>

                                                            </>
                                                        }



                                                        <div class="mb-3">
                                                            {
                                                                otpButton === 0 &&

                                                                <button type="submit"  tabIndex={1} tabindex="10" class="btn btn-block text-uppercase modal_button_bg" onClick={() => { handleOtpCreate() }}>
                                                                    Generate OTP
                                                                </button>
                                                            }
                                                            {
                                                                otpButton === 1 &&
                                                                <>

                                                                    <button type="submit" class="btn btn-block text-uppercase modal_button_bg" onClick={() => { CreateAccount() }}>
                                                                        Verify Otp & submit
                                                                    </button>
                                                                    <div>

                                                                        <button type="submit" class="btn btn-block text-uppercase modal_button_bg" onClick={() => { handleOtpCreate() }}>
                                                                            Resend Otp
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            }
                                                            {/* {
                                                            otpButton === 2 &&

                                                            <button type="submit" class="btn btn-block text-uppercase modal_button_bg" onClick={() => { CreateAccount() }}>
                                                                Submit
                                                            </button>
                                                        } */}
                                                        </div>

                                                    </div>
                                                </>
                                            }
                                            {
                                                activeData === "forgot" &&
                                                <>

                                                    <h2 class="title text-center mt-4 modal_heading" >
                                                        Forgot Password
                                                    </h2>
                                                    <div class="form-box px-3">
                                                        <div class="form-input">
                                                            <span><i class="fa-regular fa-envelope"></i></span>
                                                            <input tabIndex={1} type="text" placeholder="Email Address" tabindex="10" required value={email}
                                                                onChange={(e) => { setEmail(e.target.value) }}
                                                            />
                                                        </div>
                                                        <div class="form-input">
                                                            <span><i class="fa fa-key"></i></span>
                                                            <input tabIndex={1} type="password" name="" placeholder=" New Password" required value={createPassword} onChange={(e) => { setCreatePassword(e.target.value) }} />
                                                        </div>
                                                        <div class="form-input">
                                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                                            <input tabIndex={1} type="password" name="" placeholder="Confirm Password" required value={createConPassword} onChange={(e) => { setCreateConPassword(e.target.value) }} />
                                                        </div>



                                                        <div class="mb-3">
                                                            <button type="submit" class="btn btn-block text-uppercase modal_button_bg" onClick={() => { ForgotPassword() }}>
                                                                Submit
                                                            </button>
                                                        </div>


                                                    </div>
                                                </>
                                            }
                                            {
                                                activeData === "OTP" &&
                                                <>

                                                    <h2 class="title text-center mt-4 modal_heading" >
                                                        Login With OTP
                                                    </h2>
                                                    <div class="form-box px-3">
                                                        <div class="form-input ">
                                                            <span><i class="fa-solid fa-phone-volume"></i></span>
                                                            <input tabIndex={1} type="tel" name="" placeholder="Mobile Number" pattern="[0-9]{3} [0-9]{3} [0-9]{4}" maxlength="12" tabindex="10" required
                                                                value={createNumber} onChange={(e) => {
                                                                    handleNumberChange(e)
                                                                }}

                                                            />
                                                            {numberError && <span className="error d-flex text-danger" style={{ position: 'absolute', marginTop: "34px", fontWeight: '500' }}><i class="bi bi-exclamation-circle mr-1"></i> {numberError}</span>}
                                                        </div>

                                                        {
                                                            otpBox &&
                                                            <>
                                                                <div class="form-input">
                                                                    <span><i class="fa fa-key"></i></span>
                                                                    <input tabIndex={1} type="tel" name="" placeholder="Verify OTP" pattern="[0-9]{3} [0-9]{3} [0-9]{4}" maxlength="6" required value={verifyOtp} onChange={(e) => {
                                                                        const onlyNumbers = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                                                                        setVerifyOtp(onlyNumbers);
                                                                    }} />
                                                                </div>

                                                            </>
                                                        }




                                                        <div class="mb-3">
                                                            <button type="submit" class="btn btn-block text-uppercase modal_button_bg" onClick={() => {
                                                                otpBox ? handleverifyOtp() : handleOtp()
                                                            }}>
                                                                {
                                                                    otpBox ? "Verify Otp" : "Send Otp"
                                                                }
                                                            </button>
                                                        </div>


                                                    </div>
                                                </>
                                            }
                                            {
                                                activeData === "franchise" &&
                                                <>

                                                    <h2 class="title text-center mt-4 modal_heading" >
                                                        Franchise  account
                                                    </h2>
                                                    <div class="form-box px-3">
                                                        <div class="form-input">
                                                            <span><i class="bi bi-envelope-fill"></i></span>
                                                            <input tabIndex={1} type="email" placeholder="Email Address" tabindex="10" required value={franchiseLogin.email} onChange={(e) => { setFranchiseLogin({ ...franchiseLogin, email: e.target.value }) }} />
                                                        </div>
                                                        <div class="form-input">
                                                            <span><i class="fa fa-key"></i></span>
                                                            <input tabIndex={1} type="password" name="" placeholder="Password" required value={franchiseLogin.password} onChange={(e) => { setFranchiseLogin({ ...franchiseLogin, password: e.target.value }) }} />
                                                        </div>

                                                        {/* <div class="mb-3">
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" id="cb1" name="" />
                                                                <label class="custom-control-label" for="cb1">Remember me</label>
                                                            </div>
                                                        </div> */}

                                                        <div class="mb-3">
                                                            <button type="submit" class="btn btn-block text-uppercase modal_button_bg" onClick={() => { FranchiseLogin() }}>
                                                                Login
                                                            </button>
                                                        </div>

                                                        <div class="text-right">
                                                            <a class="forget-link" style={{ cursor: "pointer" }} onClick={() => { setActiveData('franchiseforgot') }}>
                                                                Forget Password?
                                                            </a>
                                                        </div>

                                                        {/* <div class="text-center ">
                                                            <h4 class="title text-center mb-2" style={{ color: 'rgb(25, 135, 84)' }}>
                                                                OR
                                                            </h4>
                                                        </div> */}

                                                        {/* <div class="row mb-3">
                                                            <div class="col-12 text-center">
                                                                <a onClick={() => { setCreateNumber(); setActiveData('OTP') }} class="w-auto btn btn-block btn-social btn-facebook modal_button_bg">
                                                                    login with Mobile Otp
                                                                </a>
                                                            </div>


                                                        </div> */}

                                                        <hr class="my-4" />

                                                        <div class="text-center mb-2">
                                                            Don't have an account?
                                                            <a class="register-link" onClick={() => { setActiveData('franchisecreate') }} style={{ cursor: "pointer" }} >
                                                                Register here
                                                            </a>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                activeData === "franchisecreate" &&
                                                <>

                                                    <h2 class="title text-center mt-4 modal_heading" >
                                                        Create Account
                                                    </h2>
                                                    <div class="form-box px-3">

                                                        <div class="form-input">
                                                            <span><i class="bi bi-person-circle"></i></span>
                                                            <input tabIndex={1} style={{ marginBottom: "10px" }} type="email" placeholder="Name" tabindex="10" required value={franchiseData.name} onChange={(e) => { setFranchiseData({ ...franchiseData, name: e.target.value }) }} />
                                                        </div>
                                                        <div class="form-input">
                                                            <span><i class="bi bi-envelope-fill"></i></span>
                                                            <input tabIndex={1} style={{ marginBottom: "10px" }} type="email" placeholder="Email Address" tabindex="10" required value={franchiseData.email} onChange={(e) => { setFranchiseData({ ...franchiseData, email: e.target.value }) }} />
                                                        </div>
                                                        <div class="form-input">
                                                            <span><i class="fa-solid fa-phone-volume"></i></span>
                                                            <input tabIndex={1} style={{ marginBottom: "10px" }} type="tel" name="" placeholder="Mobile Number" pattern="[0-9]{3} [0-9]{3} [0-9]{4}" maxlength="12" tabindex="10" required
                                                                value={franchiseData.mobile} onChange={(e) => {
                                                                    const onlyNumbers = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                                                                    setFranchiseData({ ...franchiseData, mobile: onlyNumbers })
                                                                }}

                                                            />
                                                        </div>
                                                        <div class="form-input">
                                                            <span><i class="bi bi-pin-map-fill"></i></span>
                                                            <input tabIndex={1} style={{ marginBottom: "10px" }} type="text" name="" placeholder="state" required value={franchiseData.state} onChange={(e) => { setFranchiseData({ ...franchiseData, state: e.target.value }) }} />
                                                        </div>
                                                        <div class="form-input">
                                                            <span><i class="bi bi-pin-map-fill"></i></span>
                                                            <input tabIndex={1} style={{ marginBottom: "10px" }} type="text" name="" placeholder="district" required value={franchiseData.district} onChange={(e) => { setFranchiseData({ ...franchiseData, district: e.target.value }) }} />
                                                        </div>
                                                        <div class="form-input">
                                                            {/* <span><i class="bi bi-pin-map-fill"></i></span> */}
                                                            <select tabIndex={1} class="form-select" aria-label="Default select example" style={{ borderRadius: "30px" }} onChange={(e) => { setFranchiseData({ ...franchiseData, occupation: e.target.value }) }}>
                                                                <option value='0'>Choose occupation</option>
                                                                <option value='CA'>CA</option>
                                                                <option value='CS'>CS</option>
                                                                <option value='Advocate'>Advocate</option>
                                                                <option value='Graduate'>Graduate</option>
                                                                <option value='PostGraduate'>Post Graduate</option>
                                                                <option value='Student'>Student</option>
                                                                <option value='other'>other</option>

                                                            </select>
                                                            {/* <input style={{ marginBottom: "10px" }} type="text" name="" placeholder="occupation" required value={franchiseData.district} onChange={(e) => { setFranchiseData({ ...franchiseData, occupation: e.target.value }) }} /> */}
                                                        </div>
                                                        {/* <div class="form-input">
                                                            <span><i class="bi bi-geo-alt-fill"></i></span>
                                                            <input style={{ marginBottom: "10px" }} type="text" name="" placeholder="Location" required value={franchiseData.location} onChange={(e) => { setFranchiseData({ ...franchiseData, location: e.target.value }) }} />
                                                        </div> */}
                                                        <div class="form-input mt-2">
                                                            <span><i class="fa fa-key"></i></span>
                                                            <input tabIndex={1} style={{ marginBottom: "10px" }} type="password" name="" placeholder="Password" required value={franchiseData.password} onChange={(e) => { setFranchiseData({ ...franchiseData, password: e.target.value }) }} />
                                                        </div>
                                                        <div class="form-input">
                                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                                            <input tabIndex={1} style={{ marginBottom: "10px" }} type="password" name="" placeholder="Confirm Password" required value={franchiseData.confirmpassword} onChange={(e) => { setFranchiseData({ ...franchiseData, confirmpassword: e.target.value }) }} />
                                                        </div>



                                                        <div class="mb-3">
                                                            <button type="submit" class="btn btn-block text-uppercase modal_button_bg" onClick={() => { CreateFranchiseAccount() }}>
                                                                Submit
                                                            </button>
                                                        </div>

                                                    </div>
                                                </>
                                            }
                                            {
                                                activeData === "franchiseforgot" &&
                                                <>

                                                    <h2 class="title text-center mt-4 modal_heading" >
                                                        Forgot Password
                                                    </h2>
                                                    <div class="form-box px-3">
                                                        <div class="form-input">
                                                            <span><i class="fa-regular fa-envelope"></i></span>
                                                            <input tabIndex={1} type="text" placeholder="Email Address" tabindex="10" required
                                                                value={franchiseForgot.email}
                                                                onChange={(e) => { setFranchiseForgot({ ...franchiseForgot, email: e.target.value }) }}
                                                            />
                                                        </div>
                                                        <div class="form-input">
                                                            <span><i class="fa fa-key"></i></span>
                                                            <input tabIndex={1} type="password" name="" placeholder=" New Password" required
                                                                value={franchiseForgot.password}
                                                                onChange={(e) => { setFranchiseForgot({ ...franchiseForgot, password: e.target.value }) }} />
                                                        </div>
                                                        <div class="form-input">
                                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                                            <input tabIndex={1} type="password" name="" placeholder="Confirm Password" required
                                                                value={franchiseForgot.confirmpassword}
                                                                onChange={(e) => { setFranchiseForgot({ ...franchiseForgot, confirmpassword: e.target.value }) }}
                                                            />
                                                        </div>



                                                        <div class="mb-3">
                                                            <button type="submit" class="btn btn-block text-uppercase modal_button_bg" onClick={() => { ForgotFranchisePassword() }}>
                                                                Submit
                                                            </button>
                                                        </div>


                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>


                </>
            )}


        </>






    )
}

export default Header
