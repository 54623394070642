import { useEffect, useState } from "react"
import Footer from "./Footer"
import Header from "./Header"
import '../styles/blog.css'
import blog from '../images/blog.png'
import popular from '../images/blog-popular.png'
import { Link, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import CartSidebar from "./CartSidebar"
import { FaWhatsapp } from "react-icons/fa"

const BlogDetails = () => {
    const [blogdata, setBlogData] = useState()
    const [allData, setAllData] = useState()
    const [isLoader, setIsLoader] = useState(false)
    const [cartStatus, setCartStatus] = useState(false);
    const [popularBlog, setPopularBlog] = useState([
        { img: popular, heading: "Apple Introduces Search Ads Basic", date: "jun 22, 2018" },
        { img: popular, heading: "new rules, more cars, more races", date: "jun 8, 2018" },
    ])
    const [modalActiveheader, setModalActiveheader] = useState(false);

    const closeModalheader = () => {
        setModalActiveheader(false);
    };

    const location = useLocation();

    console.log(location.state, 'location')
    const getList = async (dataId) => {
        setIsLoader(true)

        let url = `${process.env.REACT_APP_PORT}/admin/blog/getById`
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ id: location.state._id, category: location.state.category })
            });
            const data = await response.json();

            if (response.status === 200) {
                setIsLoader(false)
                setBlogData(data.existingData)
                setAllData(data.allData)

            }
        } catch (e) {

            console.log(e, 'error')
        }


    }

    useEffect(() => {
        getList();
    }, [location.state])

    function getVideoId(url) {
        const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

        const match = url.match(regExp);

        if (match && match[1]) {
            return match[1];
        } else {
            return null;
        }
    }

    function truncateText(text, maxLength = 180) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    }

    return (
        <>
            <Header closeModal={closeModalheader} modalActive={modalActiveheader} setModalActive={setModalActiveheader} cartStatus={cartStatus} setCartStatus={setCartStatus} />
            {
                !isLoader ?
                    <>
                        <div id="main-content" class="blog-page mt-5 mb-5">
                            <div class="container">
                                <div class="row clearfix">
                                    <div class="col-lg-12 col-md-12 left-box">

                                        <div class="card single_post">
                                            <div class="body">
                                                <div class="img-post">
                                                    <img class="d-block img-fluid" style={{ width: "-webkit-fill-available" }} src={`${process.env.REACT_APP_PORT}/admin/blog/file/${blogdata?.image}`} alt="First slide" />
                                                </div>
                                                <h3 className="d-flex justify-content-center heading_main" style={{ fontSize: '25px' }}>{blogdata?.heading}</h3>
                                                {/* {
                                        <div style={{fontSize: "20px", fontWeight: "400"}} dangerouslySetInnerHTML={{ __html: blogdata?.details }}>
                                        </div>
                                    } */}
                                                <p>
                                                    <pre className="custom-editor-content" dangerouslySetInnerHTML={{ __html: blogdata?.details }} style={{ width: '100%', whiteSpace: 'pre-wrap', textAlign: 'justify', fontFamily: 'inherit', fontSize: "20px", fontWeight: "400" }}>

                                                        {/* {blogdata?.details} */}
                                                    </pre>
                                                </p>
                                                {
                                                    blogdata?.link &&

                                                    <div className="mt-3 mb-3" style={{ padding: "20px" }}>
                                                        <iframe
                                                            title="YouTube video player"
                                                            width="100%"
                                                            height="400"
                                                            src={`https://www.youtube.com/embed/${getVideoId(blogdata?.link)}`}
                                                            allowFullScreen
                                                            style={{ borderRadius: "20px" }}>

                                                        </iframe>

                                                    </div>
                                                }
                                                {/* {
                                        <div style={{fontSize: "20px", fontWeight: "400"}} dangerouslySetInnerHTML={{ __html: blogdata?.description }}>
                                        </div>
                                    } */}
                                                <p className="mb-3">

                                                    <pre className="custom-editor-content" dangerouslySetInnerHTML={{ __html: blogdata?.description }} style={{ width: '100%', whiteSpace: 'pre-wrap', textAlign: 'justify', fontFamily: 'inherit', fontSize: "20px", fontWeight: "400" }}>
                                                        {/* {blogdata?.description} */}
                                                    </pre>
                                                </p>
                                                <div className="mt-5">

                                                    {
                                                        blogdata?.otherData?.map((item, index) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        item?.image &&

                                                                        <div class="img-post" >
                                                                            <img class="d-block img-fluid w-100" style={{ borderRadius: "15px" }} src={`${process.env.REACT_APP_PORT}/admin/blog/file/${item?.image}`} alt="First slide" />
                                                                        </div>
                                                                    }
                                                                    {item?.details &&


                                                                        <p>

                                                                            <pre className="custom-editor-content" dangerouslySetInnerHTML={{ __html: item?.details }} style={{ width: '100%', whiteSpace: 'pre-wrap', textAlign: 'justify', fontFamily: 'inherit', fontSize: "20px", fontWeight: "400" }}>
                                                                                {/* {blogdata?.description} */}
                                                                            </pre>
                                                                        </p>
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div class="footer d-flex " style={{ justifyContent: "space-between" }} >
                                                <div class="actions">
                                                    <b>Author Name : {blogdata?.author ? blogdata.author : "unknown"} </b>
                                                </div>
                                                <div class="actions">
                                                    <b>Posted Date : {blogdata?.createdAt}</b>
                                                </div>
                                                {/* <div class="actions">
                                                        <a href="javasc ript:void(0);" class="btn btn-outline-secondary">Continue Reading</a>
                                                    </div> */}
                                                {/* <ul class="stats">
                                        <li><a href="javascript:void(0);">General</a></li>
                                        <li><a href="javascript:void(0);" class="fa fa-heart">28</a></li>
                                        <li><a href="javascript:void(0);" class="fa fa-comment">128</a></li>
                                    </ul> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    allData?.length > 0 &&

                                    <div className="row mt-5" style={{ justifyContent: "center" }}>
                                        <div class=" text-center" style={{ padding: "10px 20px" }}>
                                            <h2 className="heading_main">Similar Blogs</h2>
                                        </div>
                                        {
                                            allData?.map((item) => {
                                                return (
                                                    <>

                                                        <div className="col-lg-4 col-sm-6 mt-2">
                                                            <div class="card" style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 17px 6px' }}>
                                                                <img style={{ height: "250px" }} class="card-img-top" src={`${process.env.REACT_APP_PORT}/admin/blog/file/${item?.image}`} alt="Card image cap" />
                                                                <div class="card-body">
                                                                    <h5 class="card-title">{item.heading}</h5>
                                                                    <p class="card-text text-justify custom-editor-content" dangerouslySetInnerHTML={{ __html: truncateText(item.details) }} ></p>
                                                                    <div class="actions">
                                                                        <Link to="/blogdetails" state={item} class="btn btn-outline-success" >Continue Reading</Link>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>


                                                    </>
                                                )
                                            })
                                        }

                                    </div>
                                }
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="d-flex justify-content-center">
                            <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                                <circle cx="170" cy="170" r="160" stroke="#E2007C" />
                                <circle cx="170" cy="170" r="135" stroke="#404041" />
                                <circle cx="170" cy="170" r="110" stroke="#E2007C" />
                                <circle cx="170" cy="170" r="85" stroke="#404041" />
                            </svg>
                        </div>
                    </>
            }
            <Footer />
            <a class="whats-app" href="https://wa.link/6bjptw" target="_blank">
                <FaWhatsapp style={{}} />
            </a>
            <CartSidebar cartStatus={cartStatus} setCartStatus={setCartStatus} />

        </>
    )
}

export default BlogDetails