import { useEffect, useState } from "react"
import Header from "./Header"
import { useLocation, useNavigate } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import uploadFile from "../upload/uploadFile"
import deleteFile from "../upload/deleteFile"
import Footer from "./Footer"
import CartSidebar from "./CartSidebar"
import { FaWhatsapp } from "react-icons/fa"
import axios from "axios"
import helpbg from '../images/helpbg.png'


const FranchiseProfile = () => {
    const [cartStatus, setCartStatus] = useState(false);
    const [orderModal, setOrderModal] = useState(false);
    const [orderModalData, setOrderModalData] = useState(false);
    const [needHelp, setNeedHelp] = useState()
    const [active, setActive] = useState(0)
    const [forgotPass, setForgotPass] = useState(false)
    const [newPassword, setNewPassword] = useState({
        oldpassword: "",
        password: "",
        confirmpassword: "",
    })
    const [email, setEmail] = useState()
    const [state, setState] = useState()
    const [district, setDistrict] = useState()
    const [orderDetails, setOrderDetails] = useState()
    const [name, setName] = useState()
    const [phone, setPhone] = useState()
    const [edit, setEdit] = useState(false)
    const [id, setId] = useState()
    const [documentList, setDocumentList] = useState();
    const [selectDocument, setSelectDocument] = useState()
    const [file, setFile] = useState()
    const [profileImage, setProfileImage] = useState()
    const [disble, setDisble] = useState(false)
    const [document, setDocument] = useState()
    const [modalActive, setModalActive] = useState(false);
    const [infoModal, setInfoModal] = useState(false);
    const [useData, setUserData] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [notification, setNotification] = useState();
    const [offersList, setOffersList] = useState();
    const [serviceData, setServiceData] = useState();
    const [selectedService, setSelectedService] = useState();
    const [imageShow, setImageShow] = useState({ value: false, image: "" })
    const [modalActiveheader, setModalActiveheader] = useState(false);
    const [infoData, setInfoData] = useState()


    const closeModalheader = () => {
        setModalActiveheader(false);
    };


    const openModal = () => {
        setModalActive(true);
    };

    const closeModal = () => {
        setModalActive(false);
        setInfoModal(false)
        setProfileModal(false)
    };


    const navigate = useNavigate();

    const Logout = () => {
        localStorage.removeItem("logintype");
        localStorage.removeItem("franchiseid");
        navigate('/franchiseData')
    }

    const location = useLocation();


    const getData = async (id) => {
        let url = `${process.env.REACT_APP_PORT}/admin/getuserfranchise`
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ id: id })
            });
            const data = await response.json();
            if (response.status === 200) {
                setUserData(data?.user)
                setName(data?.user?.name)
                setPhone(data?.user?.number)
                setEmail(data?.user?.email)
                setState(data?.user?.State)
                setDistrict(data?.user?.district)
                setOrderDetails(data?.user?.ordersData)

            } else {
                toast.error(data.error)
            }
        } catch (e) {
            toast.error(e)
        }
    }

    const getList = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PORT}/admin/documentlist/list`);
            const data = await response.json();
            if (response.status === 200) {
                setDocumentList(data)
            }
            // console.log('Data received:', data);

        } catch (e) {
            console.log(e, 'error')
        }

    }
    const getOffers = async () => {
        try {
            if (localStorage.getItem('franchiseid')) {
                const response = await fetch(`${process.env.REACT_APP_PORT}/admin/offer/getoffers`);
                const data = await response.json();
                if (response.status === 200) {
                    setOffersList(data)
                }

            } else {

                const response = await fetch(`${process.env.REACT_APP_PORT}/admin/offer/getoffersUsers`);
                const data = await response.json();
                if (response.status === 200) {
                    setOffersList(data)
                }
            }
            // console.log('Data received:', data);

        } catch (e) {
            console.log(e, 'error')
        }

    }




    const getNotification = async () => {
        try {
            let url = `${process.env.REACT_APP_PORT}/admin/order/notifications`
            const Notificationresponse = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ dataid: localStorage.getItem('id') })
            });
            const dataNotification = await Notificationresponse.json();
            if (Notificationresponse.status === 200) {
                const filteredNotifications = dataNotification.filter(data => data.userId === localStorage.getItem('id'));
                // Save filtered notifications to state
                setNotification(filteredNotifications);
            }

        } catch (e) {

            console.log(e, 'error')
        }
    }


    useEffect(() => {
        setId(localStorage.getItem('franchiseid'))
        getData(localStorage.getItem('franchiseid'))
        // setDocument([{ image: "img", name: 'name' }, { image: "img", name: 'name' }])
        getList()
        getNotification()
        getOffers()
    }, [])

    const Submit = async () => {
        let url = `${process.env.REACT_APP_PORT}/admin/editFranchiseAccount`


        try {
            const response = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ state: state, district: district, email: email, name: name, number: phone, id: localStorage.getItem('franchiseid') })
            });
            const data = await response.json();
            if (response.status === 200) {
                // localStorage.setItem("email", data.user.email);
                // localStorage.setItem("id", data.user._id);
                // localStorage.setItem('number', data.user.number);
                // localStorage.setItem('name', data.user.name);

                toast.success("Save Data Sucesfully!")

                setEdit(false)

            } else {
                toast.error(data.error)
            }
        } catch (e) {
            toast.error(e)

            console.log(e, 'error')
        }

    }





    const AddDocument = async () => {
        let url = `${process.env.REACT_APP_PORT}/admin/addDocumentsFranchise`
        if (file && selectDocument !== "0") {
            setDisble(true)
            // const fileName = file.name + Date.now();
            // await uploadFile(fileName, file, callBack)
            const formData = new FormData();
            formData.append("file", file);
            formData.append("name", selectDocument);
            formData.append("id", localStorage.getItem('franchiseid'));

            try {
                const response = await fetch(url, {
                    method: "POST",
                    // headers: { "Content-Type":   "application/json" },
                    body: formData,
                });
                const data = await response.json();
                if (response.status === 200) {
                    setDisble(false)

                    setDocument(data?.user?.documents)
                    setDisble(false)
                    toast.success("Save Data Sucesfully!")
                    closeModal()

                } else {
                    toast.error(data.error)
                }
            } catch (e) {
                toast.error(e)

                console.log(e, 'error')
            }

        }
    }

    const ChangeProfile = async () => {
        let url = `${process.env.REACT_APP_PORT}/admin/profileImage`
        if (profileImage) {
            setDisble(true)
            // const fileName = profileImage?.name + Date.now();
            // await uploadFile(fileName, profileImage, callBack)
            const formData = new FormData();
            formData.append("file", profileImage);
            formData.append("id", id);

            try {
                const response = await fetch(url, {
                    method: "POST",
                    body: formData,
                });
                const data = await response.json();
                if (response.status === 200) {
                    setDisble(false)
                    setUserData(data?.user)
                    toast.success("Save Data Sucesfully!")
                    closeModal()

                } else {
                    toast.error(data.error)
                }
            } catch (e) {
                toast.error(e)
                console.log(e, 'error')
            }

        }
    }






    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {

        const serviceDataApi = async () => {
            const response = await fetch(`${process.env.REACT_APP_PORT}/admin/service/list`);
            const data = await response.json();
            if (response.status === 200) {

                let array = [];
                data?.map((item) => {

                    let data = {
                        service: item?.innerCategoryName,
                        packages: item?.stepSixData
                    }
                    console.log(data, 'data')
                    array.push(data)
                })
                setServiceData(array)
            }
        }
        serviceDataApi()



        // },[localStorage.getItem('franchiseid')])
    }, [])


    const ForgotPassword = async () => {
        if (email && newPassword.password && newPassword.confirmpassword) {
            if (newPassword.password === newPassword.confirmpassword) {
                let url = `${process.env.REACT_APP_PORT}/admin/ForgotfranchiseOldPassword`
                try {
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ email: email, password: newPassword.password, oldpassword: newPassword.oldpassword })
                    });
                    if (response.status === 200) {
                        // setEmail()
                        setNewPassword({ password: "", confirmpassword: "" })
                        // setPasswod()
                        // setCreateEmail()
                        // setCreateName()
                        // setCreatePassword()
                        toast.success("Password Update Sucesfully!")
                        setForgotPass(false)

                    } else {
                        const data = await response.json();
                        toast.error(data.error)
                    }
                } catch (e) {
                    toast.error(e)

                    console.log(e, 'error')
                }
            } else {
                toast.error("password and confirmPassword not same!")
            }
        } else {

            toast.error("please fille all filled!")
        }


    }

    const DeleteDocument = async (item) => {
        let url = `${process.env.REACT_APP_PORT}/admin/deleteDocumentFranchise`
        setDisble(true)
        // await deleteFile(item.image, callBack);

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ userId: localStorage.getItem('franchiseid'), docId: item._id })
            });
            const data = await response.json();
            if (response.status === 200) {
                setDisble(false)
                setDocument(data?.user?.documents)
                toast.success("Save Data Sucesfully!")


            } else {
                toast.error(data.error)
            }
        } catch (e) {
            toast.error(e)

            console.log(e, 'error')
        }
    }

    const CreateOrder = async () => {
        let userId = localStorage.getItem('id');
        let service = " ";
        console.log(service);
        const orderData = { amount: orderModalData.amount, currency: "INR" };
        let url = `${process.env.REACT_APP_PORT}/admin/order/createOrder`;

        try {
            const { data } = await axios.post(url, orderData);
            // setOrderId(data.id);
            const options = {
                key: 'rzp_test_6igAuqeDdhUC5t', // Enter the Key ID generated from the Dashboard
                amount: data.amount,
                currency: "INR",
                name: 'Vyapar Bandhu Consultancy LLP',
                description: 'Test Transaction',
                order_id: data.id,
                handler: async (response) => {
                    console.log(response);
                    console.log(response.razorpay_payment_id ? true : false);


                    let paymentUrl = `${process.env.REACT_APP_PORT}/admin/order/placeSingledOrderFranchiseProfile`;
                    const responseData = await fetch(paymentUrl, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            razerpayData: response,
                            franchiseid: localStorage.getItem('franchiseid'),
                            discountamount: orderModalData.amount,
                            userData: useData,
                            package: orderModalData,
                            service: service
                        })
                    });
                    const data = await responseData.json();
                    if (responseData.status === 200) {
                        setOrderModal(false);
                        toast.success('Order Created Successfully');
                    } else {
                        toast.error('Something went wrong');
                    }


                },
                prefill: {
                    name: useData.name,
                    email: useData.email,
                    contact: useData.number
                },
                theme: {
                    color: '#3399cc'
                },
                modal: {
                    ondismiss: () => {
                        toast.error('Order Cancelled');
                    }
                }
            };

            const rzp1 = new window.Razorpay(options);
            rzp1.open();

        } catch (e) {
            toast.error(e.message);
        }
    }




    const SubmitData = async () => {
        if (needHelp) {
            let url = `${process.env.REACT_APP_PORT}/admin/NeedHelp`
            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ email: email, name: name, number: phone, detail: needHelp, type: "user" })
                });
                if (response.status === 200) {
                    setNeedHelp()
                    toast.success("Your details captured successfully, our expert will call you within 24 working hours, please wait")
                }
            }
            catch (e) {
                toast.error(e)
                console.log(e, 'error')
            }
        } else {
            toast.error('Fill Required details')
        }
    }

    return (
        <>
            <div style={{ backgroundColor: "#eee", height: "100vh" }}>

                <Header login={email} closeModal={closeModalheader} modalActive={modalActiveheader} setModalActive={setModalActiveheader} cartStatus={cartStatus} setCartStatus={setCartStatus} />

                {modalActive && (
                    <>
                        <div className="modal-overlay" onClick={closeModal}></div>
                        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">

                            <section className="vh-100 container_body">
                                <div className="container py-5 h-100">
                                    <div className="row px-3 d-flex align-items-center justify-content-center h-100">
                                        <div className="col-md-10 w-auto col-lg-10 col-xl-9 card flex-row mx-auto px-0">
                                            {/* <div className="img-left d-none d-md-flex">
                                            <div>
                    
                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                                                    alt="login form" class="img-fluid" style={{ height: "100%" }} />
                                            </div>
                                        </div> */}

                                            <div className="card-body">
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ fontSize: "30px" }} onClick={closeModal}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>


                                                <h2 class="title text-center mt-4 modal_heading" >
                                                    Add  Document
                                                </h2>
                                                <div class="form-box px-3">

                                                    <div class="form-input">
                                                        <select class="form-select" aria-label="Default select example" style={{ borderRadius: "30px" }} onChange={(e) => { setSelectDocument(e.target.value) }}>
                                                            <option value='0'>Choose Document</option>

                                                            {
                                                                documentList?.map((item) => {
                                                                    return (
                                                                        <>

                                                                            <option value={item.name}>{item.name}</option>

                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div class="mb-3 mt-3">
                                                        <input class="form-control" type="file" id="formFile" style={{ borderRadius: "30px" }} onChange={(e) => { setFile(e.target.files[0]) }} />
                                                    </div>

                                                    <div class="mb-3">

                                                        <button type="submit" class="btn btn-block text-uppercase modal_button_bg" onClick={() => { AddDocument() }} disabled={disble}>
                                                            {disble ?
                                                                <div class="spinner-border text-warning" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                                :

                                                                <span>Submit</span>
                                                            }

                                                        </button>
                                                    </div>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                    </>
                )}
                {profileModal && (
                    <>
                        <div className="modal-overlay" onClick={closeModal}></div>
                        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">

                            <section className="vh-100 container_body">
                                <div className="container py-5 h-100">
                                    <div className="row px-3 d-flex align-items-center justify-content-center h-100">
                                        <div className="col-md-10 w-auto col-lg-10 col-xl-9 card flex-row mx-auto px-0">


                                            <div className="card-body">
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ fontSize: "30px" }} onClick={closeModal}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>


                                                <h2 class="title text-center mt-4 modal_heading" >
                                                    Change Profile Image
                                                </h2>
                                                <div class="form-box px-3">


                                                    <div class="mb-3 mt-3">
                                                        <input class="form-control" type="file" id="formFile" style={{ borderRadius: "30px" }} onChange={(e) => { setProfileImage(e.target.files[0]) }} />
                                                    </div>

                                                    <div class="mb-3">

                                                        <button type="submit" class="btn btn-block text-uppercase modal_button_bg" onClick={() => { ChangeProfile() }} disabled={disble}>
                                                            {disble ?
                                                                <div class="spinner-border text-warning" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                                :

                                                                <span>Submit</span>
                                                            }

                                                        </button>
                                                    </div>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                    </>
                )}
                {infoModal && (
                    <>
                        <div className="modal-overlay" onClick={closeModal}></div>
                        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">



                            <section className="vh-100 container_body">
                                <div className="container py-5 h-100">
                                    <div className="row px-3 d-flex align-items-center justify-content-center h-100">
                                        <div className="col-md-10  col-lg-10 col-xl-9 card flex-row mx-auto px-0  " style={{ width: "90%", maxHeight: '100%' }}>


                                            <div className="card-body p-sm-4 p-2" style={{ overflow: "hidden" }}>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ fontSize: "30px" }} onClick={() => {
                                                    closeModal()
                                                }}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>


                                                <h2 class="title text-center mt-4 modal_heading" >
                                                    Oreder Details
                                                </h2>
                                                <div class="p-sm-2 p-0  modal-footer mt-2 d-flex flex-column justify-content-center">


                                                    {/* <div>
                                                                <div>Service Name : </div>
                                                                <div>{selectServicedata.innerCategoryName}</div>
                                                            </div> */}

                                                    <div class="card" style={{ boxShadow: "none" }}>
                                                        <div class="card-body mx-sm-4 p-0">
                                                            <div class="container w-75 d-md-block d-none p-0">


                                                                <div class="d-flex">
                                                                    <div className="w-50"><b>User Name</b></div>
                                                                    <div>: <span>{infoData?.userData?.name}</span></div>
                                                                </div>
                                                                <hr style={{ border: "1px solid black;" }} />
                                                                <div class="d-flex">
                                                                    <div className="w-50"><b>User Email</b></div>
                                                                    <div>: <span>{infoData?.userData?.email}</span></div>
                                                                </div>
                                                                <hr style={{ border: "1px solid black;" }} />
                                                                <div class="d-flex">
                                                                    <div className="w-50"><b>User Phone Number</b></div>
                                                                    <div>: <span>{infoData?.userData?.number}</span></div>
                                                                </div>
                                                                <hr style={{ border: "1px solid black;" }} />

                                                                <div class="d-flex">
                                                                    <div className="w-50"><b>Package Amount</b></div>
                                                                    <div>: <span>{infoData?.package?.discount_amount ? infoData?.package?.discount_amount : infoData?.package?.amount}</span></div>
                                                                </div>

                                                                <hr style={{ border: "1px solid black;" }} />






                                                            </div>
                                                            <div class="container p-0 d-md-none d-block">


                                                                <div class="d-flex">
                                                                    <div className="w-50"><b>User Name</b></div>
                                                                    <div>: <span>{infoData?.userData?.name}</span></div>
                                                                </div>
                                                                <hr style={{ border: "1px solid black;" }} />
                                                                <div class="d-flex">
                                                                    <div className="w-50"><b>User Email</b></div>
                                                                    <div>: <span>{infoData?.userData?.email}</span></div>
                                                                </div>
                                                                <hr style={{ border: "1px solid black;" }} />
                                                                <div class="d-flex">
                                                                    <div className="w-50"><b>User Phone Number</b></div>
                                                                    <div>: <span>{infoData?.userData?.number}</span></div>
                                                                </div>
                                                                <hr style={{ border: "1px solid black;" }} />

                                                                <div class="d-flex">
                                                                    <div className="w-50"><b>Package Amount</b></div>
                                                                    <div>: <span>{infoData?.package?.discount_amount ? infoData?.package?.discount_amount : infoData?.package?.amount}</span></div>
                                                                </div>

                                                                <hr style={{ border: "1px solid black;" }} />





                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>





                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>


                    </>
                )}


                <section style={{ backgroundColor: "#eee" }}>

                    <div class="container py-5">
                        <div class="d-lg-flex justify-content-end mb-4">

                            <button type="button" class={`mb-lg-0 mb-2 btn btn-outline-success ms-1 ${active === 6 ? "btn-success text-white" : 'btn-outline-success'}`} onClick={() => { setActive(6) }}> Subscription plans</button>
                            <button type="button" class={`mb-lg-0 mb-2 btn btn-outline-success ms-1 ${active === 5 ? "btn-success text-white" : 'btn-outline-success'}`} onClick={() => { setActive(5) }}>Purchase Documents</button>
                            {
                                localStorage.getItem('franchiseid') &&

                                <button type="button" class={`mb-lg-0 mb-2 btn btn-outline-success ms-1 ${active === 4 ? "btn-success text-white" : 'btn-outline-success'}`} onClick={() => { setActive(4) }}> Package Details</button>
                            }
                            <button type="button" class={`mb-lg-0 mb-2 btn btn-outline-success ms-1 ${active === 3 ? "btn-success text-white" : 'btn-outline-success'}`} onClick={() => { setActive(3) }}> Offers</button>
                            {/* } */}
                            {/* <button type="button" class={`mb-lg-0 mb-2 btn btn-outline-success ms-1 ${active === 2 ? "btn-success text-white" : 'btn-outline-success'}`} onClick={() => { setActive(2) }}> Notification</button> */}
                            <button type="button" class={`mb-lg-0 mb-2 btn btn-outline-success ms-1 ${active === 1 ? "btn-success text-white" : 'btn-outline-success'}`} onClick={() => { setActive(1) }}>Order History</button>
                            <button type="button" class={`mb-md-0 mb-2 btn btn-outline-success ms-1 ${active === 7 ? "btn-success text-white" : 'btn-outline-success'}`} onClick={() => { setActive(7) }}>Need Help</button>

                            <button type="button" class={`mb-lg-0 mb-2 btn btn-outline-success ms-1 ${active === 0 ? "btn-success text-white" : 'btn-outline-success'}`} onClick={() => { setActive(0) }}>Profile</button>
                            <button type="button" class="mb-lg-0 mb-2 btn btn-outline-danger ms-1" onClick={() => { Logout() }}>Log Out</button>
                        </div>



                        {
                            active === 0
                            &&
                            <>

                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="card mb-4">
                                            <div class="card-body text-center">
                                                <div class="d-flex justify-content-end mb-4">
                                                    {/* <button type="button" class="btn btn-outline-success ms-1 btn-sm" onClick={() => { setProfileModal(!profileModal) }}><i class="bi bi-cloud-arrow-up"></i></button> */}
                                                </div>

                                                {
                                                    useData?.image ?
                                                        <img src={`${process.env.REACT_APP_PORT}/admin/home/file/${useData?.image}`} alt="avatar"
                                                            class="rounded-circle img-fluid" style={{ width: "200px;", height: "200px" }} />
                                                        :
                                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp" alt="avatar"
                                                            class="rounded-circle img-fluid" style={{ width: "150px;" }} />
                                                }
                                                <h5 class="my-3">{name ? name : 'user Name'}</h5>
                                                <div class="d-flex justify-content-center mb-2">
                                                    <button type="button" class="btn btn-outline-success ms-1" onClick={() => { setEdit(!edit) }}>Edit Profile</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-8">
                                        <div class="card mb-4">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div className="d-flex justify-content-end">
                                                        <button type="button" class="btn btn-outline-success ms-1 mb-2" onClick={() => { setForgotPass(!forgotPass) }}>Update password</button>
                                                    </div>
                                                    {
                                                        forgotPass === true &&
                                                        <>
                                                            <div class="col-sm-3">
                                                                <p class="mb-0">Email</p>
                                                            </div>
                                                            <div class="col-sm-9">

                                                                <p class="text-muted mb-2">{email ? email : 'No UserName set'}</p>
                                                                <input type="text" class="form-control mb-2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Old Password" value={newPassword.oldpassword} onChange={(e) => { setNewPassword({ ...newPassword, oldpassword: e.target.value }) }}></input>
                                                                <input type="text" class="form-control mb-2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="New Password" value={newPassword.password} onChange={(e) => { setNewPassword({ ...newPassword, password: e.target.value }) }}></input>
                                                                <input type="text" class="form-control mb-2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Confirm Password" value={newPassword.confirmpassword} onChange={(e) => { setNewPassword({ ...newPassword, confirmpassword: e.target.value }) }}></input>

                                                                <button type="button" class="btn btn-primary ms-1 mb-2" onClick={() => { ForgotPassword() }}>Submit</button>


                                                            </div>
                                                            <hr />
                                                        </>
                                                    }
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">Full Name</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        {
                                                            edit ?
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name" value={name} onChange={(e) => { setName(e.target.value) }}></input>
                                                                :
                                                                <p class="text-muted mb-0">{name ? name : 'No UserName set'}</p>

                                                        }
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">Email</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        {
                                                            edit ?
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" value={email} onChange={(e) => { setEmail(e.target.value) }}></input>
                                                                :
                                                                <p class="text-muted mb-0">{email ? email : 'No Email Added'}</p>

                                                        }
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">State</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        {
                                                            edit ?
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter State" value={state} onChange={(e) => { setState(e.target.value) }}></input>
                                                                :
                                                                <p class="text-muted mb-0">{state ? state : 'No state Added'}</p>

                                                        }
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">District</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        {
                                                            edit ?
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter District" value={district} onChange={(e) => { setDistrict(e.target.value) }}></input>
                                                                :
                                                                <p class="text-muted mb-0">{district ? district : 'No district Added'}</p>

                                                        }
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">Phone</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        {
                                                            edit ?
                                                                <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter PhoneNumber" value={phone} onChange={(e) => { setPhone(e.target.value) }}></input>
                                                                :
                                                                <p class="text-muted mb-0">{phone ? phone : 'No number Added'}</p>

                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    localStorage.getItem('franchiseid') &&
                                                    <>

                                                        <hr />
                                                        <div class="row">
                                                            <div class="col-sm-3">
                                                                <p class="mb-0">Franchise Id</p>
                                                            </div>
                                                            <div class="col-sm-9 d-md-flex " style={{ justifyContent: 'space-between' }}>

                                                                <p class="text-muted mb-0">{localStorage.getItem('franchiseid')}</p>


                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {
                                                    edit &&
                                                    <>

                                                        <hr />


                                                        <div class="row mt-3">
                                                            <div class="col-sm-3">
                                                            </div>
                                                            <div class="col-sm-9">
                                                                <button type="button" class="btn btn-primary ms-1" onClick={() => { Submit() }}>Submit</button>

                                                            </div>
                                                        </div>
                                                    </>

                                                }

                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card mb-4 mb-md-0">
                                                    <div class="card-body">
                                                        <div className="d-sm-flex" style={{ justifyContent: "space-between" }}>
                                                            <p class="mb-sm-4"><span class="text-green  me-1" style={{ fontSize: "25px" }}>Documents</span>
                                                            </p>
                                                            <div class="mb-sm-0 mb-3 ">
                                                                <button type="button" class="btn btn-outline-success ms-1" onClick={() => { openModal() }}>Upload Document</button>
                                                            </div>
                                                        </div>
                                                        <table className='table'>
                                                            <thead>
                                                                <tr>

                                                                    <th scope='col' style={{ opacity: "1", fontWeight: "500", borderBottom: 'none' }}> Name</th>
                                                                    {/* <th scope='col' style={{ opacity: "1", fontWeight: "500", borderBottom: 'none' }}> Image</th> */}
                                                                    <th scope='col' style={{ opacity: "1", fontWeight: "500", borderBottom: 'none' }}>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    document?.length ? document.map((item, index) => {
                                                                        return (
                                                                            <>

                                                                                <tr>

                                                                                    <td>{item.name}</td>
                                                                                    {/* <td>
                                                            <img src={`${process.env.REACT_APP_BUCKET_URL}/${item.image && item.image}`} style={{ width: "120px" }} className="mb-1" alt="No Previous Image" />


                                                        </td> */}
                                                                                    <td>


                                                                                        <button type="button" class="btn btn-sm btn-primary" style={{ marginRight: "5px" }} onClick={() => { window.open(`${process.env.REACT_APP_PORT}/admin/templates/file/${item.image}`) }}
                                                                                            //  onClick={() => { setImageShow({ value: true, image: item.image }) }}
                                                                                            disabled={disble}>


                                                                                            <span><i class="bi bi-eye"></i></span>
                                                                                        </button>
                                                                                        <button type="button" class="btn btn-sm btn-danger" onClick={() => { DeleteDocument(item) }} disabled={disble}>


                                                                                            <span><i class="bi bi-trash"></i></span>
                                                                                        </button>


                                                                                    </td>


                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })
                                                                        :
                                                                        <>
                                                                            <tr>
                                                                                <td className="text-center" colSpan={2}>No Data</td>
                                                                            </tr>
                                                                        </>
                                                                }

                                                            </tbody>

                                                        </table>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </>

                        }

                        {
                            active === 1
                            &&
                            <>

                                <div class="row">

                                    <div class="col-12">

                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card mb-4 mb-md-0">
                                                    <div class="card-body">
                                                        <div style={{ justifyContent: "space-between", display: "flex" }}>
                                                            <p class="mb-4"><span class=" text-green  me-1" style={{ fontSize: "25px" }}>Orders</span>
                                                            </p>
                                                            <div class=" ">
                                                                {/* <button type="button" class="btn btn-outline-primary ms-1" onClick={() => { openModal() }}>Upload Document</button> */}
                                                            </div>
                                                        </div>
                                                        <div style={{ overflowX: 'auto' }}>

                                                            <table className='table'>
                                                                <thead>
                                                                    <tr>

                                                                        <th scope='col' style={{ opacity: "1", fontWeight: "500", borderBottom: 'none' }}> Service Name</th>
                                                                        <th scope='col' style={{ opacity: "1", fontWeight: "500", borderBottom: 'none' }}> Package</th>
                                                                        <th scope='col' style={{ opacity: "1", fontWeight: "500", borderBottom: 'none' }}> Amount</th>
                                                                        <th scope='col' style={{ opacity: "1", fontWeight: "500", borderBottom: 'none' }}> Pay Amount</th>
                                                                        <th scope='col' style={{ opacity: "1", fontWeight: "500", borderBottom: 'none' }}> Franchise Amount</th>
                                                                        {/* <th scope='col' style={{ opacity: "1", fontWeight: "500", borderBottom: 'none' }}> Image</th> */}
                                                                        <th scope='col' style={{ opacity: "1", fontWeight: "500", borderBottom: 'none' }}>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        orderDetails?.length ? orderDetails.map((item, index) => {
                                                                            return (
                                                                                <>

                                                                                    <tr>

                                                                                        <td>{item.service}</td>
                                                                                        <td>{item.package?.package}</td>
                                                                                        <td>₹{item.package?.amount}</td>

                                                                                        {/* {item.status === "pending" && */}
                                                                                        <td><span >₹{item.discountamount}</span>
                                                                                        </td>
                                                                                        <td>₹{item.package?.fr_amount ? item.package?.fr_amount : item.package?.amount}</td>
                                                                                        {/* } */}
                                                                                        {/* {item.status === "complete" &&
                                                                                            <td><span class="badge badge-success">{item.status}</span>
                                                                                            </td>
                                                                                        }
                                                                                        {item.status === "failed" &&
                                                                                            <td><span class="badge badge-danger">{item.status}</span>
                                                                                            </td>
                                                                                        } */}

                                                                                        <td>


                                                                                            <button type="button" class="btn btn-sm btn-primary" onClick={() => { setInfoData(item); setInfoModal(!infoModal) }}  >
                                                                                                <span><i class="bi bi-info-circle"></i></span>
                                                                                            </button>


                                                                                        </td>


                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        })
                                                                            :
                                                                            <>
                                                                                <tr>
                                                                                    <td className="text-center f_20" colSpan={6}><b>No Data</b></td>
                                                                                </tr>
                                                                            </>



                                                                    }

                                                                </tbody>

                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </>

                        }

                        {
                            active === 3 &&
                            <>
                                <section >
                                    <div class="text-center container pb-5 pt-3 p-0 p-sm-3">
                                        <h1 className="text-center heading_main mb-5">Offers</h1>

                                        <div class="row" style={{ display: 'flex', justifyContent: 'center' }}>
                                            {offersList && offersList.length ? (
                                                offersList.map((item, index) => (
                                                    <div class="col-lg-4 col-md-6 col-sm-6 mb-4" key={index}>
                                                        <div class="card h-100 d-flex flex-column justify-content-between">
                                                            <div class="bg-image hover-zoom ripple ripple-surface ripple-surface-light" data-mdb-ripple-color="light">
                                                                <div class="mask">
                                                                    <div class="d-flex justify-content-start align-items-end h-100" style={{ padding: "5px 3px" }}>
                                                                        <h4><span class="badge bg-primary ms-2">{item.offer}</span></h4>
                                                                    </div>
                                                                </div>
                                                                <div class="hover-overlay">
                                                                    <div class="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.15);" }}></div>
                                                                </div>
                                                            </div>
                                                            <div class="card-body" style={{ padding: "0px 15px 10px 15px" }}>
                                                                <h5 class="card-title mb-3" style={{ color: "#198754" }}>{item.title}</h5>
                                                                <h6 class="mb-3">{item.code}</h6>
                                                                <p style={{ fontSize: "17px", textAlign: "justify" }}>{item.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div class="col-lg-4 col-md-6 col-sm-6 mb-4">
                                                    <div class="card h-100">
                                                        <div class="card-body" style={{ padding: "0px 15px 10px 15px" }}>
                                                            <h5 class="card-title mb-3" style={{ color: "#198754" }}>No Data</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}





                                        </div>


                                    </div>
                                </section>
                            </>
                        }
                        {
                            active === 4 &&
                            <>
                                <section >
                                    <div class="text-center container pb-5 pt-3 p-0 p-sm-3">
                                        <h1 className="text-center heading_main ">Package Deatils</h1>

                                    </div>
                                    <select class="form-select f_24" aria-label="Default select example" onChange={(e) => {
                                        setSelectedService(serviceData.filter((item) => item.service === e.target.value));

                                    }}>
                                        <option selected>Select the service</option>
                                        {
                                            serviceData.map((item) => {
                                                return (

                                                    <option value={item.service} selected={selectedService && selectedService[0]?.service === item.service}>{item.service}</option>
                                                )
                                            })
                                        }

                                    </select>

                                    {
                                        selectedService &&


                                        <div className="d-flex row mt-3" style={{ justifyContent: 'space-around' }}>
                                            {
                                                selectedService?.map((data, index) => {
                                                    return (
                                                        <>
                                                            {
                                                                data?.packages?.map((item) => {
                                                                    return (
                                                                        <>


                                                                            <div className="col-lg-6 col-md-6 p-4" style={{ display: "flex", alignItems: 'stretch' }}>

                                                                                <div class="card " style={{ borderRadius: "15px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 15px 10px" }} >
                                                                                    <div className="text-center bg_orange text-white text_workflow d-flex f_24" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px", height: "160px", flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                                                        <div className="f_30">{item.package}</div>
                                                                                        <div className="f_20">Amount : ₹ {item.amount}</div>
                                                                                        <div className="f_20">Discount Amount : ₹ {item.discount_amount ? item.discount_amount : item.amount}</div>


                                                                                        <div className="f_20">Franchise Amount : ₹ {item.fr_amount ? item.fr_amount : item.amount}</div>

                                                                                    </div>
                                                                                    <div class="card-body p-sm-3 p-2">
                                                                                        <div>

                                                                                            <ul className="f_24 custom-editor-content" dangerouslySetInnerHTML={{ __html: item.description }}>

                                                                                                {/* <pre style={{ fontSize: '18px', width: '100%', whiteSpace: 'pre-wrap', textAlign: 'justify', fontFamily: 'inherit' }} dangerouslySetInnerHTML={{ __html: data.description }}>
                                        </pre> */}
                                                                                            </ul>

                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                    )

                                                                })
                                                            }

                                                            {
                                                                data.packages.length === 0 &&
                                                                <h1 className="d-flex justify-content-center">No Data</h1>
                                                            }






                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </section>
                            </>
                        }
                        {
                            active === 5 &&
                            <>

                                <div class="row">

                                    <div class="col-12">

                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card mb-4 mb-md-0">
                                                    <div class="card-body">
                                                        <div style={{ justifyContent: "space-between", display: "flex" }}>
                                                            <p class="mb-4"><span class=" text-green  me-1" style={{ fontSize: "25px" }}>Purchase Documents</span>
                                                            </p>
                                                            <div class=" ">
                                                                {/* <button type="button" class="btn btn-outline-primary ms-1" onClick={() => { openModal() }}>Upload Document</button> */}
                                                            </div>
                                                        </div>
                                                        <div style={{ overflowX: 'auto' }}>

                                                            <table className='table'>
                                                                <thead>
                                                                    <tr>

                                                                        <th scope='col' style={{ opacity: "1", fontWeight: "500", borderBottom: 'none' }}> File Name</th>
                                                                        <th scope='col' style={{ opacity: "1", fontWeight: "500", borderBottom: 'none' }}> Price</th>
                                                                        <th scope='col' style={{ opacity: "1", fontWeight: "500", borderBottom: 'none' }}> Category</th>
                                                                        <th scope='col' style={{ opacity: "1", fontWeight: "500", borderBottom: 'none' }}> File</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        useData?.DocumentOrders?.length ? useData?.DocumentOrders.map((item, index) => {
                                                                            return (
                                                                                <>

                                                                                    <tr>

                                                                                        <td>{item?.name} </td>
                                                                                        <td>{item?.price} </td>
                                                                                        <td>{item?.type} </td>
                                                                                        <td>
                                                                                            <button type="button" class="btn btn-sm btn-primary" onClick={() => { window.open(`${process.env.REACT_APP_PORT}/admin/templates/file/${item.fileName}`) }}><i class="bi bi-cloud-download"></i> Download</button>
                                                                                        </td>





                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        })
                                                                            :
                                                                            <>
                                                                                <tr>
                                                                                    <td className="text-center" colSpan={4}>No Data</td>
                                                                                </tr>
                                                                            </>



                                                                    }

                                                                </tbody>

                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </>
                        }
                        {
                            active === 6 &&
                            <>

                                <div class="row">

                                    <div class="col-12">

                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card mb-4 mb-md-0">
                                                    <div class="card-body">
                                                        <div style={{ justifyContent: "space-between", display: "flex" }}>
                                                            <p class="mb-4"><span class=" text-green  me-1" style={{ fontSize: "25px" }}>Subscription plans</span>
                                                            </p>
                                                            <div class=" ">
                                                                {/* <button type="button" class="btn btn-outline-primary ms-1" onClick={() => { openModal() }}>Upload Document</button> */}
                                                            </div>
                                                        </div>
                                                        <div style={{ overflowX: 'auto' }}>

                                                            <table className='table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th scope='col' style={{ fontWeight: "600", borderBottom: 'none' }}>Packages Name</th>
                                                                        <th scope='col' style={{ fontWeight: "600", borderBottom: 'none' }}>Amount</th>
                                                                        <th scope='col' style={{ fontWeight: "600", borderBottom: 'none' }}> Details</th>
                                                                        <th scope='col' style={{ fontWeight: "600", borderBottom: 'none' }}> Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {
                                                                        useData && useData?.subscriptionsplan?.map((item, index) => {

                                                                            return (
                                                                                <>

                                                                                    <tr>
                                                                                        <td>{item?.name} </td>
                                                                                        <td>{item?.amount} </td>
                                                                                        <td className="custom-editor-content" style={{ width: "60%" }} dangerouslySetInnerHTML={{ __html: item?.details }}>
                                                                                        </td>
                                                                                        <td>

                                                                                            <button type="button" class="btn btn-sm btn-success" onClick={() => { setOrderModalData(item); setOrderModal(!orderModal) }}> Pay Now</button>

                                                                                        </td>




                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        })

                                                                    }
                                                                    {
                                                                        useData?.subscriptionsplan.length === 0 &&
                                                                        <tr>

                                                                            <td colSpan={6} className="text-center"><b>No Data</b></td>
                                                                        </tr>
                                                                    }



                                                                </tbody>

                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </>
                        }

                        {
                            active === 7 &&
                            <>

                                <div class="row">

                                    <div class="col-12">

                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card mb-4 mb-md-0">
                                                    <div class="card-body">
                                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                                            <p class=" text-center"><span class=" text-green text-center  me-1" style={{ fontSize: "30px" }}>Need  Help <i class="bi bi-question-circle"></i></span>
                                                            </p>

                                                        </div>
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <div className="col-lg-5 d-none d-lg-block">
                                                                <img src={helpbg} className="w-100" style={{ marginTop: "-40px" }} />
                                                            </div>
                                                            <div className="col-lg-7">
                                                                <div class="form-floating">
                                                                    <textarea onChange={(e)=>{setNeedHelp(e.target.value)}} class="form-control" placeholder="Leave a comment here" id="floatingTextarea" style={{ border: "1px solid", minHeight: "180px" }}></textarea>
                                                                    <label for="floatingTextarea">Write yor Query here......</label>
                                                                </div>
                                                                <div className="text-center mt-3">
                                                                    <button type="button" class="btn btn-primary" onClick={() => { SubmitData() }}>Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </>
                        }


                        {imageShow.value === true && (
                            <>

                                <div className="modal-overlay" onClick={() => { }}></div>
                                <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">

                                    <section className="vh-100 container_body" style={{ background: "#ffffffc4" }}>
                                        <div className="container py-5 h-75">
                                            <div className="row px-3 d-flex align-items-center justify-content-center h-100">
                                                <div className="col-md-10 w-auto col-lg-10 col-xl-9 card flex-row mx-auto px-0 h-100">
                                                    {/* <div className="img-left d-none d-md-flex">
                                            <div>

                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                                                    alt="login form" class="img-fluid" style={{ height: "100%" }} />
                                            </div>
                                        </div> */}

                                                    <div className="card-body h-100" style={{ boxShadow: "rgb(41 42 38 / 67%) 2px 2px 20px 14px", borderRadius: "10px" }}>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ fontSize: "30px" }} onClick={() => { setImageShow({ value: false, image: "" }) }}>
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                        {
                                                            imageShow?.image &&

                                                            <div className="w-100 h-100" style={{ padding: "10px 10px 25px 10px" }}>
                                                                <img src={`${process.env.REACT_APP_PORT}/admin/file/${imageShow.image}`} className="h-100 w-100" style={{ borderRadius: "20px" }} />
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                            </>
                        )}

                        {
                            orderModal &&
                            <>
                                <div className="modal-overlay" ></div>
                                <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">

                                    <section className="vh-100 container_body">
                                        <div className="container py-5 h-100">
                                            <div className="row px-3 d-flex align-items-center justify-content-center h-100">
                                                <div className="col-md-10  col-lg-10 col-xl-9 card flex-row mx-auto px-0  " style={{ width: "90%", maxHeight: '100%' }}>


                                                    <div className="card-body p-sm-4 p-2" style={{ overflow: "hidden" }}>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ fontSize: "30px" }} onClick={() => {
                                                            setOrderModal(false);

                                                        }}>
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>

                                                        {

                                                            <>
                                                                <h2 class="title text-center mt-4 modal_heading" >
                                                                    Oreder Details
                                                                </h2>
                                                                <div class="p-sm-2 p-0  modal-footer mt-2 d-flex flex-column justify-content-center">


                                                                    {/* <div>
                                                                <div>Service Name : </div>
                                                                <div>{selectServicedata.innerCategoryName}</div>
                                                            </div> */}

                                                                    <div class="card" style={{ boxShadow: "none" }}>
                                                                        <div class="card-body mx-sm-4 p-0">
                                                                            <div class="container w-75 d-md-block d-none">


                                                                                <div class="d-flex">
                                                                                    <div className="w-50"><b>Package Name</b></div>
                                                                                    <div>: <span>{orderModalData.name}</span></div>
                                                                                </div>
                                                                                <hr style={{ border: "1px solid black;" }} />
                                                                                <div class="d-flex">
                                                                                    <div className="w-50"><b>Package Amount</b></div>
                                                                                    <div>: <span>{orderModalData.amount}</span></div>
                                                                                </div>
                                                                                <hr style={{ border: "1px solid black;" }} />

                                                                                {
                                                                                    localStorage.getItem('franchiseid') &&
                                                                                    <>
                                                                                        <div class="d-flex">
                                                                                            <div className="w-50"><b>Franchiseid</b></div>
                                                                                            <div>: <span>{localStorage.getItem('franchiseid')}</span></div>
                                                                                        </div>
                                                                                        <hr style={{ border: "1px solid black;" }} />

                                                                                    </>
                                                                                }
                                                                                <div class="d-flex justify-content-center">
                                                                                    <div><b>You Need to you {orderModalData.amount}</b></div>
                                                                                    <hr style={{ border: "1px solid black;" }} />
                                                                                </div>


                                                                                <div>
                                                                                    <button type="button" class="btn btn-primary"
                                                                                        style={{ backgroundColor: "rgb(25, 135, 84)", border: "none", padding: '12px', fontSize: "30px", width: "100%", fontWeight: "600" }}
                                                                                        onClick={() => { CreateOrder() }}
                                                                                    >Pay Now</button>
                                                                                </div>


                                                                            </div>
                                                                            <div class="container d-md-none d-block">

                                                                                <div class="d-flex">
                                                                                    <div className="w-50"><b>Package Name</b></div>
                                                                                    <div>: <span>{orderModalData.name}</span></div>
                                                                                </div>
                                                                                <hr style={{ border: "1px solid black;" }} />
                                                                                <div class="d-flex">
                                                                                    <div className="w-50"><b>Package Amount</b></div>
                                                                                    <div>: <span>{orderModalData.amount}</span></div>
                                                                                </div>
                                                                                <hr style={{ border: "1px solid black;" }} />

                                                                                {
                                                                                    localStorage.getItem('franchiseid') &&
                                                                                    <>
                                                                                        <div class="d-flex">
                                                                                            <div className="w-50"><b>Franchiseid</b></div>
                                                                                            <div>: <span>{localStorage.getItem('franchiseid')}</span></div>
                                                                                        </div>
                                                                                        <hr style={{ border: "1px solid black;" }} />

                                                                                    </>
                                                                                }
                                                                                <div class="d-flex justify-content-center">
                                                                                    <div><b>You Need to you {orderModalData.amount}</b></div>
                                                                                    <hr style={{ border: "1px solid black;" }} />
                                                                                </div>


                                                                                <div className="d-flex justify-content-center">
                                                                                    <button type="button" class="btn btn-primary"
                                                                                        style={{ backgroundColor: "#198754", border: "none", padding: '10px 30px', fontSize: "20px", width: "auto", fontWeight: "600" }}
                                                                                        onClick={() => {
                                                                                            // history.push("/Payment");

                                                                                            CreateOrder()

                                                                                        }}
                                                                                    >Pay Now</button>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                            </>
                                                        }




                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>


                            </>
                        }

                    </div>
                </section>
                <ToastContainer />
                <a class="whats-app" href="https://wa.link/6bjptw" target="_blank">
                    <FaWhatsapp style={{}} />
                </a>
                <Footer />
                <CartSidebar cartStatus={cartStatus} setCartStatus={setCartStatus} />
            </div>
        </>
    )
}

export default FranchiseProfile