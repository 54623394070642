import PU from "../../images/PU-separator.svg"
const Section2 = ({ data }) => {

    return (
        <>

            <h1 className="text-center heading_main  ">{data.name}</h1>
            <div className='row'>
                {
                    data.description && data.icon ?
                        <>
                            <div className='col-lg-8 '>
                                <div style={{  overflowY: 'auto', }}>
                        
                                    <pre className="f_24 custom-editor-content" style={{ width: '100%', whiteSpace: 'pre-wrap' , textAlign:'justify' , fontFamily:'inherit' }} dangerouslySetInnerHTML={{ __html: data.description }}>
                                        {/* {data.description} */}
                                    </pre>
                                </div>
                            </div>

                            <div className='col-lg-4' style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                <img src={`${process.env.REACT_APP_PORT}/admin/service/file/${data?.icon}`} alt="flower" style={{ width: "90%" }} />
                            </div>
                        </>
                        :
                        data.description ?
                            <div className='col-12 '>
                                <div style={{ textAlign:"justify"}} className="f_24 custom-editor-content" dangerouslySetInnerHTML={{ __html: data.description }}>
                                </div>
                            </div>
                            :
                            <>
                                {
                                    data?.icon &&
                                      <>
                                        <div className="col-sm-4"></div>
                                        <div className='col-sm-4'>
                                            <img src={`${process.env.REACT_APP_PORT}/admin/service/file/${data?.icon}`} alt="flower" style={{ width: "90%" }} />
                                        </div>
                                    </>
                                }
                            </>
                }
            </div>


        </>
    )
}

export default Section2